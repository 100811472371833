import calculateExpenses from './calculateExpenses'
import calculateLoan from './calculateLoan'
import calculateMortgage from './calculateMortgage'
// import calculatePropertyValueGrowth from './calculatePropertyValueGrowth'
import calculateResults from './calculateResults'

const calculateSummary = (values) => {
  const noPrincipal = values?.noPrincipal
  const downPayment = Number(values?.downPayment?.amount) || 0
  const interestRate = Number(values?.interestRate) || 0
  // const propertyValueGrowthRate = Number(values?.propertyValueGrowthRate) || 0

  const expenses = calculateExpenses(values)

  const results = calculateResults({
    ...values,
    totalExpenses: expenses.totalExpenses
  })

  const mortgageBase = calculateMortgage({ ...values })

  const yearlyMortgages = [
    {
      ...calculateLoan({
        debt: mortgageBase?.debt,
        monthlyPayment: mortgageBase?.monthlyPayment,
        monthlyRate: mortgageBase?.monthlyRate,
        month: 0 * 12,
        noPrincipal,
        downPayment,
        interestRate
      }),
      totalInterestFromStartToFinish: expenses?.totalInterest,
      mainMortgageDebt: mortgageBase?.mainMortgageDebt,
      financeRenovationDebt: mortgageBase?.financeRenovationDebt,
      mainMortgageInterestRate: mortgageBase?.mainMortgageInterestRate,
      financedRenovationCostRate: mortgageBase?.financedRenovationCostRate,
      interestMainMortgage: expenses?.interestMainMortgage,
      interestFinancedRenovationCost: expenses?.interestFinancedRenovationCost
    }
  ]
  // const propertyValues = Array(years)
  //   .fill(null)
  //   .map((_, index) =>
  //     calculatePropertyValueGrowth({
  //       propertyValue: mortgageBase?.propertyValue,
  //       propertyValueGrowthRate,
  //       year: index
  //     })
  //   )
  //   .map(({ propertyValueIncreased }) => propertyValueIncreased)

  return {
    results,
    mortgage: yearlyMortgages,
    expenses
    // propertyValues,
  }
}

export default calculateSummary
