import { Box, Divider, Text } from '@qonsoll/react-design'
import { Icon, Info } from 'components'

import PropTypes from 'prop-types'
import { round } from 'helpers'
import { useUser } from 'domains/User/context'

const SummaryItem = (props) => {
  const {
    divider = true,
    label,
    value,
    locked,
    measurementSymbol = '',
    colorized = false,
    roundTo = 0,
    symbolAtStart = '',
    info = null,
    strong = false,
    negativeToZero = false
  } = props

  const { unlockProAccess } = useUser()

  const valueColor = colorized
    ? value > 0
      ? 'var(--ql-typography-text-color-success)'
      : 'var(--ql-typography-text-color-danger)'
    : 'var(--ql-typography-text-color-primary)'

  const valueComputed = negativeToZero
    ? Number(value) >= 1
      ? round(value, roundTo)
      : 0
    : value
    ? round(value, roundTo)
    : 0

  return (
    <Box mb={2}>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          {info && (
            <Box mr={2}>
              <Info placement="topLeft" title={info} />
            </Box>
          )}

          <Text
            strong={strong}
            disabled={locked}
            onClick={locked ? unlockProAccess : null}
            cursor={locked ? 'pointer' : 'auto'}
            fontSize="var(--ql-typography-font-size-md)"
            style={{ display: 'flex', alignItems: 'center' }}>
            {label}
          </Text>
        </Box>
        {locked ? (
          <Icon
            onClick={locked ? unlockProAccess : null}
            size={20}
            cursor="pointer"
            name="Lock1Outlined"
            fill="var(--ql-state-disabled-color)"
          />
        ) : (
          <Text
            disabled={locked}
            color={valueColor}
            strong={strong}
            fontSize="var(--ql-typography-font-size-md)"
            cursor={locked ? 'pointer' : 'auto'}>
            {symbolAtStart +
              (valueComputed?.toLocaleString('en-EN') || 0) +
              measurementSymbol}
          </Text>
        )}
      </Box>
      {divider && (
        <Box mt={2}>
          <Divider />
        </Box>
      )}
    </Box>
  )
}

SummaryItem.propTypes = {
  divider: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  locked: PropTypes.bool,
  measurementSymbol: PropTypes.string,
  colorized: PropTypes.bool,
  roundTo: PropTypes.number,
  symbolAtStart: PropTypes.any,
  info: PropTypes.any,
  strong: PropTypes.bool,
  negativeToZero: PropTypes.bool
}

export default SummaryItem
