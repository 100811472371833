import { memo, useMemo } from 'react'

import { Account } from '@qonsoll/react-design'
import { LogoutOutlined } from '@ant-design/icons'
import firebase from 'firebase/compat/app'
import { useHistory } from 'react-router-dom'
import { useTranslations } from 'contexts/Translation'
import { useUser } from 'domains/User/context'
import { useUserName } from 'hooks'

const AccountMenu = () => {
  const { user } = useUser()
  const { displayName, email, avatarUrl, avatarText, isDisplayNameExists } =
    useUserName(user)
  const history = useHistory()

  const { t } = useTranslations()
  const menu = useMemo(
    () => [
      {
        type: 'item',
        text: t('Log out'),
        icon: <LogoutOutlined />,
        danger: true,
        disabled: false,
        onClick: () => {
          firebase.auth().signOut()
          history.push('/')
        }
      }
    ],
    [t, history]
  )

  return (
    <Account
      title={isDisplayNameExists ? displayName : t('No name')}
      avatarText={avatarText || 'N/A'}
      caption={email || t('No email')}
      menuPlacement="topRight"
      avatar={avatarUrl}
      menu={menu}
      isEllipsis
    />
  )
}

AccountMenu.propTypes = {}

export default memo(AccountMenu)
