import {
  BILLING_PERIODS,
  BILLING_PERIODS_INTERVAL_VALUES
} from '__constants__/enums'
import { Box, Divider, Text, Title } from '@qonsoll/react-design'

import { Grid } from 'antd'
import { Icon } from 'components'
import PATHS from 'pages/paths'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { useTranslations } from 'contexts/Translation'

const PlanSimpleView = (props) => {
  const {
    plan,
    isTrialUsed,
    withPeriods = true,
    selectedPeriod = BILLING_PERIODS_INTERVAL_VALUES[BILLING_PERIODS.MONTHLY],
    withRedirect = true,
    onChange
  } = props
  const { title, suffix = ' plan', prices } = plan || {}
  const { t } = useTranslations()
  const history = useHistory()

  const { xs } = Grid.useBreakpoint()
  const price = withPeriods
    ? prices.find(({ period }) => period === selectedPeriod)
    : prices?.[0]

  const navigateToCheckout = () => {
    history.push(
      `${PATHS.AUTHENTICATED.PlAN_CHECKOUT}?selectedPlan=${plan._id}&priceId=${price?.priceId}&selectedPeriod=${selectedPeriod}`
    )
  }

  const onContinue = () => (onChange ? onChange() : navigateToCheckout())

  return (
    <Box
      borderRadius="var(--card-radius)"
      width="inherit"
      bg="var(--ql-color-accent1)"
      display="flex"
      justifyContent="space-between">
      <Box p={16} width="inherit">
        <Title color="var(--ql-color-white)" level={xs ? 3 : 2}>
          {t(title + suffix)}
        </Title>
        <Text
          color="var(--ql-color-white)"
          fontSize={xs ? 16 : 20}
          letterSpacing="2px">
          {price?.price / 100}
          {price?.currencySymbol}
          {' / '}
          <Text
            type="secondary"
            letterSpacing="2px"
            color="var(--ql-color-white-t-lighten2)">
            {t(price?.period) || t(suffix)}
          </Text>
          {!isTrialUsed ? ` + ${t('trialPeriod')}` : null}
        </Text>
      </Box>
      <Box display="flex">
        <Divider
          style={{
            borderLeft: '1px solid var(--ql-color-white)',
            height: '100%'
          }}
          type="vertical"
        />
        <Box
          px={xs ? 16 : 24}
          display="flex"
          cursor={withRedirect ? 'pointer' : 'auto'}
          onClick={() => withRedirect && onContinue?.()}
          justifyContent="center"
          alignItems="center">
          <Icon
            mr={2}
            fill="var(--ql-color-white)"
            name="ArrowNarrowRightOutlined"
            size={28}
          />
        </Box>
      </Box>
    </Box>
  )
}

PlanSimpleView.propTypes = {
  plan: PropTypes.object,
  isTrialUsed: PropTypes.bool,
  selectedPeriod: PropTypes.number,
  withPeriods: PropTypes.bool,
  withRedirect: PropTypes.bool,
  onChange: PropTypes.func
}

export default PlanSimpleView
