const calculateResults = (values) => {
  const { totalExpenses = 0 } = values

  const askingPrice = Number(values?.askingPrice) || 0
  const bestCaseSellingPrice =
    Number(values?.estimatedSellingBestCasePrice) || 0
  const worstCaseSellingPrice =
    Number(values?.estimatedSellingPriceWorstCase) || 0
  const downPayment = Number(values?.downPayment?.amount) || 0
  const renovation = Number(values?.upfrontRenovation) || 0
  // const closingTaxValue = (askingPrice * 2.5) / 100
  const closingCost = Number(values?.closingCost)
  const monthsFromStartToFinish = Number(values?.monthsFromStartToFinish) || 0
  const financeRenovation = Number(values?.financeRenovation?.amount) || 0

  /* Total cash required: Down payment, document fee, closing costs and renovation costs */
  const totalCashRequired =
    downPayment + renovation + closingCost - financeRenovation

  const grossProfitBestCase = bestCaseSellingPrice - totalExpenses - askingPrice
  const grossProfitWorstCase =
    worstCaseSellingPrice - totalExpenses - askingPrice
  const roiBestCase = grossProfitBestCase / totalCashRequired
  const roiWorstCase = grossProfitWorstCase / totalCashRequired
  const grossProfitPerMonthBestCase = monthsFromStartToFinish
    ? grossProfitBestCase / monthsFromStartToFinish
    : 0
  const grossProfitPerMonthWorstCase = monthsFromStartToFinish
    ? grossProfitWorstCase / monthsFromStartToFinish
    : 0

  return {
    grossProfitBestCase,
    grossProfitWorstCase,
    roiBestCase,
    roiWorstCase,
    grossProfitPerMonthBestCase,
    grossProfitPerMonthWorstCase,
    totalCashRequired
  }
}

export default calculateResults
