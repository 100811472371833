import { Col, Row } from '@qonsoll/react-design'

import { AddItemCard } from 'components'
import PropTypes from 'prop-types'
import { RentalCalculatorSimpleView } from 'domains/RentalCalculator/components'
import { useRentalCalculatorBaseActions } from 'domains/RentalCalculator/hooks'
import { useTranslations } from 'contexts/Translation'

const RentalCalculatorList = (props) => {
  const {
    rentalCalculators,
    isListWithCreate,
    listView,
    cw = [12, 12, 12, 12, 6, 4]
  } = props

  const isEmpty = !rentalCalculators?.length
  const itemWidth = listView ? 12 : cw
  const { t } = useTranslations()
  const actions = useRentalCalculatorBaseActions()

  return (
    <Row mx="var(--ql-grid-gutter-negative)">
      {isListWithCreate && (
        <Col mb={4} cw={itemWidth}>
          <AddItemCard
            message={t('Create new project')}
            onClick={actions?.onCreate}
            minHeight={300}
            height="100%"
          />
        </Col>
      )}
      {!isEmpty &&
        rentalCalculators.map((rentalCalculator, index) => (
          <Col mb={4} key={rentalCalculator?._id || index} cw={itemWidth}>
            <RentalCalculatorSimpleView
              rentalCalculator={rentalCalculator}
              index={index}
            />
          </Col>
        ))}
    </Row>
  )
}

RentalCalculatorList.propTypes = {
  rentalCalculators: PropTypes.array,
  isListWithCreate: PropTypes.bool,
  listView: PropTypes.bool,
  emptyProps: PropTypes.object,
  actions: PropTypes.any,
  cw: PropTypes.any
}

export default RentalCalculatorList
