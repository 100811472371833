import { Box, Title } from '@qonsoll/react-design'

import PropTypes from 'prop-types'
import { SummaryItem } from 'domains/RentalCalculator/components/RentalCalculatorSummary/components'
import { useTranslations } from 'contexts/Translation'

const FlippingCalculatorResultsExpensesSection = (props) => {
  const { measurementSymbol, value = {}, isLocked } = props

  const { t } = useTranslations()

  return (
    <>
      <Box mb={4} display="flex" alignItems="center" justifyContent="center">
        <Title level={3}>{t('Expenses')}</Title>
      </Box>
      <SummaryItem
        measurementSymbol={measurementSymbol}
        value={value.totalInterest}
        label={t('Total interest')}
        info={t('totalInterestInfo')}
        locked={isLocked}
      />
      <SummaryItem
        measurementSymbol={measurementSymbol}
        value={value.operatingExpenses}
        label={t('Operating expenses')}
        info={t('totalCommonCostInfo')}
        locked={isLocked}
      />
      <SummaryItem
        measurementSymbol={measurementSymbol}
        value={value.upfrontRenovation}
        label={t('Renovation cost')}
        info={t('renovationCostInfo')}
        locked={isLocked}
      />
      <SummaryItem
        measurementSymbol={measurementSymbol}
        value={value.sellingCosts}
        label={t('Selling costs')}
        info={t('sellingCostsInfo')}
        locked={isLocked}
      />
      <SummaryItem
        measurementSymbol={measurementSymbol}
        value={value.closingTaxValue}
        label={t('Closing cost')}
        info={t('closingCostValueInfo')}
        locked={isLocked}
      />
      <SummaryItem
        measurementSymbol={measurementSymbol}
        value={-value.rentalIncome}
        label={t('Total rental income')}
        info={t('rentalIncomeInfo')}
        locked={isLocked}
      />
      <SummaryItem
        measurementSymbol={measurementSymbol}
        value={value.totalExpenses}
        label={t('Total expenses')}
        info={t('totalExpensesInfo')}
        locked={isLocked}
      />
    </>
  )
}

FlippingCalculatorResultsExpensesSection.propTypes = {
  value: PropTypes.object,
  measurementSymbol: PropTypes.string,
  isLocked: PropTypes.bool
}

export default FlippingCalculatorResultsExpensesSection
