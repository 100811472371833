// import isLocalhost from './isLocalhost'

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT
//   'http://localhost:5001/onkel-skrue-dev/us-central1'
//   'http://localhost:5001/onkel-skrue-prod/us-central1'

// isLocalhost
//   ? 'http://127.0.0.1:5001/onkel-skrue-dev/us-central1'
//   :

export default API_ENDPOINT
