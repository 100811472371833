import { Col, Row } from 'antd'
import { StyledAddon, StyledInputNumber } from './InputNumber.styled'

import PropTypes from 'prop-types'
import { forwardRef } from 'react'

const InputNumber = forwardRef((props, ref) => {
  const { addonAfter, disabled, id, ...rest } = props

  return (
    <Row wrap={false}>
      <Col flex="auto">
        <StyledInputNumber
          ref={ref}
          id={id}
          disabled={disabled}
          stringMode
          formatter={(value) =>
            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          }
          addon={(!!addonAfter)?.toString()}
          {...rest}
        />
      </Col>
      {addonAfter && (
        <Col flex="none">
          <StyledAddon className="input-addon" disabled={disabled}>
            {addonAfter}
          </StyledAddon>
        </Col>
      )}
    </Row>
  )
})

InputNumber.displayName = InputNumber

InputNumber.propTypes = {
  addonAfter: PropTypes.node,
  inputRef: PropTypes.object,
  disabled: PropTypes.bool,
  id: PropTypes.string
}

export default InputNumber
