/* eslint-disable no-console */
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'

import { APPLICATION_URL } from '__constants__/environment'
import PATHS from 'pages/paths'
import { PRODUCT_TYPES } from '__constants__/enums'
import { SUBSCRIPTION_STATUSES } from '__constants__'
import { fetchApi } from 'services/api/rest'
import { notification } from 'antd'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'
import { useTranslations } from 'contexts/Translation'
import { useUser } from 'domains/User/context'

const useBuyPlan = (plan) => {
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()

  const { user } = useUser()
  const { t } = useTranslations()
  const stripe = useStripe()
  const elements = useElements()

  const getCustomer = async (paymentMethodId) => {
    if (!user?.customerId) {
      // Create new customer
      return await fetchApi({
        method: 'POST',
        path: 'subscriptions/customers/' + user?._id,
        body: { paymentMethodId }
      })
    } else {
      // In case that customer exists
      return await fetchApi({
        method: 'PUT',
        path: 'subscriptions/customers/' + user?._id,
        body: { paymentMethodId }
      })
    }
  }

  const subscribe = async (updatePaymentMethod) => {
    try {
      setIsLoading(true)

      let paymentMethodId
      let customerId
      if (updatePaymentMethod) {
        // create a payment method
        const paymentMethod = await stripe?.createPaymentMethod({
          type: 'card',
          card: elements?.getElement(CardElement),
          billing_details: {
            name: user?.firstName + ' ' + user.lastName,
            email: user?.email
          }
        })
        paymentMethodId = paymentMethod?.paymentMethod?.id

        // Create or update customer
        const customerRes = await getCustomer(paymentMethod?.paymentMethod?.id)
        const customer = customerRes?.data?.customer
        customerId = customer?.customerId
      } else {
        paymentMethodId = user.paymentMethodId
        customerId = user.customerId
      }

      const subscriptionRes = await fetchApi({
        method: 'POST',
        path: 'subscriptions',
        body: { uid: user._id, priceId: plan.priceId }
      })

      const subscription = subscriptionRes?.data?.subscription

      const isCorrect = subscription?.subscriptionId && customerId

      if (isCorrect) {
        if (
          subscription.status !== SUBSCRIPTION_STATUSES.TRIALING &&
          subscription?.piStatus !== 'succeeded'
        ) {
          // eslint-disable-next-line no-console
          console.log('Confirmation required')
          if (!subscription?.clientSecret)
            throw new Error(
              JSON.stringify({
                msg: 'No client secret',
                subscription,
                customerId
              })
            )

          await stripe.confirmCardPayment(subscription?.clientSecret, {
            payment_method: paymentMethodId,
            return_url: APPLICATION_URL + PATHS.CONFIG.AFTER_PAYMENT
          })
        } else {
          const subscriptionType =
            subscription?.subscriptionType || PRODUCT_TYPES.RENTAL
          history.push(
            subscriptionType === PRODUCT_TYPES.FLIPPING
              ? `${PATHS.CONFIG.AFTER_FLIPPING}?type=${subscriptionType}`
              : `${PATHS.CONFIG.AFTER_PAYMENT}?type=${subscriptionType}`
          )
          notification.success({
            message: t('Subscription confirmed'),
            description: t('Your subscription is now active'),
            duration: 120
          })
        }
      } else throw new Error(JSON.stringify({ subscription, customerId }))
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      notification.error({
        message: t('Error'),
        description: t('Something went wrong, please try again later')
      })

      setIsLoading(false)
    }
  }

  return [subscribe, isLoading]
}

export default useBuyPlan
