import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import { notification } from 'antd'
import { useReactToPrint } from 'react-to-print'
import { useState } from 'react'
import { useTranslations } from 'contexts/Translation'

const useCreatePDFFileFromPage = (pdfRef, onPDFCreatedCallback) => {
  const [isLoanSendMode, setIsLoanSendMode] = useState(false)
  const { t } = useTranslations()

  const handleDownLoadPDF = useReactToPrint({
    content: () => pdfRef.current,
    bodyClass: 'root',
    copyStyles: true,
    print: async (printIframe) => {
      try {
        const doc = printIframe.contentDocument
        const html = doc.getElementById('divToSaveAsPDF')
        const canvas = await html2canvas(html, { logging: false, scale: 1 })
        const pageHeight = 295
        const imgData = canvas.toDataURL('image/png')
        const imgHeight = (html.scrollHeight * 190) / canvas.width
        let heightLeft = imgHeight
        let position = -2
        const pdf = new jsPDF('p', 'mm', 'a4')
        pdf.addImage(imgData, 'PNG', 10, position, 190, imgHeight)
        heightLeft -= pageHeight
        while (heightLeft > 0) {
          position = heightLeft - imgHeight - 2
          pdf.addPage()
          pdf.addImage(imgData, 'PNG', 10, position, 190, imgHeight)
          heightLeft -= pageHeight
        }

        await onPDFCreatedCallback?.({
          projectFile: pdf.output('blob')
        })
      } catch (err) {
        notification.error({ message: t('Error during loan application send') })
        //eslint-disable-next-line
        console.log('Loan application send error:', err)
      } finally {
        setIsLoanSendMode(false)
      }
    }
  })

  const download = () => {
    setIsLoanSendMode(true)
    setTimeout(() => {
      handleDownLoadPDF()
    }, 500)
  }

  return { download, isLoanSendMode }
}

export default useCreatePDFFileFromPage
