import { Grid } from 'antd'

const { useBreakpoint } = Grid

const useAsideState = () => {
  const { lg, xs } = useBreakpoint()

  return [!lg ? !xs : false, xs]
}

export default useAsideState
