import { useEffect, useState } from 'react'

import { fetchApi } from 'services/api/rest'
import { transformProduct } from 'domains/Product/helpers'

const useGetProductByPrice = (id) => {
  const [product, setProduct] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        setLoading(true)
        const res = await fetchApi({
          method: 'GET',
          path: `subscriptions/prices/${id}`
        })
        if (!res.data) throw new Error('No data')
        const product = transformProduct(res?.data?.price)
        setProduct(product)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error)
        setError(error)
      } finally {
        setLoading(false)
      }
    }
    id && fetchProduct()
  }, [id])

  return [product, loading, error]
}

export default useGetProductByPrice
