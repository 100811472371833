import { Box, Card } from '@qonsoll/react-design'

import { Grid } from 'antd'
import { PRODUCT_TYPES } from '__constants__/enums'
import { PlanSelect } from 'pages/App/Plan'
import PropTypes from 'prop-types'
import { memo } from 'react'

const CalculatorPopover = (props) => {
  const { isNewCustomer, type = PRODUCT_TYPES.RENTAL } = props

  if (isNewCustomer) {
    return (
      <Wrapper>
        <PlanSelect
          cw={[12, 12, 6, 4]}
          planType={type}
          wrapperCw={12}
          mb={[4, 4, 0]}
        />
      </Wrapper>
    )
  }

  return null
}

CalculatorPopover.propTypes = {
  isNewCustomer: PropTypes.bool,
  type: PropTypes.string
}

const Wrapper = (props) => {
  const { children } = props
  const { xs, lg } = Grid.useBreakpoint()

  return (
    <Box
      position="Fixed"
      zIndex={1000}
      transform="translate(-50%, 0%)"
      left={xs ? '50%' : !lg ? 'calc(50% + 50px)' : 'calc(50% + 140px)'}
      top={xs ? '150px' : '250px'}>
      <Card
        borderColor="var(--ql-color-accent1-t-lighten3)"
        width={['90vw', '75vw', '75vw', '60vw', '50vw']}
        maxHeight="600px"
        overflow="auto">
        {children}
      </Card>
    </Box>
  )
}

Wrapper.propTypes = {
  children: PropTypes.node
}

export default memo(CalculatorPopover)
