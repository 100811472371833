import {
  BILLING_PERIODS,
  BILLING_PERIODS_INTERVAL_VALUES,
  BILLING_PERIODS_VALUES,
  PRODUCT_TYPE_PACKAGE
} from '__constants__/enums'
import { Box, Button, Col, Row } from '@qonsoll/react-design'
import { Grid, Modal, Radio, notification } from 'antd'

import { PlanView } from 'domains/Plan/components'
import PropTypes from 'prop-types'
import { fetchApi } from 'services/api/rest'
import { useGetPlans } from 'domains/Plan/hooks'
import { useMemo } from 'react'
import { useState } from 'react'
import { useTranslations } from 'contexts/Translation'
import { useUser } from 'domains/User/context'

const ChangePlan = ({ currentPlan, onAfterChange }) => {
  const [open, setOpen] = useState(false)
  const [selectedPeriod, setSelectedPeriod] = useState(
    BILLING_PERIODS_INTERVAL_VALUES[BILLING_PERIODS.MONTHLY]
  )

  const [plans, plansLoading] = useGetPlans()
  const [submitLoading, setSubmitLoading] = useState(false)
  const [selected, setSelected] = useState(null)
  const { user } = useUser()
  const { t } = useTranslations()
  const { xs, md } = Grid.useBreakpoint()

  const availablePlans = useMemo(
    () =>
      plans?.filter((plan) =>
        currentPlan?.accessibleOptions.find(({ key }) => key === 'type')
          ?.value === PRODUCT_TYPE_PACKAGE
          ? plan?._id !== currentPlan?._id ||
            plan?.prices?.find(({ period }) => period === selectedPeriod)
              ?.priceId !== currentPlan?.priceId
          : plan?._id !== currentPlan?._id &&
            [
              PRODUCT_TYPE_PACKAGE,
              currentPlan?.accessibleOptions.find(({ key }) => key === 'type')
                ?.value
            ].includes(plan?.type)
      ),
    [plans, selectedPeriod, currentPlan]
  )

  const showModal = () => setOpen(true)
  const hideModal = () => setOpen(false)

  const purchase = async () => {
    try {
      setSubmitLoading(true)
      const isSamePlan =
        currentPlan?.accessibleOptions.find(({ key }) => key === 'type')
          ?.value === selected?.type
      const res = await fetchApi({
        method: isSamePlan ? 'PATCH' : 'POST',
        path: isSamePlan
          ? 'subscriptions/' +
            user?.subscriptions?.[
              currentPlan?.accessibleOptions.find(({ key }) => key === 'type')
                ?.value
            ]
          : 'subscriptions',
        body: isSamePlan
          ? { priceId: selected?.priceId }
          : { priceId: selected?.priceId, uid: user?._id }
      })

      const isSuccess = isSamePlan
        ? res?.data?.subscription?.id
        : res?.data?.subscription?.subscriptionId
      if (isSuccess) {
        notification.success({
          message: t('Success'),
          description: t('You have successfully changed your plan')
        })
      } else throw new Error(res)
      onAfterChange?.()
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
      notification.error({
        message: t('Error'),
        description: t('Something went wrong, please try again later')
      })
    } finally {
      setOpen(false)
      setSubmitLoading(false)
    }
  }

  return (
    <>
      <Button onClick={showModal} block loading={plansLoading}>
        {t('Change plan')}
      </Button>
      <Modal
        title={t('Change plan')}
        visible={open}
        onCancel={hideModal}
        onOk={purchase}
        okText={t('Purchase')}
        width={600}
        okButtonProps={{
          disabled: !selected,
          loading: submitLoading,
          type: 'primary'
        }}
        bodyStyle={{ borderRadius: 'var(--ql-border-radius-lg)' }}>
        <Box mb={2} display="flex" justifyContent="center">
          <Radio.Group
            size="middle"
            buttonStyle={xs || !md ? 'solid' : 'outline'}
            onChange={(val) => {
              setSelected(null)
              setSelectedPeriod(val?.target?.value)
            }}
            value={selectedPeriod}>
            {Object.entries(BILLING_PERIODS_VALUES)?.map(
              ([key, period], index) => (
                <Radio.Button
                  style={{ padding: '4px 8px' }}
                  key={key}
                  value={BILLING_PERIODS_INTERVAL_VALUES[key]}>
                  {t(period)}
                </Radio.Button>
              )
            )}
          </Radio.Group>
        </Box>
        <Row justifyContent="center">
          {availablePlans?.map((availablePlan) => (
            <Col
              mb={2}
              cw={[12, 12, 6, 6]}
              key={`availablePlan-${availablePlan?._id}`}>
              <PlanView
                selectedPeriod={selectedPeriod}
                selected={selected}
                onSelect={() =>
                  setSelected({
                    priceId: availablePlan?.priceId,
                    type: availablePlan?.type
                  })
                }
                plan={availablePlan}
                withButton={false}
              />
            </Col>
          ))}
        </Row>
      </Modal>
    </>
  )
}

ChangePlan.propTypes = {
  currentPlan: PropTypes.object,
  onAfterChange: PropTypes.func
}

export default ChangePlan
