import { Box, Switch, Title } from '@qonsoll/react-design'
import { Form, Grid, Tooltip } from 'antd'
import { YearlyNetProfitDiagram, YearlyNetProfitList } from './components'
import { useMemo, useState } from 'react'

import DiagramStateSwitcher from '../DiagramStateSwitcher'
import { Icon } from 'components'
import { Info } from 'components'
import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'

const YearlyNetProfitSummary = (props) => {
  const { options, isLocked, measurementSymbol, isPrintMode } = props

  const { xs } = Grid.useBreakpoint()

  const { t } = useTranslations()
  const [isDiagram, setIsDiagram] = useState(true)

  const optionsMapped = useMemo(
    () =>
      options?.map((i, index) => ({
        locked: isLocked && index !== 0,
        label: index + 1,
        value: i
      })),
    [options, isLocked]
  )

  return (
    <>
      <Box
        mb={4}
        display="flex"
        alignItems="center"
        justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Title level={3} mr={2}>
            {xs ? t('Net profit') : t('Yearly net profit')}
          </Title>
          <Info placement="topLeft" title={t('yearlyNetProfitInfo')} />
        </Box>
        <Tooltip placement="top" title={t('netProfitPropertyGrowthInfo')}>
          <Form.Item
            name={['rentalCalculator', 'isNetProfitPropertyGrowth']}
            valuePropName="checked"
            style={{ margin: 0 }}>
            <Switch
              checkedChildren={<Icon fill="#fff" name="TrendUp1Outlined" />}
              unCheckedChildren={<Icon fill="#fff" name="TrendDown1Outlined" />}
            />
          </Form.Item>
        </Tooltip>
        <DiagramStateSwitcher
          isPrintMode={isPrintMode}
          setIsDiagram={setIsDiagram}
          isDiagram={isDiagram}
        />
      </Box>
      {isDiagram ? (
        <YearlyNetProfitDiagram options={optionsMapped} />
      ) : (
        <YearlyNetProfitList
          measurementSymbol={measurementSymbol}
          options={optionsMapped}
          isLocked={isLocked}
        />
      )}
    </>
  )
}

YearlyNetProfitSummary.propTypes = {
  options: PropTypes.array,
  isLocked: PropTypes.bool,
  measurementSymbol: PropTypes.string,
  group: PropTypes.array,
  isPrintMode: PropTypes.bool
}

export default YearlyNetProfitSummary
