import { useEffect, useState } from 'react'

import { fetchApi } from 'services/api/rest'

const CURRENCY_SYMBOLS = {
  usd: '$',
  eur: '€',
  gbp: '£',
  nok: 'kr'
}

/**
 * It returns an array of values that are used to render the plans
 * @param props - An object that contains the query parameters.
 * @returns An array of values.
 */
const useGetPlans = () => {
  const [plans, setPlans] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const retrievePlans = async () => {
      try {
        const res = await fetchApi({
          method: 'GET',
          path: 'subscriptions/products'
        })
        const products = res?.data?.products
        const fetchedPlans = products
          ?.filter((i) => !!Object.keys(i.recurring).length)
          ?.sort((a, b) => a?.metadata?.order - b?.metadata?.order)
          ?.map((product) => {
            const accessibleOptions = Object.keys(product.metadata)
              .filter(
                (key) =>
                  product.metadata[key] !== 'false' &&
                  !['type', 'order'].includes(key)
              )
              .map((key) => ({ key: key, value: product.metadata[key] }))

            const notAccessibleOptions = Object.keys(product.metadata)
              .filter((key) => product.metadata[key] === 'false')
              .map((key) => ({ key: key, value: product.metadata[key] }))

            const prices = product?.prices
              ?.map((priceObject) => ({
                period: priceObject?.recurring?.interval,
                price: priceObject?.amount,
                priceId: priceObject?.priceId,
                currency: priceObject?.currency,
                currencySymbol: CURRENCY_SYMBOLS[priceObject.currency]
              }))
              ?.sort((a, b) => a.period - b.period)

            return {
              _id: product.productId,
              title: product.name,
              currency: product.currency,
              currencySymbol: CURRENCY_SYMBOLS[product.currency],
              description: product.description,
              price: product.amount,
              period: product.recurring.interval,
              notAccessibleOptions,
              accessibleOptions,
              type: product.metadata?.type,
              priceId: product.priceId,
              prices
            }
          })

        setPlans(fetchedPlans)
      } catch (err) {
        setError(err)
      } finally {
        setLoading(false)
      }
    }

    retrievePlans()
  }, [])

  // const plans = [
  //   {
  //     _id: '1qls23_2gsd',
  //     title: 'PAY',
  //     subtitle: 'MONTHLY',
  //     price: 6,
  //     currency: 'usd',
  //     currencySymbol: symbol,
  //     period: 'month',
  //     description:
  //       'Lorem ipsum dolor sit amet, conse ctetur adipiscing elit. Volutpat ac',
  //     accessibleOptions: [
  //       'Purring og inkasso',
  //       'Depositumskonto',
  //       'Årlig indeksregulering'
  //     ],
  //     notAccessibleOptions: ['Juridisk bistand (1t/år)']
  //   },
  //   {
  //     _id: '1qls212fas',
  //     title: 'PAY',
  //     subtitle: 'ANNUALLY',
  //     price: 5,
  //     currency: 'usd',
  //     currencySymbol: symbol,
  //     period: 'month',
  //     description:
  //       'Lorem ipsum dolor sit amet, conse ctetur adipiscing elit. Volutpat ac',
  //     accessibleOptions: [
  //       'Purring og inkasso',
  //       'Depositumskonto',
  //       'Årlig indeksregulering'
  //     ],
  //     notAccessibleOptions: ['Juridisk bistand (1t/år)']
  //   }
  // ]

  return [plans, loading, error]
}

export default useGetPlans
