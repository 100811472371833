import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage'

import { notification } from 'antd'
import { useState } from 'react'
import { useTranslations } from 'contexts/Translation'
import { useUser } from 'domains/User/context'

const useSendLoanApplication = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const { t } = useTranslations()
  const { user } = useUser()

  const handleSendLoanApplication = async ({
    projectFile,
    projectName,
    finnLink
  }) => {
    try {
      setLoading(true)
      const storage = getStorage()
      const filePath = `loan-applications/${new Date().getTime()}`
      const fileRef = ref(storage, filePath)
      await uploadBytes(fileRef, projectFile, {
        contentType: 'application/pdf'
      })
      const downloadUrl = await getDownloadURL(fileRef)

      const fetchUrl = `${process.env.REACT_APP_API_ENDPOINT}/loans`

      const dynamicTemplateData = {
        first_name: user?.firstName,
        last_name: user?.lastName,
        project_name: projectName,
        phone_number: user?.phone,
        email: user?.email
      }
      if (finnLink) dynamicTemplateData.finn_link = finnLink

      const request = await fetch(fetchUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          templateId: finnLink
            ? process.env.REACT_APP_SENDGRID_LOAN_APPLICATION_TEMPLATE_ID
            : process.env
                .REACT_APP_SENDGRID_LOAN_APPLICATION_TEMPLATE_ID_WITHOUT_FINN_LINK,
          dynamicTemplateData,
          filePath,
          downloadUrl
        })
      })
      if (request.ok) {
        notification.success({
          message: t('Successfully sent loan application')
        })
        // window.location.replace(process.env.REACT_APP_BANK_CONSENT_URL)
      }
    } catch (err) {
      setError(err)
      //eslint-disable-next-line
      console.log('Loan application error:', err)
      notification.error({
        message: `${t('Error during loan application send')}.${t(
          'Please check console for more info'
        )}`
      })
    } finally {
      setLoading(false)
    }
  }

  return [handleSendLoanApplication, loading, error]
}

export default useSendLoanApplication
