import { Button, Col, Row } from '@qonsoll/react-design'
import { PageLayout, Spinner } from 'components'
import {
  useActionsFlippingCalculatorAdvancedForm,
  useInitializeFlippingCalculatorAdvancedForm
} from 'domains/FlippingCalculator/components/FlippingCalculatorAdvancedForm/hooks'

import { FlippingCalculatorAdvancedForm } from 'domains/FlippingCalculator/components'
import { useGetFlippingCalculatorInitialValues } from 'domains/FlippingCalculator/hooks'
import { useParams } from 'react-router-dom'
import { usePrint } from 'hooks'
import { useTranslations } from 'contexts/Translation'
import { useUser } from 'domains/User/context'

const FlippingCalculatorEditPage = () => {
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting params from router */
  const params = useParams()
  /* Getting id from params */
  const { projectId: flippingCalculatorId } = params
  const { isFlippingProAccess } = useUser()

  /* Fetching rentalCalculator initial values */
  const [initialValues, loading] =
    useGetFlippingCalculatorInitialValues(flippingCalculatorId)

  /* Getting form instance from hook */
  const { form, loading: initialization } =
    useInitializeFlippingCalculatorAdvancedForm({
      initialData: initialValues,
      isProAccess: isFlippingProAccess
    })

  const { print, PrintWrapper, isPrintMode } = usePrint()

  /* Getting loading state and actions from hook */
  const { onFinish } = useActionsFlippingCalculatorAdvancedForm({
    flippingCalculatorId,
    initialData: initialValues,
    form
  })

  return (
    <PageLayout
      title={t('Flipping calculator project edit')}
      subTitle={t(
        'Follow the instructions bellow to get results in shortest possible time'
      )}
      actions={
        <Row mx="var(--ql-grid-gutter-negative)">
          <Col mb={[2, 0]}>
            <Button
              block
              disabled={!isFlippingProAccess}
              loading={isPrintMode}
              onClick={print}>
              {t('Export to PDF')}
            </Button>
          </Col>
          <Col>
            <Button
              block
              type="primary"
              disabled={!isFlippingProAccess}
              onClick={() => form.submit()}>
              {t('Save project')}
            </Button>
          </Col>
        </Row>
      }>
      <Row noGutters>
        {loading || initialization ? (
          <Col cw={12}>
            <Spinner height="70vh" text={t('Rental calculator loading')} />
          </Col>
        ) : (
          <Col cw={12}>
            <FlippingCalculatorAdvancedForm
              isAccessGranted={isFlippingProAccess}
              initialData={initialValues}
              onFinish={onFinish}
              form={form}
            />
            <PrintWrapper>
              <FlippingCalculatorAdvancedForm
                withId
                isAccessGranted={isFlippingProAccess}
                initialData={initialValues}
                onFinish={onFinish}
                form={form}
              />
            </PrintWrapper>
          </Col>
        )}
      </Row>
    </PageLayout>
  )
}

export default FlippingCalculatorEditPage
