import { Select } from 'antd'
import styled from 'styled-components'

export const StyledSelect = styled(Select)`
  background-color: transparent;
  width: 100%;

  & .ant-select-selector {
    background-color: transparent !important;
  }
`
