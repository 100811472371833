import './RentalCalculatorView.css'

import { Box, Col, Row, Text, Title } from '@qonsoll/react-design'
import { Button, Dropdown, Image, Menu, Tooltip } from 'antd'
import { useMemo, useRef } from 'react'

import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'
import SimpleImageSlider from 'react-simple-image-slider'
import { memo } from 'react'
import noImage from 'assets/no-photo.png'
import { useCurrency } from 'contexts/Currency'
import { useParentSize } from 'hooks'
import { useTranslations } from 'contexts/Translation'

const prepareValue = (value) => {
  const fixedNumber = Number(value || 0).toFixed?.(1) || 0
  const localized = Number(fixedNumber || 0).toLocaleString?.('en-EN')
  return localized || 0
}

const RentalCalculatorView = (props) => {
  const { rentalCalculator, onDelete, onEdit, isHovered } = props

  const ref = useRef(null)
  const { symbol, percentSymbol } = useCurrency()
  const { width } = useParentSize(ref)

  /* Getting translations instance */
  const { t } = useTranslations()

  const monthlyCashflow = prepareValue(
    rentalCalculator?.calculations?.monthlyCashflow
  )
  const grossYield = prepareValue(rentalCalculator?.calculations?.grossYield)
  const netYield = prepareValue(rentalCalculator?.calculations?.netYield)
  const totalCashRequired = prepareValue(
    rentalCalculator?.calculations?.totalCashRequired
  )

  const menuItems = [
    {
      key: 'edit',
      icon: 'Edit1Outlined',
      label: t('Edit'),
      onClick: () => {
        onEdit?.()
      }
    },
    {
      key: 'remove',
      icon: 'Trash1Outlined',
      label: t('Remove'),
      danger: true,
      onClick: () => {
        onDelete?.()
      }
    }
  ]

  const images = useMemo(
    () =>
      rentalCalculator?.picturesOfTheProperty?.map((url) => ({ url })) || [
        { url: noImage }
      ],
    [rentalCalculator]
  )

  const dotsMenu = (
    <Dropdown
      style={{ marginLeft: 'auto' }}
      placement="bottomRight"
      overlay={
        <Menu>
          {menuItems.map((item) => (
            <Menu.Item
              key={item.key}
              onClick={item.onClick}
              disabled={item.disabled}
              danger={item.danger}>
              <Box display="flex" alignItems="center">
                <Icon name={item.icon} size={16} mr={2} />
                <Text ml={2}>{item.label}</Text>
              </Box>
            </Menu.Item>
          ))}
        </Menu>
      }>
      <Button
        type="text"
        icon={<Icon name="DotsVerticalOutlined" size={22} />}
      />
    </Dropdown>
  )

  return (
    <Box>
      <Box
        ref={ref}
        mb={3}
        height={(width * 3) / 5 || null}
        width={width}
        style={{
          borderRadius: 'var(--ql-border-radius-md)',
          overflow: 'hidden'
        }}>
        {isHovered ? (
          <SimpleImageSlider
            slideDuration={1}
            autoPlayDelay={0.3}
            images={images}
            showBullets
            width="inherit"
            height="inherit"
            useGPURender
            autoPlay
            loop
          />
        ) : (
          <Image
            style={{ objectFit: 'cover', objectPosition: 'top' }}
            src={images[0]?.url}
            height="inherit"
            width="inherit"
            preview={false}
          />
        )}
      </Box>

      <Box
        mb={3}
        display="flex"
        alignItems="center"
        justifyContent="space-between">
        <Title
          mr={2}
          level={3}
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap">
          {rentalCalculator?.projectName}
        </Title>
        <Box onClick={(e) => e.stopPropagation()}>{dotsMenu}</Box>
      </Box>

      <Row mx="var(--ql-grid-gutter-negative)" width="inherit">
        <Col cw={6} mb={2}>
          <Tooltip title={t('Gross yield')}>
            <Text whiteSpace="noWrap" type="secondary" ellipsis>
              {t('Gross yield')}
            </Text>
          </Tooltip>
          <Text whiteSpace="noWrap">
            {grossYield && grossYield >= 0 && '+'} {grossYield}
            {percentSymbol}
          </Text>
        </Col>
        <Col cw={6} mb={2}>
          <Tooltip title={t('Net yield')}>
            <Text whiteSpace="noWrap" type="secondary" ellipsis>
              {t('Net yield')}
            </Text>
          </Tooltip>
          <Text whiteSpace="noWrap">
            {netYield && netYield >= 0 && '+'} {netYield}
            {percentSymbol}
          </Text>
        </Col>
        <Col cw={6}>
          <Tooltip title={t('Cashflow')}>
            <Text whiteSpace="noWrap" type="secondary" ellipsis>
              {t('Cashflow')}
            </Text>
          </Tooltip>
          <Text whiteSpace="noWrap">
            {monthlyCashflow}
            {symbol}
          </Text>
        </Col>
        <Col cw={6}>
          <Tooltip title={t('Total cash required')}>
            <Text whiteSpace="noWrap" type="secondary" ellipsis>
              {t('Required')}
            </Text>
          </Tooltip>
          <Text whiteSpace="noWrap">
            {totalCashRequired}
            {symbol}
          </Text>
        </Col>
      </Row>
    </Box>
  )
}

RentalCalculatorView.propTypes = {
  rentalCalculator: PropTypes.object,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onExport: PropTypes.func,
  isHovered: PropTypes.bool
}

export default memo(RentalCalculatorView)
