import {
  ClosingCostInput,
  InterestRateForRenovationCostInput,
  SplitSellingPriceInput
} from './components'
import { EnumSelect, ImageUploader } from 'components'
import { Form, Grid, Input } from 'antd'
import {
  FormItemInfo,
  InputAmountWithPercent,
  InputNumber,
  InputPercent,
  InputPrice,
  RoomInput,
  UrlInput
} from 'domains/RentalCalculator/components/RentalCalculatorSimpleForm/components'
import {
  OWNERSHIPS,
  OWNERSHIPS_OPTIONS,
  PROPERTY_TYPES_OPTIONS,
  PROPERTY_TYPES_WITH_FEE
} from '__constants__/enums'

import { DOWN_PAYMENT_PERCENTS_FLIPPING } from '__constants__/calculations/downPaymentsPercents'
import INTEREST_RATE_PERCENTS_FLIPPING from '__constants__/calculations/interestRatePercents'
import PropTypes from 'prop-types'
import { handleAfterChangeOwnership } from 'domains/RentalCalculator/components/RentalCalculatorSimpleForm/helpers'
import { useState } from 'react'
import { useTranslations } from 'contexts/Translation'

const FlippingCalculatorSimpleForm = (props) => {
  const {
    onSubmit,
    group,
    isAccessRestricted,
    form,
    initialOwnership,
    isPrintMode
  } = props
  const [ownership, setOwnership] = useState(
    initialOwnership || OWNERSHIPS.PERSONAL
  )

  const onBeforeAskingPriceChange = (value) => {
    const closingTaxVal = form.getFieldValue([group, 'closingTax'])
    const closingCostVal =
      Number(form.getFieldValue([group, 'closingCost'])) || 0
    const prevAskingPrice = form.getFieldValue([group, 'askingPrice'])
    if (closingTaxVal)
      form.setFieldsValue({
        [group]: {
          closingCost: !closingCostVal
            ? value * 0.025
            : closingCostVal - prevAskingPrice * 0.025 + value * 0.025
        }
      })
    const downPayment = form?.getFieldValue([...group, 'downPayment'])

    const { percent: dpPercent } = downPayment || {}

    const additionalFormValues = { flippingCalculator: {} }

    if (dpPercent || dpPercent === 0) {
      const amount = Number(((dpPercent / 100) * value)?.toFixed(0))
      additionalFormValues.flippingCalculator.downPayment = {
        amount,
        percent: dpPercent
      }
    }
    form?.setFieldsValue(additionalFormValues)
  }

  const { t } = useTranslations()
  const { xs } = Grid.useBreakpoint()

  const handleAfterChangePropertyType = (propertyType) => {
    const previousClosingTax = form.getFieldValue([...group, 'closingTax'])

    const newClosingTaxValue = PROPERTY_TYPES_WITH_FEE.includes(propertyType)
    const updatedFormObject = {
      [group]: {
        closingTax: newClosingTaxValue
      }
    }
    if (previousClosingTax !== newClosingTaxValue) {
      const closingCost = form.getFieldValue([...group, 'closingCost'])
      const askingPrice = form.getFieldValue([...group, 'askingPrice'])
      const closingTaxValue = askingPrice * 0.025
      updatedFormObject[group].closingCost = newClosingTaxValue
        ? closingCost + closingTaxValue
        : closingCost - closingTaxValue
    }
    form.setFieldsValue(updatedFormObject)
  }

  const handleAfterInterestRateChange = (interestRateValue) => {
    const isMatchInterestRate = form.getFieldValue([
      ...group,
      'matchInterestRate'
    ])
    if (isMatchInterestRate)
      form.setFieldsValue({
        [group]: { interestRateRenovationCost: interestRateValue }
      })
  }

  const handleRenovationCostChange = (value) => {
    const upfrontRenovationCost = Number(value)
    const financeRenovation = form.getFieldValue([group, 'financeRenovation'])
    if (financeRenovation?.percent !== 0)
      form.setFieldsValue({
        [group]: {
          financeRenovation: {
            amount: Number(
              Number(
                (upfrontRenovationCost * Number(financeRenovation?.percent)) /
                  100
              ).toFixed(2)
            ),
            percent: financeRenovation?.percent
          }
        }
      })
  }

  return (
    <>
      <Form.Item
        label={t('Project name')}
        name={[...group, 'projectName']}
        rules={[
          {
            required: true,
            message: t('Please enter project name!')
          }
        ]}>
        <Input
          disabled={isAccessRestricted}
          placeholder={t('Forrest cottage, Hotel apartments')}
          onPressEnter={onSubmit}
        />
      </Form.Item>
      <Form.Item
        label={
          <FormItemInfo
            title={t('Zillow URL')}
            info={t('zillowUrlFieldInfo')}
          />
        }
        name={[...group, 'zillowLink']}
        rules={[
          {
            required: false,
            message: t('Please enter zillow url!')
          }
        ]}>
        <UrlInput
          calculatorType="flippingCalculator"
          disabled={isAccessRestricted}
          onOwnershipChange={setOwnership}
          placeholder="https://"
          form={form}
        />
      </Form.Item>
      <Form.Item
        label={
          <FormItemInfo title={t('Ownership')} info={t('ownershipFieldInfo')} />
        }
        name={[...group, 'ownership']}>
        <EnumSelect
          isPrintMode={isPrintMode}
          onChangeAfter={(ownershipValue) =>
            handleAfterChangeOwnership({
              downPaymentConfig: DOWN_PAYMENT_PERCENTS_FLIPPING,
              ownership: ownershipValue,
              interestRateConfig: INTEREST_RATE_PERCENTS_FLIPPING,
              setOwnership,
              form,
              group,
              handleAfterChangePropertyType
            })
          }
          disabled={isAccessRestricted}
          options={OWNERSHIPS_OPTIONS}
        />
      </Form.Item>
      <Form.Item
        name={[...group, 'propertyType']}
        label={
          <FormItemInfo
            title={t('Property type')}
            disabled={isAccessRestricted}
            info={
              ownership === OWNERSHIPS.COMPANY
                ? t('companyPropertyTypeFieldInfo')
                : t('propertyTypeFieldInfo')
            }
          />
        }>
        <EnumSelect
          isPrintMode={isPrintMode}
          disabled={isAccessRestricted}
          onChangeAfter={handleAfterChangePropertyType}
          options={PROPERTY_TYPES_OPTIONS[ownership]}
        />
      </Form.Item>
      <Form.Item
        label={
          <FormItemInfo
            title={t('Asking price')}
            info={t('askingPriceFieldInfo')}
          />
        }
        name={[...group, 'askingPrice']}
        rules={[
          {
            required: true,
            message: t('Please enter asking price!')
          }
        ]}>
        <InputPrice
          onBeforeChange={onBeforeAskingPriceChange}
          disabled={isAccessRestricted}
          placeholder={t('Placeholder')}
        />
      </Form.Item>
      <Form.Item
        label={
          <FormItemInfo
            title={t('Down payment')}
            info={t('downPaymentFieldInfo')}
          />
        }
        name={[...group, 'downPayment']}>
        <InputAmountWithPercent
          disabled={isAccessRestricted}
          placeholder={t('Placeholder')}
          targetField="askingPrice"
          group={group}
          form={form}
          max={100}
        />
      </Form.Item>
      <Form.Item
        label={
          <FormItemInfo
            title={t('Interest rate')}
            info={t('interestRateFieldInfo')}
          />
        }
        name={[...group, 'interestRate']}>
        <InputPercent
          max={30}
          customOnChange={handleAfterInterestRateChange}
          step={0.05}
          form={form}
          disabled={isAccessRestricted}
          placeholder={t('Placeholder')}
        />
      </Form.Item>
      <Form.Item
        label={
          <FormItemInfo
            title={t('Closing cost')}
            info={t('closingCostFieldInfo')}
          />
        }
        name={[...group, 'closingCost']}>
        <ClosingCostInput
          group={group}
          form={form}
          isAccessRestricted={isAccessRestricted}
          xs={xs}
          isPrintMode={isPrintMode}
        />
      </Form.Item>
      <Form.Item
        label={
          <FormItemInfo
            title={t('Months from start to finish')}
            info={t('monthsFromStartToFinishFieldInfo')}
          />
        }
        name={[...group, 'monthsFromStartToFinish']}>
        <InputNumber
          disabled={isAccessRestricted}
          placeholder={t('Placeholder')}
        />
      </Form.Item>
      <Form.Item
        label={
          <FormItemInfo
            title={t('Cost of renovation')}
            info={t('upfrontRenovationFieldInfo')}
          />
        }
        name={[...group, 'upfrontRenovation']}>
        <InputPrice
          onBeforeChange={handleRenovationCostChange}
          disabled={isAccessRestricted}
          placeholder={t('Placeholder')}
        />
      </Form.Item>
      <Form.Item
        label={
          <FormItemInfo
            title={t('Finance the renovation')}
            info={t('financeTheRenovationFieldInfo')}
          />
        }
        name={[...group, 'financeRenovation']}>
        <InputAmountWithPercent
          disabled={isAccessRestricted}
          placeholder={t('Placeholder')}
          targetField="upfrontRenovation"
          group={group}
          form={form}
          max={100}
        />
      </Form.Item>
      <Form.Item
        label={
          <FormItemInfo
            title={t('Interest rate for renovation cost')}
            info={t('interestRateForRenovationCostFieldInfo')}
          />
        }
        name={[...group, 'interestRateRenovationCost']}>
        <InterestRateForRenovationCostInput
          form={form}
          targetField="interestRate"
          max={30}
          step={0.05}
          group={group}
          disabled={isAccessRestricted}
          placeholder={t('Placeholder')}
        />
      </Form.Item>
      <Form.Item
        label={
          <FormItemInfo
            title={t('Municipal Taxes')}
            info={t('municipalTaxesFieldInfo')}
          />
        }
        name={[...group, 'fixedExpenses']}>
        <InputPrice
          disabled={isAccessRestricted}
          placeholder={t('Placeholder')}
        />
      </Form.Item>
      <Form.Item
        label={
          <FormItemInfo
            title={`${t('Common expenses')} (${t('month')})`}
            info={t('commonExpensesFieldInfo')}
          />
        }
        name={[...group, 'commonExpenses']}>
        <InputPrice
          disabled={isAccessRestricted}
          placeholder={t('Placeholder')}
        />
      </Form.Item>
      <Form.Item
        label={
          <FormItemInfo
            title={`${t('Electricity')} (${t('month')})`}
            info={t('electricityFieldInfo')}
          />
        }
        name={[...group, 'electricity']}>
        <InputPrice
          disabled={isAccessRestricted}
          placeholder={t('Placeholder')}
        />
      </Form.Item>
      <Form.Item
        label={
          <FormItemInfo title={t('Insurance')} info={t('insuranceFieldInfo')} />
        }
        name={[...group, 'insurance']}>
        <InputPrice
          disabled={isAccessRestricted}
          placeholder={t('Placeholder')}
        />
      </Form.Item>
      <Form.Item
        label={
          <FormItemInfo
            title={t('Selling fees')}
            info={t('sellingFeesFieldInfo')}
          />
        }
        name={[...group, 'sellingFees']}>
        <InputPrice
          disabled={isAccessRestricted}
          placeholder={t('Placeholder')}
        />
      </Form.Item>
      <Form.Item
        label={
          <FormItemInfo
            title={t('Total rental income from start to finish')}
            info={t('totalRentalIncomeFromStartToFinishFieldInfo')}
          />
        }
        name={[...group, 'totalRentalIncomeFromStartToFinish']}>
        <InputPrice
          disabled={isAccessRestricted}
          placeholder={t('Placeholder')}
        />
      </Form.Item>
      <Form.Item
        label={t('Primary room')}
        name={[...group, 'primaryRoom']}
        rules={[
          {
            required: false,
            message: t('Please enter primary room!')
          }
        ]}>
        <RoomInput
          disabled={isAccessRestricted}
          placeholder={t('Placeholder')}
        />
      </Form.Item>
      <Form.Item
        label={
          <FormItemInfo
            title={`${t('Estimated selling price')} (${t('best case')})`}
            info={t('estimatedSellingPriceBestCaseFieldInfo')}
          />
        }
        name={[...group, 'estimatedSellingBestCasePrice']}>
        <SplitSellingPriceInput
          form={form}
          group={group}
          isAccessRestricted={isAccessRestricted}
        />
      </Form.Item>
      <Form.Item
        label={
          <FormItemInfo
            title={`${t('Estimated selling price')} (${t('worst case')})`}
            info={t('estimatedSellingPriceWorstCaseFieldInfo')}
          />
        }
        name={[...group, 'estimatedSellingPriceWorstCase']}>
        <SplitSellingPriceInput
          form={form}
          group={group}
          isAccessRestricted={isAccessRestricted}
        />
      </Form.Item>
      <Form.Item
        label={t('Property photos')}
        style={{ flexFlow: xs ? null : 'column' }}
        name={[...group, 'picturesOfTheProperty']}>
        <ImageUploader
          disabled={isAccessRestricted}
          placeholder={t('Please enter picture of the property')}
        />
      </Form.Item>
    </>
  )
}

FlippingCalculatorSimpleForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  showTitle: PropTypes.bool,
  form: PropTypes.any,
  group: PropTypes.array,
  isAccessRestricted: PropTypes.bool,
  initialOwnership: PropTypes.string,
  isPrintMode: PropTypes.bool,
  formValues: PropTypes.object
}

export default FlippingCalculatorSimpleForm
