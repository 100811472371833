import { Box, Col, Row } from '@qonsoll/react-design'

import { Icon } from '@qonsoll/icons'
import { Image } from 'antd'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledCol = styled(Col)`
  position: relative;
  &:hover {
    .remove {
      display: flex;
    }
  }
  &.remove {
    display: none;
  }
`

const StyledRemove = styled(Box)`
  display: none;
  position: absolute;
  right: 8px;
  top: -16px;
  border: 2px solid #ffffff;
  border-radius: 50%;
  background: var(--ql-color-danger);
  z-index: 1;
  width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const FilesList = (props) => {
  const { fileList, onRemove } = props

  if (!fileList?.length) return null

  return (
    <Row mt={3} mx="var(--ql-grid-gutter-negative)">
      {fileList?.map((url, index) => (
        <StyledCol key={url} cw={[12, 12, 6]} mb={4}>
          <StyledRemove className="remove" onClick={() => onRemove(url, index)}>
            <Icon name="XCloseOutlined" size={16} fill="#fff" />
          </StyledRemove>
          <Image
            src={url}
            alt="url"
            height={['200px', '160px']}
            crossOrigin="anonymous"
            width="100%"
            style={{
              objectFit: 'cover',
              maxHeight: '180px',
              borderRadius: 'var(--ql-border-radius-md)'
            }}
          />
        </StyledCol>
      ))}
    </Row>
  )
}

FilesList.propTypes = {
  fileList: PropTypes.array,
  onRemove: PropTypes.func
}

export default FilesList
