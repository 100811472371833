import {
  COMPANY_PROPERTY_TYPES,
  OWNERSHIPS,
  PERSONAL_PROPERTY_TYPES,
  PRODUCT_TYPE_PACKAGE,
  PROPERTY_TYPES,
  PROPERTY_TYPES_WITH_FEE,
  RENTAL_UNITS
} from '__constants__/enums'

import { INPUT_NUMBER_REGEX } from '__constants__/regExps'
import { fetchApi } from 'services/api/rest'
import { notification } from 'antd'
import { useState } from 'react'
import { useTranslations } from 'contexts/Translation'
import { useUser } from 'domains/User/context'

const normalizeField = (field, { monthCheck = true, fixed = 1 } = {}) => {
  if (!field) return null

  const number = field?.replace?.(INPUT_NUMBER_REGEX, '')
  if (monthCheck && field?.toLowerCase()?.includes('år')) {
    return (Number(number) / 12).toFixed(fixed) || null
  }
  return Number(number).toFixed(fixed) || null
}

const findPropertyType = (propertyType) => {
  if (!propertyType) return null

  const propertyTypesNo = [
    { value: PROPERTY_TYPES.FREEHOLD, label: 'Selveier' },
    { value: PROPERTY_TYPES.SHARE, label: 'Andel' },
    { value: PROPERTY_TYPES.SHARE, label: 'Aksje' }
  ]

  const propertyTypeNo = propertyTypesNo.find((item) =>
    propertyType.toLowerCase().includes(item.label.toLowerCase())
  )

  return propertyTypeNo?.value || null
}

const hasPackageSubscription = (user) => {
  return (
    !!user?.subscriptions?.[PRODUCT_TYPE_PACKAGE] &&
    (user?.isProAccess || user?.isFlippingProAccess)
  )
}

const useScrapDataByUrl = (calculatorType) => {
  const { user } = useUser()
  const { t } = useTranslations()
  const [loading, setLoading] = useState(false)

  const scrapData = async (url) => {
    try {
      setLoading(true)
      const res = await fetchApi({
        method: 'POST',
        path: 'scrapDataByUrl',
        body: {
          url,
          uid: user._id,
          calculatorType,
          hasPackage: hasPackageSubscription(user)
        }
      })

      const { address, numberOfBedrooms } = res?.data || {}
      const isBusiness = url?.includes('business') || false

      const askingPrice = normalizeField(res?.data?.askingPrice) || 0
      const primaryRoom = normalizeField(res?.data?.primaryArea)
      const usableArea = normalizeField(res?.data?.usableArea, { fixed: 0 })
      const commonExpenses = normalizeField(res?.data?.commonExpenses)
      const image = res?.data?.images?.[0]?.['1280']
      const fixedExpenses = normalizeField(res?.data?.municipalTaxes, {
        monthCheck: false
      })
      const propertyType = findPropertyType(res?.data?.propertyType)
      const closingCost = normalizeField(res?.data?.closingCost)

      const data = {
        picturesOfTheProperty: image ? [image] : null,
        primaryRoom:
          calculatorType === 'flippingCalculator'
            ? primaryRoom || usableArea
            : usableArea || primaryRoom,
        projectName: address,
        askingPrice,
        numberOfBedrooms,
        commonExpenses,
        fixedExpenses,
        propertyType,
        closingCost
      }

      if (isBusiness) {
        data.ownership = OWNERSHIPS.COMPANY
        data.rentalUnit = RENTAL_UNITS.RESIDENTIAL
        if (!COMPANY_PROPERTY_TYPES.includes(data.propertyType)) {
          data.propertyType = PROPERTY_TYPES.BUY_PROPERTY
        }
      } else {
        data.ownership = OWNERSHIPS.PERSONAL
        data.rentalUnit = RENTAL_UNITS.OWN_PROPERTY
        if (!PERSONAL_PROPERTY_TYPES.includes(data.propertyType)) {
          data.propertyType = PROPERTY_TYPES.BUY_PROPERTY
        }
      }

      if (data.propertyType) {
        data.closingTax = PROPERTY_TYPES_WITH_FEE.includes(data.propertyType)
      }

      notification.success({
        message: t('Success'),
        description: t('Url data was successfully fetched!')
      })

      return data
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)

      notification.error({
        message: t('Error'),
        description: t('Url data was not fetched!')
      })

      return null
    } finally {
      setLoading(false)
    }
  }

  return [scrapData, loading]
}

export default useScrapDataByUrl
