import { Col, Row } from '@qonsoll/react-design'
import { useRef, useState } from 'react'

import { InputPrice } from 'domains/RentalCalculator/components/RentalCalculatorSimpleForm/components'
import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'

const SplitSellingPriceInput = (props) => {
  const { isAccessRestricted, value, onChange, form, group } = props
  const { t } = useTranslations()
  const kvm = Number(form.getFieldValue([group, 'primaryRoom'])) || 1
  const [perMetrValue, setPerMetrValue] = useState(
    value ? Math.round(value / kvm) : null
  )
  const perMetrRef = useRef(null)

  const onMainValueChange = (value) => {
    const kvm = Number(form.getFieldValue([group, 'primaryRoom'])) || 1
    onChange(value)
    setPerMetrValue(Math.round(value / kvm))
  }

  const onPerMetrValueChange = (value) => {
    setPerMetrValue(value)
    const kvm = Number(form.getFieldValue([group, 'primaryRoom'])) || 1
    onChange?.(Math.round(value * kvm))
  }

  return (
    <Row noGutters>
      <Col cw={6} style={{ paddingRight: '4px' }}>
        <InputPrice
          value={value}
          onChange={onMainValueChange}
          disabled={isAccessRestricted}
          placeholder={t('Placeholder')}
        />
      </Col>
      <Col cw={6} style={{ paddingLeft: '4px' }}>
        <InputPrice
          value={perMetrValue}
          inputPriceRef={perMetrRef}
          onChange={onPerMetrValueChange}
          disabled={isAccessRestricted}
          placeholder={t('Price per m2')}
          mainSuffixSymbol=",-/m2"
        />
      </Col>
    </Row>
  )
}

SplitSellingPriceInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  form: PropTypes.object,
  group: PropTypes.array,
  isAccessRestricted: PropTypes.bool
}

export default SplitSellingPriceInput
