import calculateTax from '../calculate/calculateTax'

const calculateMonthlyRent = (values) => {
  const {
    year,
    monthlyPayment,
    operatingExpenses,
    rentalIncome,
    rentGrowthRate,
    interest,
    yearlyInterest,
    installment,
    vacancy,
    rentalUnit,
    isNetProfitPropertyGrowth,
    propertyValueIncrease,
    upfrontRenovation,
    previousMonthlyIncomes,
    previousMonthlyExpenses,
    previousYearlyInterests,
    previousPropertyValueIncreases
  } = values

  /* Income */
  const monthlyIncome =
    rentalIncome *
    Math.pow(1 + rentGrowthRate / 100, year) *
    (1 - vacancy / 100)

  /* Expenses */
  const monthlyOperatingExpenses =
    operatingExpenses * Math.pow(1 + rentGrowthRate / 100, year)
  const monthlyExpenses = monthlyOperatingExpenses

  /* Profit: Rental income - expenses - interest expenses */
  const monthlyProfit = monthlyIncome - monthlyExpenses - interest

  /* Monthly cashflow: Rental income - expenses - mortgage - tax */
  const grossCashflow = monthlyIncome - monthlyExpenses - interest

  /* Calculating yearly profit to determine whether we need to calculate tax or not */
  const previousNetProfit =
    year === 0
      ? monthlyIncome * 12 -
        monthlyExpenses * 12 -
        yearlyInterest +
        (isNetProfitPropertyGrowth ? propertyValueIncrease : 0)
      : previousMonthlyIncomes.reduce((acc, curr, index) => {
          const previousYearsProfit =
            curr * 12 -
            previousMonthlyExpenses[index] * 12 -
            previousYearlyInterests[index]
          const previousIncrease = isNetProfitPropertyGrowth
            ? previousPropertyValueIncreases[index]
            : 0
          return previousYearsProfit + previousIncrease + acc
        }, 0)

  // const currentYearlyProfit =
  //   monthlyIncome * 12 - monthlyExpenses * 12 - yearlyInterest
  // const currentIncrease = isNetProfitPropertyGrowth ? propertyValueIncrease : 0
  // const currentYearlyProfitBeforeTax = currentYearlyProfit + currentIncrease

  /* ****************************************************************************** */
  const { tax } = calculateTax(
    grossCashflow,
    rentalUnit,
    previousNetProfit,
    upfrontRenovation
  )

  /* Net cashflow: Gross cashflow - tax - installment */
  const netCashflow = grossCashflow - tax - installment

  return {
    grossRent: monthlyIncome,
    operatingExpenses: monthlyOperatingExpenses,
    grossCashflow: grossCashflow,
    monthlyCashflow: netCashflow,
    mortgage: monthlyPayment,
    tax: tax,
    netCashflow: netCashflow,
    interest: interest,
    installment: installment,
    monthlyProfit,

    monthlyExpenses,
    monthlyIncome
  }
}

export default calculateMonthlyRent
