import { Button, Col, Row } from '@qonsoll/react-design'
import { PageLayout, Spinner } from 'components'
import {
  useActionsRentalCalculatorAdvancedForm,
  useInitializeRentalCalculatorAdvancedForm
} from 'domains/RentalCalculator/components/RentalCalculatorAdvancedForm/hooks'

import { Form } from 'antd'
import { LoanApplicationButton } from 'domains/Loan/components'
import { RentalCalculatorAdvancedForm } from 'domains/RentalCalculator/components'
import { useCallback } from 'react'
import { useGetRentalCalculatorInitialValues } from 'domains/RentalCalculator/hooks'
import { useParams } from 'react-router-dom'
import { usePrint } from 'hooks'
import { useSendLoanApplication } from 'domains/Loan/hooks'
import { useTranslations } from 'contexts/Translation'
import { useUser } from 'domains/User/context'

const RentalCalculatorEditPage = (props) => {
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting params from router */
  const params = useParams()
  /* Getting id from params */
  const { projectId: rentalCalculatorId } = params
  const { isProAccess } = useUser()

  /* Fetching rentalCalculator initial values */
  const [initialValues, loading] =
    useGetRentalCalculatorInitialValues(rentalCalculatorId)

  /* Getting form instance from hook */
  const { form, loading: initialization } =
    useInitializeRentalCalculatorAdvancedForm({
      initialData: initialValues,
      isProAccess
    })

  const projectName = Form.useWatch(['rentalCalculator', 'projectName'], form)
  const finnLink = Form.useWatch(['rentalCalculator', 'zillowLink'], form)
  const [handleSendLoanApplication] = useSendLoanApplication()
  const onPDFCreatedCallback = useCallback(
    async (additionalArgs) =>
      await handleSendLoanApplication({
        projectName,
        finnLink,
        ...additionalArgs
      }),
    [projectName, finnLink, handleSendLoanApplication]
  )
  const { print, PrintWrapper, isPrintMode, download, isLoanSendMode } =
    usePrint(onPDFCreatedCallback)

  /* Getting loading state and actions from hook */
  const { onFinish } = useActionsRentalCalculatorAdvancedForm({
    rentalCalculatorId: rentalCalculatorId,
    initialData: initialValues,
    form
  })

  return (
    <PageLayout
      title={t('Project edit')}
      subTitle={t(
        'Follow the instructions bellow to get results in shortest possible time'
      )}
      actions={
        <Row mx="var(--ql-grid-gutter-negative)">
          <Col mb={[2, 0]}>
            <LoanApplicationButton
              projectName={projectName}
              form={form}
              isLoanSendMode={isLoanSendMode}
              download={download}
            />
          </Col>
          <Col mb={[2, 0]}>
            <Button
              block
              disabled={!isProAccess}
              loading={isPrintMode}
              onClick={print}>
              {t('Export to PDF')}
            </Button>
          </Col>
          <Col>
            <Button
              block
              type="primary"
              disabled={!isProAccess}
              onClick={() => form.submit()}>
              {t('Save project')}
            </Button>
          </Col>
        </Row>
      }>
      <Row noGutters>
        {loading || initialization ? (
          <Col cw={12}>
            <Spinner height="70vh" text={t('Rental calculator loading')} />
          </Col>
        ) : (
          <Col cw={12}>
            <RentalCalculatorAdvancedForm
              isAccessGranted={isProAccess}
              initialData={initialValues}
              onFinish={onFinish}
              form={form}
            />
            <PrintWrapper>
              <RentalCalculatorAdvancedForm
                withId
                isAccessGranted={isProAccess}
                initialData={initialValues}
                onFinish={onFinish}
                form={form}
              />
            </PrintWrapper>
          </Col>
        )}
      </Row>
    </PageLayout>
  )
}

export default RentalCalculatorEditPage
