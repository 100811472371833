import { Box, Title } from '@qonsoll/react-design'
import {
  EquitySummary,
  MonthlyNetProfitSummary,
  MortgageSummary,
  PropertyValueSummary,
  SummaryItem,
  YearlyNetProfitSummary
} from './components'

import { Form } from 'antd'
import PropTypes from 'prop-types'
import { useCurrency } from 'contexts/Currency'
import { useTranslations } from 'contexts/Translation'

const RentalCalculatorSummary = (props) => {
  const { isAccessGranted, group, value, isPrintMode } = props

  const { t } = useTranslations()
  const { symbol, percentSymbol } = useCurrency()

  const {
    netProfits = [],
    equities = [],
    mortgages = [],
    propertyValues = [],
    monthlyProfits = []
  } = value || {}

  const isLocked = !isAccessGranted

  const items = [
    {
      label: t('Monthly cashflow'),
      locked: isLocked,
      field: 'monthlyCashflow',
      measurementSymbol: symbol,
      info: t('monthlyCashflowInfo'),
      roundTo: 0
    },
    {
      label: t('Yearly net profit'),
      locked: isLocked,
      field: 'yearlyNetProfit',
      measurementSymbol: symbol,
      info: t('yearlyNetProfitInfo'),
      roundTo: 0
    },
    {
      label: t('Total cash required'),
      locked: isLocked,
      field: 'totalCashRequired',
      measurementSymbol: symbol,
      info: t('totalCashRequiredInfo'),
      roundTo: 0
    },
    {
      label: t('Property value'),
      locked: isLocked,
      field: 'propertyValue',
      measurementSymbol: symbol,
      info: t('propertyValueInfo'),
      roundTo: 0
    },
    {
      label: t('Equity in property'),
      locked: isLocked,
      field: 'equityInProperty',
      measurementSymbol: symbol,
      info: t('equityInPropertyInfo'),
      roundTo: 0
    },
    {
      label: t('Total debt'),
      locked: isLocked,
      field: 'debt',
      measurementSymbol: symbol,
      info: t('totalDebtInfo'),
      roundTo: 0
    },
    {
      label: t('Debt to value ratio'),
      locked: isLocked,
      field: 'debtToValueRatio',
      measurementSymbol: percentSymbol,
      info: t('debtToValueRatioInfo'),
      roundTo: 1
    },
    {
      label: t('Remaining cash invested'),
      locked: isLocked,
      field: 'remainingCashInvested',
      measurementSymbol: symbol,
      info: t('remainingCashInvestedInfo'),
      roundTo: 0
    },
    // {
    //   label: t('Years before investment is made back'),
    //   locked: isLocked,
    //   field: 'yearsToPayOff',
    //   info: t('yearsToPayOffInfo')
    // },
    {
      label: t('Annualized ROI'),
      locked: isLocked,
      field: 'annualROI',
      measurementSymbol: percentSymbol,
      info: t('annualROIInfo'),
      roundTo: 2
    },
    {
      label: t('Net cost yield'),
      locked: isLocked,
      field: 'netCostYield',
      measurementSymbol: percentSymbol,
      info: t('netCostYieldInfo'),
      roundTo: 2
    },
    {
      label: t('Net market yield'),
      locked: isLocked,
      field: 'netMarketYield',
      measurementSymbol: percentSymbol,
      info: t('netMarketYieldInfo'),
      roundTo: 2
    }
  ]

  return (
    <Box width="inherit">
      <Title level={2} mb={40}>
        {t('Summary calculations')}
      </Title>
      <Box mb={40}>
        {items?.map((i) => (
          <Form.Item
            key={i.label}
            name={[...group, i.field]}
            style={{ margin: 0 }}>
            <SummaryItem {...i} />
          </Form.Item>
        ))}
      </Box>
      <Box mb={40} width="inherit">
        <EquitySummary
          isPrintMode={isPrintMode}
          measurementSymbol={symbol}
          isLocked={isLocked}
          options={equities}
        />
      </Box>
      <Box mb={40} width="inherit">
        <YearlyNetProfitSummary
          isPrintMode={isPrintMode}
          measurementSymbol={symbol}
          isLocked={isLocked}
          options={netProfits}
          group={group}
        />
      </Box>
      <Box mb={40}>
        <MonthlyNetProfitSummary
          isPrintMode={isPrintMode}
          measurementSymbol={symbol}
          monthlyProfits={monthlyProfits}
          years={value?.years}
          isLocked={isLocked}
        />
      </Box>
      <Box mb={40}>
        <Form.Item name={[...group, 'mortgage']} style={{ margin: 0 }}>
          <MortgageSummary
            isPrintMode={isPrintMode}
            measurementSymbol={symbol}
            propertyValues={propertyValues}
            mortgages={mortgages}
            isLocked={isLocked}
            years={value?.years}
          />
        </Form.Item>
      </Box>
      <Box width="inherit">
        <PropertyValueSummary
          isPrintMode={isPrintMode}
          options={propertyValues}
          measurementSymbol={symbol}
        />
      </Box>
    </Box>
  )
}

RentalCalculatorSummary.propTypes = {
  isAccessGranted: PropTypes.bool,
  group: PropTypes.array,
  value: PropTypes.object,
  isPrintMode: PropTypes.bool
}

export default RentalCalculatorSummary
