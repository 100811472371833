import { Box, Title } from '@qonsoll/react-design'
import { EquityDiagram, EquityProfitList } from './components'
import { useMemo, useState } from 'react'

import DiagramStateSwitcher from '../DiagramStateSwitcher'
import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'

const PropertyValueSummary = (props) => {
  const { options, isLocked, measurementSymbol, isPrintMode } = props

  const { t } = useTranslations()
  const [isDiagram, setIsDiagram] = useState(true)

  const optionsMapped = useMemo(
    () =>
      options?.map((i, index) => ({
        locked: isLocked && index !== 0,
        label: index + 1,
        value: i
      })),
    [options, isLocked]
  )

  return (
    <>
      <Box
        mb={4}
        display="flex"
        alignItems="center"
        justifyContent="space-between">
        <Title level={3}>{t('Property Value')}</Title>
        <DiagramStateSwitcher
          isPrintMode={isPrintMode}
          setIsDiagram={setIsDiagram}
          isDiagram={isDiagram}
        />
      </Box>
      {isDiagram ? (
        <EquityDiagram options={optionsMapped} />
      ) : (
        <EquityProfitList
          measurementSymbol={measurementSymbol}
          options={optionsMapped}
          isLocked={isLocked}
        />
      )}
    </>
  )
}

PropertyValueSummary.propTypes = {
  options: PropTypes.array,
  isLocked: PropTypes.bool,
  measurementSymbol: PropTypes.string,
  years: PropTypes.number,
  isPrintMode: PropTypes.bool
}

export default PropertyValueSummary
