import { Form, Grid, Space } from 'antd'

import ClosingCostTaxCheckbox from '../ClosingCostTaxCheckbox'
import { Info } from 'components'
import InputPrice from '../InputPrice'
import PropTypes from 'prop-types'
import { Text } from '@qonsoll/react-design'
import { memo } from 'react'
import { useTranslations } from 'contexts/Translation'

const AskingPriceInput = (props) => {
  const {
    onChange,
    group,
    form,
    isPrintMode,
    formGroupName = 'rentalCalculator',
    disabled,
    ...rest
  } = props

  /* Getting translations instance */
  const { t } = useTranslations()
  const { xs } = Grid.useBreakpoint()

  const calculateNewClosingCost = (newAskingPrice) => {
    const closingTaxChecked = form?.getFieldValue([...group, 'closingTax'])
    const prevClosingCost = form?.getFieldValue([...group, 'closingCost']) || 0
    const prevAskingPrice = form.getFieldValue([group, 'askingPrice']) || 0

    // If closing tax is not checked, we don't need to calculate new closing cost
    if (!closingTaxChecked) return prevClosingCost

    const newAskingPriceTax = newAskingPrice * 0.025
    // If there is no previous closing cost added by user
    if (!prevClosingCost) return Number(newAskingPriceTax)?.toFixed(0)

    // If there is previous closing cost added by user
    // we need to remove previous asking price tax and add new asking price tax
    const prevAskingPriceTax = prevAskingPrice * 0.025
    const newValue = prevClosingCost - prevAskingPriceTax + newAskingPriceTax
    const newValueNormalized = Number(newValue)?.toFixed(0)

    return newValueNormalized
  }

  const handleChange = ({ price, formGroupName }) => {
    const downPayment = form?.getFieldValue([...group, 'downPayment'])

    const { percent: dpPercent } = downPayment || {}

    const additionalFormValues = { [formGroupName]: {} }

    additionalFormValues[formGroupName].newPropertyValue = price
    additionalFormValues[formGroupName].refinance = 0
    if (dpPercent || dpPercent === 0) {
      const amount = Number(((dpPercent / 100) * price)?.toFixed(0))
      additionalFormValues[formGroupName].downPayment = {
        amount,
        percent: dpPercent
      }
    }

    additionalFormValues[formGroupName].closingCost =
      calculateNewClosingCost(price)

    form?.setFieldsValue(additionalFormValues)

    onChange(price)
  }

  return (
    <InputPrice
      onBeforeChange={(price) => handleChange({ price, formGroupName })}
      addonAfter={
        <Space>
          <Form.Item
            style={{ margin: 0 }}
            name={[...group, 'closingTax']}
            valuePropName="checked">
            <ClosingCostTaxCheckbox
              group={group}
              form={form}
              isAccessRestricted={disabled}
            />
          </Form.Item>
          <Text disabled={disabled} whiteSpace="nowrap">
            2.5% {xs || isPrintMode ? null : t('tax')}
          </Text>
          <Info title={t('Property closing fee')} />
        </Space>
      }
      disabled={disabled}
      {...rest}
    />
  )
}

AskingPriceInput.propTypes = {
  onChange: PropTypes.func,
  group: PropTypes.array,
  form: PropTypes.object,
  isPrintMode: PropTypes.bool,
  disabled: PropTypes.bool,
  formGroupName: PropTypes.string
}

export default memo(AskingPriceInput)
