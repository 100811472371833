import { StyledCard, cardStyles } from './SelectableCard.styled'

import PropTypes from 'prop-types'

/**
 * If the card is selected, use the selected styles, otherwise use the default styles.
 * @param isSelected {boolean} - If true, the card is selected
 * @param onSelect {function} - The function to call when the card is selected
 */
const SelectableCard = (props) => {
  const { isSelected, onSelect, children, ...rest } = props

  // [COMPUTED_PROPERTIES]
  const styles = isSelected ? cardStyles.selected : cardStyles.default

  return (
    <StyledCard onClick={onSelect} bodyStyle={styles} {...rest}>
      {children}
    </StyledCard>
  )
}

SelectableCard.propTypes = {
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func,
  children: PropTypes.node
}

export default SelectableCard
