import { Col, Row } from '@qonsoll/react-design'

import { AddItemCard } from 'components'
import { FlippingCalculatorSimpleView } from 'domains/FlippingCalculator/components'
import PropTypes from 'prop-types'
import { useFlippingCalculatorBaseActions } from 'domains/FlippingCalculator/hooks'
import { useTranslations } from 'contexts/Translation'

const FlippingCalculatorList = (props) => {
  const {
    flippingCalculators,
    isListWithCreate,
    listView,
    cw = [12, 12, 12, 12, 6, 4]
  } = props

  const isEmpty = !flippingCalculators?.length
  const itemWidth = listView ? 12 : cw
  const { t } = useTranslations()
  const actions = useFlippingCalculatorBaseActions()

  return (
    <Row mx="var(--ql-grid-gutter-negative)">
      {isListWithCreate && (
        <Col mb={4} cw={itemWidth}>
          <AddItemCard
            message={t('Create new project')}
            onClick={actions?.onCreate}
            minHeight={300}
            height="100%"
          />
        </Col>
      )}
      {!isEmpty &&
        flippingCalculators.map((flippingCalculator, index) => (
          <Col mb={4} key={flippingCalculator?._id || index} cw={itemWidth}>
            <FlippingCalculatorSimpleView
              flippingCalculator={flippingCalculator}
              index={index}
            />
          </Col>
        ))}
    </Row>
  )
}

FlippingCalculatorList.propTypes = {
  flippingCalculators: PropTypes.array,
  isListWithCreate: PropTypes.bool,
  listView: PropTypes.bool,
  cw: PropTypes.any
}

export default FlippingCalculatorList
