const calculateSummaryProfit = (values) => {
  const {
    operatingExpenses,
    yearlyProfitBeforeTax,
    equities,
    propertyValue,
    debt
  } = values

  const askingPrice = Number(values?.askingPrice) || 0
  const renovation = Number(values?.upfrontRenovation) || 0
  const downPayment = Number(values?.downPayment?.amount) || 0
  const rentalIncome = Number(values?.rentalIncome) || 0
  const closingCost = Number(values?.closingCost) || 0
  const refinance = Number(values?.refinance) || 0

  const financeRenovation = Number(values?.financeRenovation?.amount) || 0

  const netCostYield =
    ((rentalIncome * 12 - operatingExpenses * 12) /
      (askingPrice + closingCost + renovation)) *
    100

  const netMarketYield =
    ((rentalIncome * 12 - operatingExpenses * 12) /
      (values?.newPropertyValue || askingPrice)) *
    100
  const grossYield = ((rentalIncome * 12) / propertyValue) * 100
  const netYield =
    ((rentalIncome * 12 - operatingExpenses * 12) / propertyValue) * 100

  /* Total cash required: Down payment, document fee, closing costs and renovation costs */
  const totalCashRequired =
    downPayment + renovation + closingCost - financeRenovation

  /* Yearly profits before tax / Total cash invested */
  const annualROI = totalCashRequired
    ? (yearlyProfitBeforeTax / totalCashRequired) * 100
    : 0

  /* Years before investment is made back: The year equity surpass down payment + Total cash required */
  const yearsToPayOff = equities.reduce((acc, equity, index) => {
    if (acc) return acc
    if (equity > totalCashRequired) return index
    return acc
  }, null)

  /* The remaining cash invested as you see in the picture will be: Total cash required - cash taken out when refinance'ing */
  const remainingCashInvested = totalCashRequired - refinance

  /* New value - debt */
  const equityInProperty = propertyValue - debt

  return {
    annualROI,
    grossYield,
    netYield,
    yearsToPayOff,
    totalCashRequired,
    remainingCashInvested,
    equityInProperty,
    netCostYield,
    netMarketYield
  }
}

export default calculateSummaryProfit
