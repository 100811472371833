import { Box, Col, Row, Text, Title } from '@qonsoll/react-design'
import { useHistory, useLocation } from 'react-router-dom'

import { PlanCheckoutForm } from 'domains/Plan/components'
import { Spin } from 'antd'
import { useGetProduct } from 'domains/Product/hooks'
import { useMemo } from 'react'
import { usePlan } from 'domains/Plan/context'
import { useTranslations } from 'contexts/Translation'

const PlanCheckout = () => {
  const { t } = useTranslations()
  const location = useLocation()
  const history = useHistory()

  const { planId, priceId, period } = useMemo(() => {
    const query = new URLSearchParams(location.search)
    return {
      planId: query.get('selectedPlan'),
      priceId: query.get('priceId'),
      period: query.get('selectedPeriod')
    }
  }, [location])

  const [selectedPlan, planLoading] = useGetProduct(planId, priceId)

  const planData = usePlan()
  const subscriptionData = useMemo(() => {
    const productType = selectedPlan?.accessibleOptions?.find(
      ({ key }) => key === 'type'
    )
    return productType?.value ? planData[productType] : {}
  }, [planData, selectedPlan?.accessibleOptions])

  const loading = useMemo(
    () => subscriptionData?.subscriptionLoading || planLoading,
    [subscriptionData?.subscriptionLoading, planLoading]
  )

  const onChangePlan = () => history.goBack()

  return (
    <Row h="center" v="center" height="100%">
      <Col cw={[12, 12, 12, 12, 8, 6]}>
        <Box mb={40}>
          <Title textAlign="center" mb={3}>
            {subscriptionData?.subscriptionId
              ? 'You have already subscribed'
              : t('Add card details')}
          </Title>
          <Text display="block" textAlign="center">
            {subscriptionData?.subscriptionId
              ? `${t(
                  'If you want to buy a new plan, then first cancel the old one'
                )}. ${t(
                  'If your plan is not paid then please confirm payment'
                )}. ${t('You can find more options at account page')}.`
              : t('You will not be charged until you make a purchase')}
          </Text>
        </Box>
        {selectedPlan && !loading && (
          <Box>
            <PlanCheckoutForm
              isSubscriptionIncomplete={
                subscriptionData?.isSubscriptionIncomplete
              }
              invoiceUrl={subscriptionData?.subscription?.hostedInvoiceUrl}
              subscriptionId={subscriptionData?.subscriptionId}
              onChangePlan={onChangePlan}
              plan={selectedPlan}
              period={period}
            />
          </Box>
        )}
        {loading && (
          <Text display="flex" justifyContent="center" alignItems="center">
            <Box mr={2}>
              <Spin />
            </Box>
            {t('Loading')}...
          </Text>
        )}
      </Col>
    </Row>
  )
}

export default PlanCheckout
