import {
  createDocument,
  getIsDocumentExists,
  updateDocument,
  updateParent
} from 'services/api/firebase'

import { COLLECTIONS } from '__constants__'
import { message } from 'antd'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'
import { useTranslations } from 'contexts/Translation'
import { useUser } from 'domains/User/context'

const useActionsRentalCalculatorAdvancedForm = (props) => {
  const { rentalCalculatorId, initialData, form } = props || {}

  /* State for loading */
  const [loading, setLoading] = useState(false)
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance */
  const history = useHistory()
  /* Getting history state */
  const historyState = history.location.state
  const { user } = useUser()

  /* Function for preparing values for saving */
  const prepareValues = async (values = {}, additionalValues = {}) => {
    const picturesOfTheProperty =
      values?.['rentalCalculator']?.['picturesOfTheProperty'] ?? null
    const numberOfPictures = picturesOfTheProperty?.length

    const financeRenovation =
      values?.['rentalCalculator']?.['financeRenovation'] ?? null
    if (
      typeof financeRenovation?.amount === 'string' ||
      financeRenovation?.amount instanceof String
    ) {
      financeRenovation.amount = Number(financeRenovation?.amount)
    }
    if (
      typeof financeRenovation?.percent === 'string' ||
      financeRenovation?.percent instanceof String
    ) {
      financeRenovation.percent = Number(financeRenovation?.percent)
    }

    /* Preparing rentalCalculator values */
    const preparedValues = {
      ...additionalValues,
      customerId: user?._id,
      projectName: values?.['rentalCalculator']?.['projectName'] ?? null,
      zillowLink: values?.['rentalCalculator']?.['zillowLink'] ?? null,
      propertyType: values?.['rentalCalculator']?.['propertyType'] ?? null,
      ownership: values?.['rentalCalculator']?.['ownership'] ?? null,
      askingPrice: values?.['rentalCalculator']?.['askingPrice'] ?? null,
      rentalIncome: values?.['rentalCalculator']?.['rentalIncome'] ?? null,
      interestRate: values?.['rentalCalculator']?.['interestRate'] ?? null,
      downPayment: values?.['rentalCalculator']?.['downPayment'] ?? null,
      upfrontRenovation:
        values?.['rentalCalculator']?.['upfrontRenovation'] ?? null,
      financeRenovation:
        (financeRenovation?.amount || 0 === financeRenovation?.amount) &&
        (financeRenovation?.percent || 0 === financeRenovation?.percent)
          ? financeRenovation
          : null,
      loanTerms: values?.['rentalCalculator']?.['loanTerms'] ?? null,
      fixedExpenses: values?.['rentalCalculator']?.['fixedExpenses'] ?? null,
      maintenance: values?.['rentalCalculator']?.['maintenance'] ?? null,
      propertyTax: values?.['rentalCalculator']?.['propertyTax'] ?? null,
      insurance: values?.['rentalCalculator']?.['insurance'] ?? null,
      sewer: values?.['rentalCalculator']?.['sewer'] ?? null,
      management: values?.['rentalCalculator']?.['management'] ?? null,
      vacancy: values?.['rentalCalculator']?.['vacancy'] ?? null,
      communication: values?.['rentalCalculator']?.['communication'] ?? null,
      other: values?.['rentalCalculator']?.['other'] ?? null,
      rentGrowthRate: values?.['rentalCalculator']?.['rentGrowthRate'] ?? null,
      propertyValueGrowthRate:
        values?.['rentalCalculator']?.['propertyValueGrowthRate'] ?? null,
      numberOfBedrooms:
        values?.['rentalCalculator']?.['numberOfBedrooms'] ?? null,
      primaryRoom: values?.['rentalCalculator']?.['primaryRoom'] ?? null,
      rentalUnit: values?.['rentalCalculator']?.['rentalUnit'] ?? null,
      picturesOfTheProperty:
        numberOfPictures > 0 ? picturesOfTheProperty : null,
      notes: values?.['rentalCalculator']?.['notes'] ?? null,
      calculations: values?.['calculations'] ?? null,
      closingTax: values?.['rentalCalculator']?.['closingTax'] ?? null,
      isNetProfitPropertyGrowth:
        values?.['rentalCalculator']?.['isNetProfitPropertyGrowth'] ?? null,
      commonExpenses: values?.['rentalCalculator']?.['commonExpenses'] ?? null,
      electricity: values?.['rentalCalculator']?.['electricity'] ?? null,
      closingCost: values?.['rentalCalculator']?.['closingCost'] ?? null,
      noPrincipal: values?.['rentalCalculator']?.['noPrincipal'] ?? null,
      newPropertyValue:
        values?.['rentalCalculator']?.['newPropertyValue'] ?? null,
      refinance: values?.['rentalCalculator']?.['refinance'] ?? null
    }
    return preparedValues
  }

  /* Saving form data */
  const saveForm = async (values, callback) => {
    try {
      // Prepare data to be saved
      const data = await prepareValues(values)

      if (historyState?.parent)
        await updateParent(historyState.parent, data._id)

      // Save data
      if (initialData) {
        await updateDocument(
          COLLECTIONS.RENTAL_CALCULATORS,
          initialData.rentalCalculator?._id,
          data
        )
        message.success(t('RentalCalculator successfully updated'))
      } else {
        const isExists = await getIsDocumentExists(
          COLLECTIONS.RENTAL_CALCULATORS,
          rentalCalculatorId
        )
        if (isExists) {
          await updateDocument(
            COLLECTIONS.RENTAL_CALCULATORS,
            rentalCalculatorId,
            data
          )
          message.success(t('RentalCalculator successfully updated'))
        } else {
          await createDocument(
            COLLECTIONS.RENTAL_CALCULATORS,
            data,
            rentalCalculatorId
          )
          message.success(t('RentalCalculator successfully created'))
        }
      }
      // Final callback
      callback?.()
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      throw new Error(t('Something went wrong during data save'))
    }
  }

  /* On finish callback */
  const onFinish = async () => {
    if (loading) return // Avoid multiple calls

    try {
      setLoading(true)
      // Get form values
      const formValues = form.getFieldsValue()
      // Save data
      await saveForm(formValues)
    } catch (error) {
      message.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  /* On cancel callback */
  const onReset = () => {
    form.resetFields()
    history.goBack()
  }

  return { onFinish, onReset, loading, saveForm, prepareValues }
}

export default useActionsRentalCalculatorAdvancedForm
