import { Box, Button, Col, Img, Row, Text } from '@qonsoll/react-design'

import { Grid } from 'antd'
import cookie from 'assets/illustrations/cookie.png'
import { useState } from 'react'
import { useTranslations } from 'contexts/Translation'

const wrapperStyles = {
  zIndex: 900,
  position: 'absolute',
  bottom: '16px',
  right: '16px',
  width: '350px',
  p: '40px',
  bg: 'var(--ql-color-white)',
  borderRadius: 'var(--ql-border-radius-20)',
  boxShadow: '0 40px 120px 16px var(--ql-color-dark-t-lighten4)'
}

const GDPR_FLAG = 'gdpr'

const GDPRPopup = () => {
  const { t } = useTranslations()
  const { xs } = Grid.useBreakpoint()
  const isAcceptedGDPR = JSON.parse(window?.localStorage.getItem(GDPR_FLAG))

  const [accepted, setAccepted] = useState(isAcceptedGDPR)

  const handleAccept = () => {
    localStorage.setItem(GDPR_FLAG, 'true')
    setAccepted(true)
  }

  return accepted || xs ? null : (
    <Box {...wrapperStyles}>
      <Row noGutters v="center">
        <Col mb={3}>
          <Row noGutters h="center">
            <Col cw="auto" mb={24}>
              <Img src={cookie} width="64" height="64" alt="cookie" />
            </Col>
            <Col cw={12} mb={24}>
              <Text textAlign="center">
                {t(
                  'gdprMessage',
                  `Application uses cookies to personalize content. By continuing of using this site, you agree with our cookie policy.`
                )}
              </Text>
            </Col>
          </Row>
        </Col>
        <Col cw={12}>
          <Button type="primary" block onClick={handleAccept}>
            {t('Accept')}
          </Button>
        </Col>
      </Row>
    </Box>
  )
}

export default GDPRPopup
