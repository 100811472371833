import { Box, Text } from '@qonsoll/react-design'
import { Checkbox, Form, Space } from 'antd'
import { Info, Slider } from 'components'

import { InputNumber } from 'domains/RentalCalculator/components/RentalCalculatorSimpleForm/components'
import PropTypes from 'prop-types'
import { SuffixMemoized } from 'domains/RentalCalculator/components/RentalCalculatorSimpleForm/components/InputPrice/InputPrice'
import { useCurrency } from 'contexts/Currency'
import { useState } from 'react'
import { useTranslations } from 'contexts/Translation'

const InterestRateForRenovationCostInput = (props) => {
  const { value, onChange, targetField, group, max, form, step, id, ...rest } =
    props
  const [disabled, setDisabled] = useState()

  const { percentSymbol } = useCurrency()
  const { t } = useTranslations()

  const onCheckboxClick = (e) => {
    const checkboxValue = e?.target?.checked
    if (checkboxValue) {
      const targetFieldValue = form.getFieldValue([...group, targetField])
      onChange?.(targetFieldValue)
    }
    setDisabled(checkboxValue)
  }

  return (
    <>
      <Box position="relative" overflow="hidden">
        <InputNumber
          id={id}
          value={value}
          disabled={disabled}
          onChange={onChange}
          addonAfter={
            <Space>
              <Form.Item
                style={{ margin: 0 }}
                name={[...group, 'matchInterestRate']}
                valuePropName="checked">
                <Checkbox onClick={onCheckboxClick} />
              </Form.Item>
              <Text whiteSpace="nowrap">{t('Match interest rate')}</Text>
              <Info title={t('Match interest rate')} />
            </Space>
          }
          {...rest}
        />
        <SuffixMemoized
          value={value}
          suffixSymbol={percentSymbol}
          disabled={disabled}
        />
      </Box>
      <Slider
        disabled={disabled}
        value={value}
        onChange={onChange}
        max={max}
        step={step}
        min={0}
      />
    </>
  )
}

InterestRateForRenovationCostInput.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  group: PropTypes.array,
  targetField: PropTypes.string,
  form: PropTypes.object,
  max: PropTypes.number,
  step: PropTypes.number,
  formGroupName: PropTypes.string
}

export default InterestRateForRenovationCostInput
