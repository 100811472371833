import * as firebaseui from 'firebaseui'

import { Box, Button, Divider, Text } from '@qonsoll/react-design'
import { useGDPRStatus, useSessionActions } from 'domains/Session/hooks'

import { LastSessionUser } from 'domains/Session/components'
import { Link } from 'react-router-dom'
import { MailOutlined } from '@ant-design/icons'
import PATHS from 'pages/paths'
import PropTypes from 'prop-types'
import { StyledFirebaseAuth } from 'react-firebaseui'
import firebase from 'firebase/compat/app'
import { useHistory } from 'react-router-dom'
import { useTranslations } from 'contexts/Translation'

const AuthMethods = (props) => {
  const { t } = useTranslations()
  const {
    authTypeText,
    // googleAuth,
    redirectToFormWithEmail,
    authSwitchText,
    authSwitchLinkText,
    authSwitchPath,
    lastSession
  } = props

  // [ADDITIONAL_HOOKS]
  const history = useHistory()
  const { getLastSessionProvider, saveUserToDB } = useSessionActions()
  const gdpr = useGDPRStatus()

  // [CLEAN_FUNCTIONS]
  const redirectToLoginWithEmail = () => {
    history.push(PATHS.UNAUTHENTICATED.LOGIN_WITH_EMAIL)
  }
  const sessionProvider = getLastSessionProvider()

  const uiConfig = {
    signInOptions: [
      {
        provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        customParameters: { prompt: 'select_account' },
        clientId: process.env.REACT_APP_OAUTH_CLIENT_ID
      }
    ],
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: ({ additionalUserInfo, user: auth }) => {
        const isNew = additionalUserInfo?.isNewUser
        if (!isNew || !auth) return false
        return saveUserToDB({
          gdpr,
          id: auth.uid,
          email: auth.email,
          avatarUrl: auth.photoURL,
          agreement: true,
          additionalData: { displayName: auth.displayName }
        })
      }
    },
    credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO
    // queryParameterForWidgetMode: 'mode'
  }

  return (
    <>
      <StyledFirebaseAuth
        uiCallback={(ui) => ui.disableAutoSignIn()}
        uiConfig={uiConfig}
        firebaseAuth={firebase.auth()}
      />

      <Box>
        <Divider>
          <Text variant="overline" type="secondary">
            {t('Or')}
          </Text>
        </Divider>
      </Box>

      {sessionProvider === 'email' && lastSession?.email ? (
        <LastSessionUser
          lastSession={lastSession}
          onChangeAccount={redirectToLoginWithEmail}
          redirectTo={redirectToFormWithEmail}
        />
      ) : (
        <Button
          size="large"
          block
          icon={<MailOutlined />}
          onClick={redirectToFormWithEmail}>
          {authTypeText} {t('with email')}
        </Button>
      )}
      <Box display="flex" justifyContent="center" mt={24}>
        <Text type="secondary" mr={1}>
          {authSwitchText}
        </Text>
        <Link to={authSwitchPath}>{authSwitchLinkText}</Link>
      </Box>
    </>
  )
}

AuthMethods.propTypes = {
  authSwitchLinkText: PropTypes.any,
  authSwitchPath: PropTypes.any,
  authSwitchText: PropTypes.any,
  authTypeText: PropTypes.any,
  lastSession: PropTypes.any,
  redirectToFormWithEmail: PropTypes.any
}

export default AuthMethods
