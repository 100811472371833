import { model, attr } from 'services/model'
import * as yup from 'yup'

const validationSchema = yup.object().shape({
  avatarUrl: yup
    .string()
    .typeError('Field avatarUrl has wrong type')
    .default(null)
    .nullable(),
  firstName: yup
    .string()
    .typeError('Field firstName has wrong type')
    .required('Field firstName is required'),
  lastName: yup
    .string()
    .typeError('Field lastName has wrong type')
    .required('Field lastName is required'),
  email: yup
    .string()
    .typeError('Field email has wrong type')
    .required('Field email is required'),
  phone: yup
    .string()
    .typeError('Field phone has wrong type')
    .default(null)
    .nullable()
})

const User = model(
  'user',
  {
    avatarUrl: attr('string'),
    firstName: attr('string'),
    lastName: attr('string'),
    email: attr('string'),
    phone: attr('string')
  },
  validationSchema
)

export default User
