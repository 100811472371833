import { Box, Button } from '@qonsoll/react-design'
import { CancelSubscription, ChangePlan, RenewSubscription } from './components'
import { Link, useHistory } from 'react-router-dom'

import PATHS from 'pages/paths'
import { PRODUCT_TYPES } from '__constants__/enums'
import PropTypes from 'prop-types'
import { Skeleton } from 'antd'
import SubscriptionView from '../SubscriptionView'
import { useGetProductByPrice } from 'domains/Product/hooks'
import { useGetUserTickets } from 'domains/User/hooks'
import { usePlan } from 'domains/Plan/context'
import { useTranslations } from 'contexts/Translation'

const UserPlan = (props) => {
  const { productType } = props
  const history = useHistory()
  const { t } = useTranslations()

  const planData = usePlan()
  const {
    subscription,
    subscriptionLoading,
    isSubscriptionIncomplete,
    isSubscriptionCancelled,
    subscriptionEndDate,
    subscriptionId
  } = planData[productType]

  const [plan, planLoading] = useGetProductByPrice(subscription?.priceId)
  const { isUnlimited, tickets } = useGetUserTickets(productType)

  const isTicketPlan =
    plan?.accessibleOptions.find(({ key }) => key === 'type') ===
    PRODUCT_TYPES.RENTAL

  return (
    <>
      {subscriptionLoading || planLoading ? (
        <Skeleton active paragraph={{ rows: 8 }} />
      ) : (
        <SubscriptionView
          isSubscriptionCancelled={isSubscriptionCancelled}
          subscriptionEndDate={subscriptionEndDate}
          subscriptionStatus={subscription?.status}
          isUnlimitedTickets={isUnlimited}
          ticketsLeft={tickets}
          plan={plan}
        />
      )}
      <Box mt={4}>
        {isSubscriptionIncomplete && (
          <Box mb={2}>
            <Link
              to={{ pathname: subscription?.hostedInvoiceUrl }}
              target="_blank"
              type="primary">
              <Button block type="primary">
                {t('Complete payment')}
              </Button>
            </Link>
          </Box>
        )}
        {!isSubscriptionCancelled && (
          <Box mb={2}>
            <ChangePlan currentPlan={plan} />
          </Box>
        )}
        {!isSubscriptionCancelled &&
          !isSubscriptionIncomplete &&
          !isUnlimited &&
          isTicketPlan && (
            <Button
              onClick={() =>
                history.push(PATHS.AUTHENTICATED.PRODUCT_SELECTION)
              }
              mb={2}
              block>
              {t('Buy more tickets')}
            </Button>
          )}
        {isSubscriptionCancelled && subscriptionId && (
          <Box mb={2}>
            <RenewSubscription subscriptionId={subscriptionId} />
          </Box>
        )}
        {subscriptionId && (
          <Box mb={2} width="100%">
            <CancelSubscription
              isCompletely={isSubscriptionCancelled}
              subscriptionId={subscriptionId}
            />
          </Box>
        )}
      </Box>
    </>
  )
}

UserPlan.propTypes = {
  subscriptionId: PropTypes.string,
  productType: PropTypes.string
}

export default UserPlan
