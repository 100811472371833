import { Col, Row } from '@qonsoll/react-design'

import styled from 'styled-components'

export const SelectableItem = styled(Col)`
  white-space: nowrap;
  font-weight: 500;
  cursor: pointer;
  padding: 8px;
  border-radius: var(--input-border-radius-base);
  background: transparent;
  color: ${(props) =>
    props.selected
      ? props.disabled
        ? 'var(--ql-color-accent1-t-lighten2)'
        : 'var(--ql-color-accent1)'
      : props.disabled
      ? 'var(--input-disabled-color)'
      : 'var(--ql-typography-text-color-primary)'};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  ${(props) =>
    props.selected &&
    `
      background: #fff !important;
      ${!props.isPrintMode && 'box-shadow: 0px 4px 16px 0px #0B13282E;'}
    `}

  font-size: 16px;
`
export const SelectableWrapper = styled(Row)`
  font-size: 16px;
  padding: 4px;
  border-radius: var(--input-border-radius-base);
  background-color: ${(props) =>
    props.disabled
      ? 'var(--input-disabled-bg) !important'
      : props.isPrintMode
      ? 'var(--ql-color-neutral1-t-lighten6)'
      : 'var(--ql-color-neutral1-t-lighten7)'};
  overflow: hidden;
`
