import { LAST_SESSION_PROVIDERS, LS } from '__constants__'

import firebase from 'firebase/compat/app'
import saveUserToDB from './saveUserToDB'

const createUserWithEmailAndPassword = (credentials, onError) => {
  return firebase
    .auth()
    .createUserWithEmailAndPassword(credentials.email, credentials.password)
    .then((userCredential) => {
      const user = userCredential.user
      const { firstName, lastName, email } = credentials
      const { uid, photoURL } = user

      saveUserToDB({
        additionalData: { firstName, lastName },
        avatarUrl: photoURL,
        id: uid,
        email
      })

      localStorage.setItem(
        LS.LAST_SESSION_PROVIDER,
        LAST_SESSION_PROVIDERS.EMAIL
      )
    })
    .catch((err) => {
      onError && onError(err)
    })
}

export default createUserWithEmailAndPassword
