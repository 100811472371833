import { Button, Box, Title } from '@qonsoll/react-design'
import {
  FlippingCalculatorResultsExpensesSection,
  FlippingCalculatorResultsMortgage,
  FlippingCalculatorResultsTopSection
} from './components'

import PropTypes from 'prop-types'
import { useCurrency } from 'contexts/Currency'
import { useTranslations } from 'contexts/Translation'

const FlippingCalculatorResultsSection = (props) => {
  const { isAccessGranted, value, isPrintMode } = props

  const { t } = useTranslations()

  const { symbol } = useCurrency()

  const isLocked = !isAccessGranted

  const {
    mortgage = [],
    results = {},
    expenses = {},
    propertyValues = []
  } = value || {}

  const onRedirectButtonClick = () =>
    window.open(process.env.REACT_APP_FLIPPELAN_LINK)

  const buttonDisplayStyle = isPrintMode ? 'none' : 'block'

  return (
    <Box width="inherit">
      <Title level={2} textAlign="center" mb={40}>
        {t('Results')}
      </Title>
      <Box mb={40}>
        <FlippingCalculatorResultsTopSection
          measurementSymbol={symbol}
          value={results}
          isLocked={isLocked}
        />
      </Box>
      <Box mb={40}>
        <Button
          block
          style={{ display: buttonDisplayStyle }}
          type="primary"
          disabled={isLocked}
          onClick={onRedirectButtonClick}>
          {t('Søk om Flippelån')}
        </Button>
      </Box>
      <Box mb={40}>
        <FlippingCalculatorResultsExpensesSection
          isPrintMode={isPrintMode}
          measurementSymbol={symbol}
          value={expenses}
          isLocked={isLocked}
        />
      </Box>
      <Box>
        <FlippingCalculatorResultsMortgage
          isPrintMode={isPrintMode}
          measurementSymbol={symbol}
          value={value}
          mortgages={mortgage}
          propertyValues={propertyValues}
          isLocked={isLocked}
          years={value?.years}
        />
      </Box>
    </Box>
  )
}

FlippingCalculatorResultsSection.propTypes = {
  isAccessGranted: PropTypes.bool,
  group: PropTypes.array,
  value: PropTypes.object,
  isPrintMode: PropTypes.bool
}

export default FlippingCalculatorResultsSection
