/* eslint-disable react/prop-types */
import { Avatar, Button } from '@qonsoll/react-design'
import { memo, useEffect, useState } from 'react'

import { Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { getBase64 } from '../ImageUploader/helpers'
import { message } from 'antd'
import { uploadImage } from 'helpers'
import { useTranslations } from 'contexts/Translation'

const AVAILABLE_FORMATS = [
  'image/jpeg',
  'image/png',
  'image/jpg',
  'image/tiff',
  'image/svg'
]

const ImageUploader = (props) => {
  const {
    value,
    onChange,
    withDirectUpload = false,
    uploadProps,
    disabled,
    size = 128
  } = props

  const [file, setFile] = useState(null)

  const { t } = useTranslations()

  const handleUpload = async ({ onSuccess, file }) => {
    if (withDirectUpload) {
      const [url] = await uploadImage(file)
      onChange(url)
    } else {
      const onTransformSuccess = (result) => {
        setFile(result)
        onChange?.(result)
      }
      const onTransformError = () => {
        onTransformSuccess(null)
        message.error(t('Error during image upload, please try again'))
      }
      getBase64(file, onTransformSuccess, onTransformError)
    }
    onSuccess()
  }

  // [LIFECYCLE]
  useEffect(() => value && setFile(value), [value])

  return (
    <Upload
      disabled={disabled}
      fileList={null}
      customRequest={handleUpload}
      accept={AVAILABLE_FORMATS.join()}
      {...uploadProps}>
      {file ? (
        <Avatar
          style={{ cursor: 'pointer' }}
          alt="Image"
          src={file || value}
          size={size}
        />
      ) : (
        <Button disabled={disabled} icon={<UploadOutlined />}>
          {t('Please upload image')}
        </Button>
      )}
    </Upload>
  )
}

ImageUploader.propTypes = {}

export default memo(ImageUploader)
