const LOAN_TERMS = {
  FIFE_YEARS: 'FIFE_YEARS',
  TEN_YEARS: 'TEN_YEARS',
  FIFTEEN_YEARS: 'FIFTEEN_YEARS',
  TWENTY_YEARS: 'TWENTY_YEARS',
  TWENTY_FIFE_YEARS: 'TWENTY_FIFE_YEARS',
  THIRTY_YEARS: 'THIRTY_YEARS'
}

const LOAN_TERMS_LABELS = {
  FIFE_YEARS: '5 years',
  TEN_YEARS: '10 years',
  FIFTEEN_YEARS: '15 years',
  TWENTY_YEARS: '20 years',
  TWENTY_FIFE_YEARS: '25 years',
  THIRTY_YEARS: '30 years'
}

const LOAN_TERMS_YEARS = {
  FIFE_YEARS: 5,
  TEN_YEARS: 10,
  FIFTEEN_YEARS: 15,
  TWENTY_YEARS: 20,
  TWENTY_FIFE_YEARS: 25,
  THIRTY_YEARS: 30
}

const LOAN_TERMS_OPTIONS = Object.keys(LOAN_TERMS_LABELS).map((key) => ({
  label: LOAN_TERMS_LABELS[key],
  value: key
}))

export default LOAN_TERMS
export { LOAN_TERMS_OPTIONS, LOAN_TERMS, LOAN_TERMS_YEARS, LOAN_TERMS_LABELS }
