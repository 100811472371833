import { Box, Col, Row, Text } from '@qonsoll/react-design'
import { cloneElement, useMemo } from 'react'

import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { useMenuItems } from '../../hooks'

const MobileMenu = (props) => {
  const { height } = props

  const menuItems = useMenuItems()
  const location = useLocation()

  const selected = useMemo(() => {
    const currentParentPath = location.pathname.split('/')?.[1]
    return ['/' + currentParentPath]
  }, [location])

  return (
    <Row noGutters height={height}>
      {menuItems?.map((item) => (
        <Col
          key={item.key}
          onClick={item.onClick}
          style={{ cursor: 'pointer' }}
          h="center"
          v="center"
          height={height}>
          <Box display="flex" flexDirection="column" alignItems="center">
            {cloneElement(item.icon, {
              fill: selected.includes(item.key)
                ? 'var(--ql-menu-item-active-color)'
                : 'var(--ql-menu-item-color)',
              size: '20px'
            })}
            <Text
              color={
                selected.includes(item.key)
                  ? 'var(--ql-menu-item-active-color)'
                  : 'var(--ql-menu-item-color)'
              }>
              {item.mobileText}
            </Text>
          </Box>
        </Col>
      ))}
    </Row>
  )
}

MobileMenu.propTypes = {
  height: PropTypes.string
}

export default MobileMenu
