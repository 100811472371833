import { COLLECTIONS, SUBSCRIPTION_STATUSES } from '__constants__'
/* eslint-disable no-console */
import { useEffect, useMemo, useState } from 'react'

import PATHS from 'pages/paths'
import { notification } from 'antd'
import { useHistory } from 'react-router-dom'
import { useListenDocument } from 'services/api/firebase'
import { useTranslations } from 'contexts/Translation'

const useGetUserSubscription = (subscriptionId) => {
  const [isSubscribeProcessing, setIsProcessing] = useState(false)
  const [shouldCheck, setShouldCheck] = useState()

  const { t } = useTranslations()
  const history = useHistory()

  const ref = useMemo(
    () =>
      subscriptionId
        ? { ref: COLLECTIONS.SUBSCRIPTIONS + '/' + subscriptionId }
        : { ref: null },
    [subscriptionId]
  )

  const [subscription, loading] = useListenDocument(ref)

  const subscriptionComputed = useMemo(
    () => (subscriptionId ? subscription : null),
    [subscriptionId, subscription]
  )

  const isSubscriptionPaid = useMemo(
    () => subscriptionComputed && subscriptionComputed?.paid,
    [subscriptionComputed]
  )
  const isSubscriptionActive = useMemo(
    () => subscriptionComputed?.status === SUBSCRIPTION_STATUSES.ACTIVE,
    [subscriptionComputed]
  )

  useEffect(() => {
    if (shouldCheck === undefined && subscriptionComputed) {
      const _shouldCheck = !isSubscriptionPaid
      setIsProcessing(_shouldCheck)
      setShouldCheck(_shouldCheck)
    }
  }, [shouldCheck, isSubscriptionPaid, subscriptionComputed])

  useEffect(() => {
    if (shouldCheck) {
      if (isSubscriptionPaid && isSubscriptionActive) {
        setIsProcessing(false)
        setShouldCheck(false)
        notification.success({
          message: t('Subscription confirmed'),
          description: t('Your subscription is now active'),
          duration: 120
        })
        history.push(PATHS.CONFIG.AFTER_PAYMENT)
      }
    }
  }, [isSubscriptionActive, t, history, shouldCheck, isSubscriptionPaid])

  return [subscriptionComputed, loading, isSubscribeProcessing]
}

export default useGetUserSubscription
