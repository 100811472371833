import _ from 'lodash'

const CURRENCY_SYMBOLS = {
  usd: '$',
  eur: '€',
  gbp: '£',
  nok: 'kr'
}

const transformProduct = (product = {}) => {
  const accessibleOptions = Object.keys(product?.metadata || {})
    .filter((key) => product.metadata[key] !== 'false')
    .map((key) => ({ key: key, value: product.metadata[key] }))

  const notAccessibleOptions = Object.keys(product?.metadata || {})
    .filter((key) => product.metadata[key] === 'false')
    .map((key) => ({ key: key, value: product.metadata[key] }))

  const prices = _.reverse(
    product?.prices?.map((priceObject) => ({
      period: priceObject?.recurring?.interval,
      price: priceObject?.amount,
      priceId: priceObject?.priceId,
      currency: priceObject?.currency,
      currencySymbol: CURRENCY_SYMBOLS[priceObject.currency]
    }))
  )

  return {
    _id: product.productId,
    title: product.name,
    currency: product.currency,
    currencySymbol: CURRENCY_SYMBOLS[product.currency],
    description: product.description,
    price: product.amount,
    period: product.recurring.interval,
    notAccessibleOptions,
    accessibleOptions,
    priceId: product.priceId,
    metadata: product.metadata,
    prices,
    isProduct: !Object.keys(product.recurring).length
  }
}

export default transformProduct
