import { Box, Divider, Menu, MenuItem } from '@qonsoll/react-design'
import { Fragment, cloneElement, useMemo } from 'react'

import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { useMenuItems } from '../../hooks'

const MainMenu = (props) => {
  const { collapse } = props

  const location = useLocation()

  // [COMPUTED PROPERTIES]
  const selected = useMemo(() => {
    const currentParentPath = location.pathname.split('/')?.[1]
    return ['/' + currentParentPath]
  }, [location])

  const menuItems = useMenuItems()

  return (
    <Fragment>
      <Menu inlineCollapsed={collapse} mode="inline" selectedKeys={selected}>
        {menuItems.map((item) => (
          <Fragment key={item.key}>
            <MenuItem
              key={item.key}
              onClick={item.onClick}
              icon={cloneElement(item.icon, {
                fill: selected.includes(item.key)
                  ? 'var(--ql-menu-item-active-color)'
                  : 'var(--ql-menu-item-color)',
                size: '16px'
              })}>
              {item.text}
            </MenuItem>
            {item.divided && (
              <Box my={3}>
                <Divider />
              </Box>
            )}
          </Fragment>
        ))}
      </Menu>
    </Fragment>
  )
}

MainMenu.propTypes = {
  collapse: PropTypes.bool
}

export default MainMenu
