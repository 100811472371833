import PropTypes from 'prop-types'
import { SummaryItem } from 'domains/RentalCalculator/components/RentalCalculatorSummary/components'
import { useTranslations } from 'contexts/Translation'

const FlippingCalculatorResultsTopSection = (props) => {
  const { measurementSymbol, value = {}, isLocked } = props

  const { t } = useTranslations()

  return (
    <>
      <SummaryItem
        measurementSymbol={measurementSymbol}
        value={value.grossProfitBestCase}
        label={t('Gross profit best case')}
        info={t('grossProfitBestCaseInfo')}
        locked={isLocked}
      />
      <SummaryItem
        measurementSymbol={measurementSymbol}
        value={value.grossProfitWorstCase}
        label={t('Gross profit worst case')}
        info={t('grossProfitWorstCaseInfo')}
        locked={isLocked}
      />
      <SummaryItem
        value={value.roiBestCase}
        label={t('ROI best case')}
        info={t('roiBestCaseInfo')}
        negativeToZero
        locked={isLocked}
      />
      <SummaryItem
        value={value.roiWorstCase}
        label={t('ROI worst case')}
        info={t('roiWorstCaseInfo')}
        negativeToZero
        locked={isLocked}
      />
      <SummaryItem
        measurementSymbol={measurementSymbol}
        value={value.grossProfitPerMonthBestCase}
        label={t('Gross profit per month best case')}
        info={t('grossProfitPerMonthBestCaseInfo')}
        locked={isLocked}
      />
      <SummaryItem
        measurementSymbol={measurementSymbol}
        value={value.grossProfitPerMonthWorstCase}
        label={t('Gross profit per month worst case')}
        info={t('grossProfitPerMonthWorstCaseInfo')}
        locked={isLocked}
      />
      <SummaryItem
        measurementSymbol={measurementSymbol}
        value={value.totalCashRequired}
        label={t('Total cash required')}
        info={t('totalCashRequiredInfo')}
        locked={isLocked}
      />
    </>
  )
}

FlippingCalculatorResultsTopSection.propTypes = {
  value: PropTypes.object,
  measurementSymbol: PropTypes.string,
  isLocked: PropTypes.bool
}

export default FlippingCalculatorResultsTopSection
