import 'firebase/compat/firestore'

import { useCallback, useEffect, useMemo } from 'react'
import {
  useGDPRStatus,
  useInitializeUser,
  useSessionActions
} from 'domains/Session/hooks'

import PATHS from 'pages/paths'
import PropTypes from 'prop-types'
import UserContext from './UserContext'
import firebase from 'firebase/compat/app'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useHandleError } from 'hooks'
import { useHistory } from 'react-router-dom'

const UserProvider = ({ children }) => {
  /* The above code is a function that takes in a callback function as an argument.
  The callback function is then called with the error as an argument. */
  const handleError = useHandleError()

  /* Using the useGDPRStatus hook to get the GDPR status of the user. */
  const gdpr = useGDPRStatus()

  const history = useHistory()

  /* Using the useAuthState hook to get the user from the firebase auth state. */
  const [auth] = useAuthState(firebase.auth())

  /* Using the useInitializeUser hook to get the user from the firebase auth state. */
  const [user, isAuthenticated, loading, error] = useInitializeUser()

  const unlockProAccess = useCallback(() => {
    history.push(
      isAuthenticated
        ? PATHS.AUTHENTICATED.PLAN_SELECTION
        : PATHS.UNAUTHENTICATED.SIGNUP
    )
  }, [isAuthenticated, history])

  // Session methods
  const {
    updateEmailVerificationStatus,
    getLastSessionFromLocalStorage,
    setSessionToLocalStorage,
    updateGDPRStatus
  } = useSessionActions()

  // Updating user's email verification status
  useEffect(() => {
    // Check if data is loaded
    const isUserDataLoaded = auth && user && !loading

    // Updating email verification status
    isUserDataLoaded &&
      !user.emailVerified &&
      updateEmailVerificationStatus({
        id: auth.uid,
        sessionUserEmailVerified: auth?.emailVerified,
        dbUserEmailVerified: user?.emailVerified,
        onError: handleError
      })
  }, [auth, updateEmailVerificationStatus, user, loading, handleError])

  // Updating LS with user session info
  useEffect(() => {
    const isUserDataLoaded = auth && user && !loading
    if (isUserDataLoaded) {
      const lastSession = getLastSessionFromLocalStorage()
      lastSession?.email !== auth?.email &&
        /* Set the session to the user's local storage. */
        setSessionToLocalStorage({
          email: auth.email,
          avatarUrl: auth.photoURL,
          provider: localStorage.getItem('lastSessionProvider')
        })
    }
  }, [
    auth,
    user,
    loading,
    getLastSessionFromLocalStorage,
    setSessionToLocalStorage
  ])

  // Update GDPR status
  useEffect(() => {
    /* This is a ternary operator. It is checking if the user, value, and loading variables are all
    true. If they are, it will return true. Otherwise, it will return false. */
    const isUserDataLoaded = auth && user && !loading

    /* If the user is logged in, and the user's data is loaded, and the user's data has not yet been
    updated to reflect the new GDPR status, then update the user's data to reflect the new GDPR
    status. */
    if (isUserDataLoaded && !user?.gdpr && gdpr !== user?.gdpr)
      updateGDPRStatus({ id: auth?.uid, gdpr, onError: handleError })
  }, [gdpr, auth, user, updateGDPRStatus, handleError, loading])

  // Handling user fetching error
  useEffect(() => {
    error && handleError(error)
  }, [error, handleError])

  // Preparing context
  const contextData = useMemo(() => {
    const isUserLoaded = user && !loading
    const isProAccess = user?.isProAccess || user?.isUnlimitedUser
    const isFlippingProAccess =
      user?.isFlippingProAccess || user?.isUnlimitedUser
    const isTrialUsed = user?.isTrialUsed
    const isNewCustomer = isUserLoaded && isAuthenticated && !isProAccess
    const isNewFlippingCustomer =
      isUserLoaded && isAuthenticated && !isFlippingProAccess
    const isPackageProAccess =
      (user?.isProAccess && user?.isFlippingProAccess) || user?.isUnlimitedUser

    return {
      user: user,
      loading,
      loaded: isUserLoaded,
      isAuthenticated: isAuthenticated && isUserLoaded,
      unlockProAccess,
      isProAccess,
      isTrialUsed,
      isNewCustomer,
      isFlippingProAccess,
      isNewFlippingCustomer,
      isPackageProAccess
    }
  }, [loading, user, isAuthenticated, unlockProAccess])

  return (
    <UserContext.Provider value={contextData}>{children}</UserContext.Provider>
  )
}

UserProvider.propTypes = { children: PropTypes.node }

export default UserProvider
