const ROLES = {
  CUSTOMER: 'CUSTOMER',
  ADMIN: 'ADMIN'
}

const ROLES_OPTIONS = Object.keys(ROLES).map((key) => ({
  label: ROLES[key],
  value: key
}))

export default ROLES
export { ROLES_OPTIONS, ROLES }
