import { SUBSCRIPTION_STATUSES } from '__constants__'
import moment from 'moment'
import { useGetUserSubscription } from 'domains/User/hooks'
import { useMemo } from 'react'

const useListenSubscriptionWithAdditionalData = (
  listenedSubscriptionId,
  isTrialUsed
) => {
  const [subscription, subscriptionLoading, isSubscribeProcessing] =
    useGetUserSubscription(listenedSubscriptionId)

  const isSubscriptionCancelled = useMemo(
    () => subscription?.cancelAtPeriodEnd,
    [subscription]
  )

  const isSubscriptionIncomplete = useMemo(
    () => subscription?.status === SUBSCRIPTION_STATUSES.INCOMPLETE,
    [subscription]
  )

  const subscriptionEndDate = useMemo(
    () =>
      subscription?.currentPeriodEnd
        ? moment
            .unix(subscription?.currentPeriodEnd.seconds)
            .format('DD MMM YYYY')
        : null,
    [subscription]
  )
  const subscriptionId = useMemo(() => subscription?._id, [subscription])
  return {
    subscription,
    isSubscribeProcessing,
    subscriptionLoading,
    isSubscriptionCancelled,
    subscriptionEndDate,
    isSubscriptionIncomplete,
    subscriptionId,
    isTrialUsed
  }
}

export default useListenSubscriptionWithAdditionalData
