import { Checkbox as AntCheckbox } from 'antd'
import styled from 'styled-components'

const Checkbox = styled(AntCheckbox)`
  .ant-checkbox:hover::after {
    border-radius: 6px;
  }
  .ant-checkbox-checked {
    border-radius: 6px;
    width: 25px;
    height: 25px;
  }
  .ant-checkbox-inner {
    border-radius: 6px;
    width: 25px;
    height: 25px;
  }
  .ant-checkbox-inner::after {
    width: 9.714286px;
    height: 20.142857px;
  }
`
export default Checkbox
