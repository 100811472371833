import { Box, Title } from '@qonsoll/react-design'
import { useMemo, useState } from 'react'

import PropTypes from 'prop-types'
import Slidebar from '../Slidebar'
import SummaryItem from '../SummaryItem'
import { useTranslations } from 'contexts/Translation'

const MonthlyNetProfitSummary = (props) => {
  const { measurementSymbol, monthlyProfits, years, isLocked, isPrintMode } =
    props

  const { t } = useTranslations()

  const [selectedIndex, setSelectedIndex] = useState(0)

  const selected = useMemo(() => {
    return monthlyProfits?.[selectedIndex] || {}
    // return options[selectedIndex]
  }, [monthlyProfits, selectedIndex])

  const slidebarText = useMemo(() => {
    const label = selectedIndex + 1
    return `${label}. ${t('year')}`
  }, [t, selectedIndex])

  const handleNext = () => {
    setSelectedIndex((prev) => {
      if (prev < years - 1) return prev + 1
      return prev
    })
  }
  const handlePrev = () => {
    setSelectedIndex((prev) => {
      if (prev > 0) return prev - 1
      return prev
    })
  }

  return (
    <>
      <Box
        mb={4}
        display="flex"
        alignItems="center"
        justifyContent="space-between">
        <Title level={3}>{t('Monthly')}</Title>
        <Slidebar
          isPrintMode={isPrintMode}
          text={slidebarText}
          onLeft={handlePrev}
          onRight={handleNext}
        />
      </Box>
      <SummaryItem
        measurementSymbol={measurementSymbol}
        value={selected.grossRent}
        info={t('grossRentInfo')}
        label={t('Gross rent')}
        locked={isLocked}
        colorized
      />
      <SummaryItem
        measurementSymbol={measurementSymbol}
        value={-selected.operatingExpenses}
        info={t('operatingExpensesInfo')}
        label={t('Operating expenses')}
        locked={isLocked}
        colorized
      />
      <SummaryItem
        measurementSymbol={measurementSymbol}
        value={-selected.interest}
        info={t('interestInfo')}
        label={t('Interest')}
        locked={isLocked}
        colorized
      />
      <SummaryItem
        measurementSymbol={measurementSymbol}
        value={selected.grossCashflow}
        info={t('grossCashflowInfo')}
        label={t('Gross cashflow')}
        locked={isLocked}
        colorized
      />
      <SummaryItem
        measurementSymbol={measurementSymbol}
        value={-selected.tax}
        info={t('taxInfo')}
        label={t('Tax')}
        locked={isLocked}
        colorized
      />
      <SummaryItem
        measurementSymbol={measurementSymbol}
        value={selected.installment}
        info={t('installmentInfo')}
        label={t('Installment')}
        locked={isLocked}
      />
      <SummaryItem
        measurementSymbol={measurementSymbol}
        value={selected.netCashflow}
        info={t('netCashflowInfo')}
        label={t('Net cashflow')}
        locked={isLocked}
      />
    </>
  )
}

MonthlyNetProfitSummary.propTypes = {
  measurementSymbol: PropTypes.string,
  monthlyProfits: PropTypes.any,
  options: PropTypes.array,
  years: PropTypes.number,
  isLocked: PropTypes.bool,
  isPrintMode: PropTypes.bool
}

export default MonthlyNetProfitSummary
