// Recursion
const calculateLoan = (values) => {
  let {
    debt = 0,
    monthlyPayment = 0,
    monthlyRate = 0,
    month = 0,
    annualInterest = 0,
    noPrincipal = false
  } = values

  /* beginning balance * rate */
  const monthlyInterest = noPrincipal ? monthlyPayment : debt * monthlyRate
  /* payment - interest */
  const monthlyInstallment = monthlyPayment - monthlyInterest

  if (month === 0) {
    return {
      debt: debt,
      monthlyPayment,
      monthlyRate,
      monthlyInterest,
      monthlyInstallment,
      annualInterest
    }
  }

  /* beginning balance - installment */
  debt -= monthlyInstallment

  if (month < 12) {
    // Annual interest
    annualInterest += monthlyInterest
  }

  return calculateLoan({
    debt,
    monthlyPayment,
    monthlyRate,
    month: month - 1,
    annualInterest,
    noPrincipal
  })
}

export default calculateLoan
