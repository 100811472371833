import {
  calculateEquity,
  calculateLoan,
  calculateMonthlyRent,
  calculatePropertyValueGrowth,
  calculateYearlyProfit
} from '../calculateByPeriod'

import { LOAN_TERMS_YEARS } from '__constants__/enums'
import calculateMortgageBase from './calculateMortgageBase'
import calculateOperatingExpenses from './calculateOperatingExpenses'
import calculateSummaryProfit from './calculateSummaryProfit'

const calculateExpenses = (values, isProAccess) => {
  const years = LOAN_TERMS_YEARS[values?.loanTerms]
  const rentalIncome = Number(values?.rentalIncome) || 0
  const rentGrowthRate = Number(values?.rentGrowthRate) || 0
  const propertyValueGrowthRate = Number(values?.propertyValueGrowthRate) || 0
  const rentalUnit = values?.rentalUnit
  const vacancy = Number(values?.vacancy) || 0
  const isNetProfitPropertyGrowth = values?.isNetProfitPropertyGrowth
  const noPrincipal = values?.noPrincipal
  const upfrontRenovation = values?.upfrontRenovation

  const operatingExpenses = calculateOperatingExpenses(values, isProAccess)

  const mortgageBase = calculateMortgageBase({ years, ...values })

  const yearlyMortgages = Array(years)
    .fill(null)
    .map((_, index) =>
      calculateLoan({
        debt: mortgageBase?.debt,
        monthlyPayment: mortgageBase?.monthlyPayment,
        monthlyRate: mortgageBase?.monthlyRate,
        month: index * 12,
        noPrincipal
      })
    )

  const mortgageAtYearEnd = Array(years)
    .fill(null)
    .map((_, index) =>
      calculateLoan({
        debt: mortgageBase?.debt,
        monthlyPayment: mortgageBase?.monthlyPayment,
        monthlyRate: mortgageBase?.monthlyRate,
        month: (index + 1) * 12,
        noPrincipal
      })
    )

  const propertyValues = Array(years)
    .fill(null)
    .map((_, index) =>
      calculatePropertyValueGrowth({
        propertyValue: mortgageBase?.propertyValue,
        propertyValueGrowthRate,
        year: index
      })
    )
  const propertyValuesIncreased = propertyValues.map(
    ({ propertyValueIncreased }) => propertyValueIncreased
  )

  const monthlyProfits = Array(years)
    .fill(null)
    .reduce(
      (acc, _, index) => [
        ...acc,
        calculateMonthlyRent({
          year: index,
          monthlyPayment: mortgageBase.monthlyPayment,
          operatingExpenses,
          rentalIncome,
          rentGrowthRate,
          interest: yearlyMortgages[index].monthlyInterest,
          installment: yearlyMortgages[index].monthlyInstallment,
          rentalUnit: rentalUnit,
          propertyValueIncrease: propertyValues[index].propertyValueIncrease,
          isNetProfitPropertyGrowth: isNetProfitPropertyGrowth,
          upfrontRenovation,
          yearlyInterest: mortgageAtYearEnd[index].annualInterest,
          previousMonthlyIncomes: Array.from(new Array(index).keys()).map(
            (item) => acc[item].monthlyIncome
          ),
          previousMonthlyExpenses: Array.from(new Array(index).keys()).map(
            (item) => acc[item].monthlyExpenses
          ),
          previousYearlyInterests: Array.from(new Array(index).keys()).map(
            (item) => mortgageAtYearEnd[item].annualInterest
          ),
          previousPropertyValueIncreases: Array.from(
            new Array(index).keys()
          ).map((item) => propertyValues[item].propertyValueIncrease),
          vacancy,
          index
        })
      ],
      []
    )

  const yearlyProfits = Array(years)
    .fill(null)
    .reduce(
      (acc, _, index) => [
        ...acc,
        calculateYearlyProfit({
          propertyValueIncrease: propertyValues[index].propertyValueIncrease,
          isNetProfitPropertyGrowth: isNetProfitPropertyGrowth,
          interest: mortgageAtYearEnd[index].annualInterest,
          monthlyIncome: monthlyProfits[index].monthlyIncome,
          monthlyExpenses: monthlyProfits[index].monthlyExpenses,
          upfrontRenovation,
          currentIndex: index,
          rentalUnit,
          netProfit: acc?.reduce(
            (netProfit, { yearlyNetProfit }) => netProfit + yearlyNetProfit,
            0
          )
        })
      ],
      []
    )

  const netProfits = yearlyProfits.map((profit) => profit.yearlyNetProfit)
  const netProfitsWithoutIncrease = yearlyProfits.map(
    (profit) => profit.yearlyNetProfitWithoutIncrease
  )

  const equities = netProfitsWithoutIncrease.map((_, index) => {
    const profitsSum = netProfitsWithoutIncrease
      .slice(0, index)
      .reduce((a, b) => a + b, 0)

    return calculateEquity({
      propertyValueIncrease: propertyValuesIncreased[index],
      profitsSum: profitsSum,
      debt: mortgageBase.debt
    })
  })

  const summaryProfit = calculateSummaryProfit({
    ...values,
    yearlyProfitBeforeTax: yearlyProfits?.[0].yearlyProfitBeforeTax,
    yearlyNetProfit: yearlyProfits?.[0].yearlyNetProfit,
    propertyValue: mortgageBase.propertyValue,
    debt: mortgageBase.debt,
    operatingExpenses,
    equities
  })

  const { debtToValueRatio, debt } = mortgageBase
  const {
    annualROI,
    grossYield,
    netYield,
    yearsToPayOff,
    totalCashRequired,
    remainingCashInvested,
    equityInProperty,
    netCostYield,
    netMarketYield
  } = summaryProfit

  const calculations = {
    monthlyCashflow: monthlyProfits[0].monthlyCashflow,
    totalCashRequired,
    debt,
    debtToValueRatio: debtToValueRatio,
    yearlyNetProfit: yearlyProfits?.[0].yearlyNetProfit,
    yearsToPayOff,
    annualROI,
    grossYield,
    netYield,
    netCostYield,
    netMarketYield,
    propertyValue: mortgageBase.propertyValue,
    remainingCashInvested,
    equityInProperty,
    // Additional
    refinanceMax: mortgageBase.refinanceMax,
    years,
    // Sections
    monthlyProfit: monthlyProfits[0],
    mortgages: yearlyMortgages,
    mortgage: mortgageBase,
    netProfits,
    equities,
    propertyValues: propertyValuesIncreased,
    monthlyProfits
  }

  return calculations
}

export default calculateExpenses
