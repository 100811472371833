const OWNERSHIPS_LABELS = {
  PERSONAL: 'Personal',
  COMPANY: 'Company'
}

const OWNERSHIPS = {
  PERSONAL: 'PERSONAL',
  COMPANY: 'COMPANY'
}

const OWNERSHIPS_OPTIONS = Object.keys(OWNERSHIPS_LABELS).map((key) => ({
  label: OWNERSHIPS_LABELS[key],
  value: key
}))

export default OWNERSHIPS
export { OWNERSHIPS_OPTIONS, OWNERSHIPS, OWNERSHIPS_LABELS }
