import styled from 'styled-components'

export const StyledSuffix = styled.span`
  position: absolute;
  padding: var(--input-padding-vertical-base) 0px;
  font-size: var(--ql-typography-font-size-md);
  line-height: var(--ql-typography-line-height-md);
  margin-top: 2px;
  z-index: 1;
  top: 0;
`
