import { CardDropdown, SelectableCard } from 'components'

import PropTypes from 'prop-types'
import { RentalCalculatorView } from './components'
import { useRentalCalculatorBaseActions } from 'domains/RentalCalculator/hooks'
import { useState } from 'react'

const RentalCalculatorSimpleView = (props) => {
  const { rentalCalculator, index, isSelected } = props

  const [isHovered, setIsHovered] = useState(false)

  /* Getting actions from useSimpleFormActions hook */
  const actions = useRentalCalculatorBaseActions({
    rentalCalculatorId: rentalCalculator?._id
  })
  const { onEdit, onDelete } = actions

  return (
    <CardDropdown
      document={rentalCalculator}
      index={index}
      data-testid="rentalCalculatorListItem">
      <SelectableCard
        isSelected={isSelected}
        hoverable
        onClick={onEdit}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}>
        <RentalCalculatorView
          rentalCalculator={rentalCalculator}
          isHovered={isHovered}
          onDelete={onDelete}
          onEdit={onEdit}
        />
      </SelectableCard>
    </CardDropdown>
  )
}

RentalCalculatorSimpleView.propTypes = {
  rentalCalculator: PropTypes.object,
  onSelect: PropTypes.func,
  index: PropTypes.number,
  isSelected: PropTypes.bool,
  actions: PropTypes.object
}

export default RentalCalculatorSimpleView
