import { Button, Form } from 'antd'

import { KEYS } from '__constants__'
import PropTypes from 'prop-types'
import { UserSimpleForm } from 'domains/User/components'
import { useActionsUserAdvancedForm } from './hooks'
import { useTranslations } from 'contexts/Translation'

const UserAdvancedForm = (props) => {
  const { initialData, form, onSuccess } = props

  /* Getting translations instance */
  const { t } = useTranslations()

  /* Handling enter key press */
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      onFinish()
    }
  }

  /* Getting loading state and actions from hook */
  const { onFinish } = useActionsUserAdvancedForm({
    initialData,
    form,
    onSuccess
  })

  return (
    <Form
      onKeyPress={handleKeyPress}
      onFinish={onFinish}
      layout="vertical"
      autoComplete="off"
      form={form}>
      <UserSimpleForm group={['user']} />
      <Button type="primary" block onClick={() => form.submit()}>
        {t('Save changes')}
      </Button>
    </Form>
  )
}

UserAdvancedForm.propTypes = {
  initialData: PropTypes.object,
  group: PropTypes.array,
  showTitle: PropTypes.bool,
  form: PropTypes.object,
  onSuccess: PropTypes.func
}

export default UserAdvancedForm
