import { Form, Typography } from 'antd'

import { Box } from '@qonsoll/react-design'
import InputPrice from '../InputPrice'
import PropTypes from 'prop-types'
import { Slider } from 'components'
import { memo } from 'react'
import { useCurrency } from 'contexts'

const { useWatch } = Form

const RefinanceInput = (props) => {
  const {
    group,
    value = 0,
    onChange,
    form,
    isPrintMode,
    disabled,
    ...rest
  } = props

  const { percentSymbol } = useCurrency()

  const refinanceMax = useWatch(['calculations', 'refinanceMax'], form)
  const debtToValueRatio = useWatch(['calculations', 'debtToValueRatio'], form)

  const _disabled = disabled || refinanceMax <= 0 || !refinanceMax

  const handleChange = (newValue) => {
    const _value = newValue > refinanceMax ? refinanceMax : newValue
    if (newValue > refinanceMax) {
      document?.getElementById('rentalCalculator_refinance')?.blur()
      document?.getElementById('rentalCalculator_refinance')?.focus()
    }
    onChange(_value < 0 ? 0 : _value)
  }

  return (
    <>
      <InputPrice
        addonAfter={
          <Box display="flex" alignItems="center" justifyContent="center">
            <Typography.Text disabled={_disabled}>
              {debtToValueRatio?.toFixed?.(1) || 0}
              {percentSymbol}
            </Typography.Text>
          </Box>
        }
        value={value}
        onChange={handleChange}
        disabled={_disabled}
        {...rest}
      />
      <Slider
        min={0}
        max={refinanceMax}
        step={100}
        disabled={_disabled}
        onChange={handleChange}
        value={value}
      />
    </>
  )
}

RefinanceInput.propTypes = {
  group: PropTypes.array,
  form: PropTypes.object,
  isPrintMode: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.any,
  onChange: PropTypes.func
}

export default memo(RefinanceInput)
