import { Box, Col, Input, Row, Text, Title } from '@qonsoll/react-design'
import { Icon, Spinner } from 'components'
import { useFilter, useStateWithStorage } from 'hooks'
import { useMemo, useState } from 'react'

import { FlippingCalculatorList } from 'domains/FlippingCalculator/components'
import PATHS from 'pages/paths'
import { PageLayout } from 'components'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { RentalCalculatorList } from 'domains/RentalCalculator/components'
import { Tabs } from 'antd'
import { useGetFlippingCalculators } from 'domains/FlippingCalculator/hooks'
import { useGetRentalCalculators } from 'domains/RentalCalculator/hooks'
import { useTranslations } from 'contexts/Translation'
import { useUser } from 'domains/User/context'

const filterProjects = (projects, query) => {
  if (!query) return projects
  return projects?.filter((project) => {
    const { projectName } = project
    return projectName.toLowerCase().includes(query.toLowerCase())
  })
}

const ProjectsAllPage = (props) => {
  const { isProAccess, isAuthenticated, isFlippingProAccess } = useUser()
  const [activeTab, setActiveTab] = useStateWithStorage(
    null,
    'calculator-projects-list-active-tab'
  )

  const [query, setQuery] = useState('')

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting filter params */
  const filterParams = useFilter('rentalCalculator')
  /* Destructuring filter params */
  const { filterData } = filterParams
  /* Building filter query */
  const ref = useMemo(() => filterData, [filterData])
  /* Fetching rentalCalculators */
  const [projects, loading] = useGetRentalCalculators(ref)
  const [flippingCalculators, flippingCalculatorsLoading] =
    useGetFlippingCalculators()

  const filteredProjects = useMemo(
    () => filterProjects(projects, query),
    [projects, query]
  )

  const filteredFlippingCalculators = useMemo(
    () => filterProjects(flippingCalculators, query),
    [flippingCalculators, query]
  )

  const projectsAmount = filteredProjects?.length

  const changeTab = (key) => {
    setActiveTab(key)
    !!query && setQuery('')
  }

  if (isAuthenticated && !isProAccess && !isFlippingProAccess)
    return <Redirect to={PATHS.AUTHENTICATED.PLAN_SELECTION} />

  return (
    <PageLayout
      actions={
        <Input
          prefix={
            <Icon
              name="SearchLgOutlined"
              fill="var(--input-placeholder-color)"
              size={18}
            />
          }
          onChange={(e) => setQuery(e.target.value)}
          placeholder={t('Search by name') + '...'}
        />
      }
      title={t('My Projects')}
      subTitle={t(
        'Follow the instructions bellow to get results in shortest possible time'
      )}>
      {query && (
        <Box mb={2} display="flex" alignItems="center">
          <Box mr={3}>
            <Box
              borderRadius="50%"
              bg="var(--ql-color-neutral1-t-lighten6)"
              width={52}
              height={52}
              display="flex"
              justifyContent="center"
              alignItems="center">
              <Icon
                name="SearchLgOutlined"
                size={24}
                fill="var(--ql-color-accent2-t-lighten1)"
              />
            </Box>
          </Box>
          <Box>
            {projectsAmount ? (
              <Title level={3} fontWeight={400}>
                {t('Found')} {projectsAmount}{' '}
                {projectsAmount === 1 ? t('result') : t('results')}
              </Title>
            ) : (
              <Title level={3} fontWeight={400}>
                {t('No results found')}
              </Title>
            )}
            <Text>
              {t('Displayed results for')} <strong>{query}</strong>
            </Text>
          </Box>
        </Box>
      )}
      <Row mx="var(--ql-grid-gutter-negative)">
        {loading && flippingCalculatorsLoading ? (
          <Col cw={12}>
            <Spinner height="70vh" text={t('Calculations loading')} />
          </Col>
        ) : (
          <Col cw={12}>
            <Tabs defaultActiveKey={activeTab} onChange={changeTab}>
              <Tabs.TabPane
                tab={t('Rental calculator projects')}
                key="#rentalCalculators"
                tab-index="#rentalCalculators">
                <RentalCalculatorList
                  rentalCalculators={filteredProjects}
                  isListWithCreate={!query}
                />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={t('Flipping calculator projects')}
                key="#flippingCalculator"
                tab-index="#flippingCalculator">
                <FlippingCalculatorList
                  flippingCalculators={filteredFlippingCalculators}
                  isListWithCreate={!query}
                />
              </Tabs.TabPane>
            </Tabs>
          </Col>
        )}
      </Row>
    </PageLayout>
  )
}

ProjectsAllPage.propTypes = {
  isListWithCreate: PropTypes.bool,
  withHeader: PropTypes.bool
}

export default ProjectsAllPage
