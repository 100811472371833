import { Box, Col, Row, Text } from '@qonsoll/react-design'

import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'
import { capitalize } from 'lodash'

const PaymentCardMethod = (props) => {
  const { brand, last4, expMonth, expYear } = props

  const { t } = useTranslations()
  return (
    <>
      <Box>
        <Row>
          <Col cw={[12, 4, 4, 3, 4]} mb={3}>
            <Text display="block" textAlign="center" strong>
              {capitalize(brand)}
              {' **** '}
              {last4}
            </Text>
            <Text display="block" textAlign="center" type="secondary">
              {t('Expires')} {expMonth}/{expYear}
            </Text>
          </Col>
          <Col cw={[12, 8, 8, 9, 8]} mb={3} v={'center'}>
            <Text display="block" textAlign="left">
              {t('You already have registered the payment card')}.
            </Text>
            <Text display="block" textAlign="left">
              {t('Would you like to use it, or enter new one?')}
            </Text>
          </Col>
        </Row>
      </Box>
    </>
  )
}

PaymentCardMethod.propTypes = {
  brand: PropTypes.string,
  last4: PropTypes.string,
  expMonth: PropTypes.number,
  expYear: PropTypes.number
}

export default PaymentCardMethod
