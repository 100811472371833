import { memo, useMemo } from 'react'

import InputPrice from '../InputPrice'
import PropTypes from 'prop-types'
import { Slider } from 'components'
import { useCurrency } from 'contexts'

const InputAmountWithPercent = (props) => {
  const {
    value,
    onChange,
    group,
    max = 50,
    form,
    targetField,
    step,
    disabled,
    ...rest
  } = props
  const { amount, percent = 0 } = value || {}
  const { percentSymbol } = useCurrency()

  const handleAmountChange = (amount) => {
    const target = form?.getFieldValue([...group, targetField])

    if (target) {
      const percentComputed = Number(((amount / target) * 100)?.toFixed(0))
      onChange({ amount, percent: percentComputed })
    } else onChange({ amount, percent })
  }

  const handlePercentChange = (percent) => {
    const target = form?.getFieldValue([...group, targetField])

    if (target) {
      const amount = Number(((percent / 100) * target)?.toFixed(0))
      onChange({ amount, percent })
    } else onChange({ percent })
  }

  const addonAfter = useMemo(
    () => `${percent}${percentSymbol}`,
    [percent, percentSymbol]
  )

  return (
    <>
      <InputPrice
        value={amount}
        onChange={handleAmountChange}
        addonAfter={addonAfter}
        disabled={disabled}
        {...rest}
      />
      <Slider
        value={percent}
        onChange={handlePercentChange}
        step={step}
        max={max}
        min={0}
        disabled={disabled}
      />
    </>
  )
}

InputAmountWithPercent.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  group: PropTypes.array,
  form: PropTypes.object,
  max: PropTypes.number,
  targetField: PropTypes.string,
  step: PropTypes.number,
  disabled: PropTypes.bool
}

export default memo(InputAmountWithPercent)
