import { Box, Button, Divider } from '@qonsoll/react-design'

import { PlanSimpleView } from 'domains/Plan/components'
import PropTypes from 'prop-types'
import { useBuyProduct } from 'domains/Product/hooks'
import { useTranslations } from 'contexts/Translation'

const ProductCheckoutForm = (props) => {
  const { plan, onChangeProduct, loading } = props
  const [buyProduct, buyLoading] = useBuyProduct(plan)

  const { t } = useTranslations()

  return (
    <Box>
      <PlanSimpleView
        withPeriods={false}
        isTrialUsed
        plan={{ ...plan, suffix: ' product' }}
        onChange={onChangeProduct}
      />
      <Divider my={4} />
      <Box display="flex" justifyContent="end">
        <Button
          width={['100%', '200px']}
          loading={loading || buyLoading}
          disabled={loading || buyLoading}
          onClick={buyProduct}
          type="primary">
          {t('Purchase')}
        </Button>
      </Box>
    </Box>
  )
}

ProductCheckoutForm.propTypes = {
  plan: PropTypes.object,
  onChangeProduct: PropTypes.func,
  loading: PropTypes.bool
}

export default ProductCheckoutForm
