import {
  createDocument,
  getId,
  updateDocument,
  updateParent
} from 'services/api/firebase'

import { COLLECTIONS } from '__constants__'
import { User } from 'models'
import { message } from 'antd'
import { uploadImage } from 'helpers'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'
import { useTranslations } from 'contexts/Translation'

const useActionsUserAdvancedForm = ({ initialData, form, onSuccess } = {}) => {
  /* State for loading */
  const [loading, setLoading] = useState(false)
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance */
  const history = useHistory()
  /* Getting history state */
  const historyState = history.location.state

  /* Function for preparing values for saving */
  const prepareValues = async (values = {}, additionalValues = {}) => {
    /* Getting id */
    const userId = initialData?.['user']?._id || getId(COLLECTIONS.USERS)

    /* Preparing user values */
    const preparedValues = {
      _id: userId,
      ...additionalValues,
      avatarUrl: (await uploadImage(values['user']?.['avatarUrl'])) ?? null,
      firstName: values['user']?.['firstName'] ?? null,
      lastName: values['user']?.['lastName'] ?? null,
      email: values['user']?.['email'] ?? null,
      phone: values['user']?.['phone'] ?? null
    }
    return preparedValues
  }

  /* Saving form data */
  const saveForm = async (values, callback) => {
    try {
      // Prepare data to be saved
      const data = await prepareValues(values)

      if (historyState?.parent)
        await updateParent(historyState.parent, data._id)

      // Save data
      if (initialData) {
        await updateDocument(COLLECTIONS.USERS, initialData.user?._id, data)
        message.success(t('User successfully updated'))
      } else {
        await createDocument(COLLECTIONS.USERS, data, data._id)
        message.success(t('User successfully created'))
      }
      // Final callback
      callback?.()
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      throw new Error(t('Something went wrong during data save'))
    }
  }

  /* Function for validating form */
  const validateForm = (values) => {
    try {
      // Prepare data to be validated
      const validationData = {
        avatarUrl: values['user']?.['avatarUrl'],
        firstName: values['user']?.['firstName'],
        lastName: values['user']?.['lastName'],
        email: values['user']?.['email'],
        phone: values['user']?.['phone']
      }
      User.validationSchema.validateSync(validationData)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      throw new Error(t('User validation error: ') + t(error.message))
    }
  }

  /* On finish callback */
  const onFinish = async () => {
    if (loading) return // Avoid multiple calls

    try {
      setLoading(true)
      // Get form values
      const formValues = form.getFieldsValue()
      // Validate fields
      validateForm(formValues)
      // Save data
      await saveForm(formValues)

      onSuccess?.()
    } catch (error) {
      setLoading(false)
      message.error(error.message)
    }
  }

  /* On cancel callback */
  const onReset = () => {
    form.resetFields()
    history.goBack()
  }

  return { onFinish, onReset, loading, saveForm, validateForm, prepareValues }
}

export default useActionsUserAdvancedForm
