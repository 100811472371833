const calculateEquity = (values) => {
  const { profitsSum = 0, propertyValueIncrease = 0, debt = 0 } = values

  /* Property value - loan + profitsSum + property value increase */
  const yearlyEquity = propertyValueIncrease - debt + profitsSum

  return yearlyEquity
}

export default calculateEquity
