import {} from 'domains/RentalCalculator/hooks'

import { COLLECTIONS } from '__constants__'
import { useDocument } from 'services/api/firebase'
import { useLoading } from 'hooks'
import { useMemo } from 'react'

const useGetFlippingCalculatorInitialValues = (id) => {
  /* Fetching data using useDocument hook */
  const [flippingCalculator, flippingCalculatorLoading] = useDocument({
    ref: id ? COLLECTIONS.FLIPPING_CALCULATORS + '/' + id : null
  })

  /* Merge all fetched data */
  const initialValues = useMemo(
    () => ({ flippingCalculator }),
    [flippingCalculator]
  )

  /* Merge all loading states */
  const loadings = useMemo(
    () => (id ? [!flippingCalculator, flippingCalculatorLoading] : []),
    [id, flippingCalculator, flippingCalculatorLoading]
  )

  /* Getting loading state using merged loadings */
  const loading = useLoading(loadings)

  return [initialValues, loading]
}

export default useGetFlippingCalculatorInitialValues
