import Chart from 'react-google-charts'
import PropTypes from 'prop-types'
import { useMemo } from 'react'

const YearlyNetProfitDiagram = ({ options }) => {
  const diagramData = useMemo(
    () => [
      ['', ''],
      ...options.map(({ label, value, locked }) => [
        label + 'y',
        locked ? 0 : value
      ])
    ],
    [options]
  )

  return (
    <Chart
      chartType="Bar"
      options={{ legend: { position: 'none' }, title: null }}
      data={diagramData}
      width="inherit"
      height="240px"
    />
  )
}

YearlyNetProfitDiagram.propTypes = {
  options: PropTypes.any
}

export default YearlyNetProfitDiagram
