import { Card } from '@qonsoll/react-design'
import styled from 'styled-components'

const PlanViewCard = styled(Card)`
  & {
    box-shadow: ${(props) =>
      props.selected ? 'rgba(0,0,0,0.35) 0px 5px 15px' : 'none'};
  }
`

export default PlanViewCard
