import { Col, Row, Text, Title } from '@qonsoll/react-design'

import PropTypes from 'prop-types'

const PageHeader = (props) => {
  const { title, subTitle, actions, mb } = props

  return (
    <Row v="center" mx="var(--ql-grid-gutter-negative)" mb={mb}>
      <Col order={[0]}>
        <Title mb={2}>{title}</Title>
      </Col>
      <Col cw={[12, 12, 12, 'auto']} order={[2, 2, 2, 1]} mt={[2, 2, 2, 0]}>
        {actions}
      </Col>
      <Col cw={12} order={[1, 1, 1, 2]}>
        <Text type="secondary">{subTitle}</Text>
      </Col>
    </Row>
  )
}

PageHeader.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  actions: PropTypes.any,
  mb: PropTypes.any
}

export default PageHeader
