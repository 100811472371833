import { Box, Text } from '@qonsoll/react-design'
import { memo, useCallback, useEffect, useState } from 'react'

import { FilesList } from './components'
import { Icon } from 'components'
import PropTypes from 'prop-types'
import { StyledDragger } from './ImageUploader.styles'
import { uploadImage } from 'helpers'
import { useTranslations } from 'contexts/Translation'

const AVAILABLE_FORMATS = [
  'image/jpeg',
  'image/png',
  'image/jpg',
  'image/tiff',
  'image/svg'
].join()

const ImageUploader = (props) => {
  const {
    value,
    onChange,
    uploadProps,
    disabled,
    withDirectUpload = true
  } = props

  const { t } = useTranslations()

  const [fileList, setFileList] = useState([])

  useEffect(() => setFileList(value || []), [value])

  const handleUpload = async ({ onSuccess, onError, file }) => {
    try {
      if (withDirectUpload) {
        const url = await uploadImage(file)
        setFileList((prev) => {
          const newFileList = [...prev, url]
          onChange?.(newFileList)
          return newFileList
        })
      }
      // else {}
      onSuccess()
    } catch (error) {
      onError(error)
    }
  }

  const handleRemove = useCallback(
    (url) => {
      setFileList((prev) => {
        const newFileList = prev.filter((file) => file !== url)
        onChange?.(newFileList)
        return newFileList
      })
    },
    [onChange]
  )

  return (
    <Box height="inherit">
      <Box height={160}>
        <StyledDragger
          customRequest={handleUpload}
          disabled={disabled}
          fileList={null}
          accept={AVAILABLE_FORMATS}
          {...uploadProps}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="128px"
            flexDirection="column">
            <Icon
              fill={
                disabled
                  ? 'var(--ql-state-disabled-color)'
                  : 'var(--ql-typography-text-color-primary)'
              }
              name="ImagePlusOutlined"
              size={20}
            />
            <Text disabled={disabled} mt={2}>
              {t('Drop your images here, or browse')}.
            </Text>
          </Box>
        </StyledDragger>
      </Box>
      <FilesList fileList={fileList} onRemove={handleRemove} />
    </Box>
  )
}

ImageUploader.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  withDirectUpload: PropTypes.bool,
  uploadProps: PropTypes.object,
  disabled: PropTypes.bool
}

export default memo(ImageUploader)
