import {
  createDocument,
  getIsDocumentExists,
  updateDocument,
  updateParent
} from 'services/api/firebase'

import { COLLECTIONS } from '__constants__'
import { message } from 'antd'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'
import { useTranslations } from 'contexts/Translation'
import { useUser } from 'domains/User/context'

const useActionsFlippingCalculatorAdvancedForm = (props) => {
  const { flippingCalculatorId, initialData, form } = props || {}

  /* State for loading */
  const [loading, setLoading] = useState(false)
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance */
  const history = useHistory()
  /* Getting history state */
  const historyState = history.location.state
  const { user } = useUser()

  /* Function for preparing values for saving */
  const prepareValues = async (values = {}, additionalValues = {}) => {
    const picturesOfTheProperty =
      values?.['flippingCalculator']?.['picturesOfTheProperty'] ?? null
    const numberOfPictures = picturesOfTheProperty?.length

    const financeRenovation =
      values?.['flippingCalculator']?.['financeRenovation'] ?? null
    if (
      typeof financeRenovation?.amount === 'string' ||
      financeRenovation?.amount instanceof String
    ) {
      financeRenovation.amount = Number(financeRenovation?.amount)
    }
    if (
      typeof financeRenovation?.percent === 'string' ||
      financeRenovation?.percent instanceof String
    ) {
      financeRenovation.percent = Number(financeRenovation?.percent)
    }

    /* Preparing flippingCalculator values */
    const preparedValues = {
      ...additionalValues,
      customerId: user?._id,
      projectName: values?.['flippingCalculator']?.['projectName'] ?? null,
      zillowLink: values?.['flippingCalculator']?.['zillowLink'] ?? null,
      ownership: values?.['flippingCalculator']?.['ownership'] ?? null,
      propertyType: values?.['flippingCalculator']?.['propertyType'] ?? null,
      askingPrice: values?.['flippingCalculator']?.['askingPrice'] ?? null,
      downPayment: values?.['flippingCalculator']?.['downPayment'] ?? null,
      interestRate: values?.['flippingCalculator']?.['interestRate'] ?? null,
      closingCost: values?.['flippingCalculator']?.['closingCost'] ?? null,
      upfrontRenovation:
        values?.['flippingCalculator']?.['upfrontRenovation'] ?? null,
      financeRenovation:
        (financeRenovation?.amount || 0 === financeRenovation?.amount) &&
        (financeRenovation?.percent || 0 === financeRenovation?.percent)
          ? financeRenovation
          : null,
      interestRateRenovationCost:
        values?.['flippingCalculator']?.['interestRateRenovationCost'] ?? null,
      fixedExpenses: values?.['flippingCalculator']?.['fixedExpenses'] ?? null,
      electricity: values?.['flippingCalculator']?.['electricity'] ?? null,
      insurance: values?.['flippingCalculator']?.['insurance'] ?? null,
      sellingFees: values?.['flippingCalculator']?.['sellingFees'] ?? null,
      monthsFromStartToFinish:
        values?.['flippingCalculator']?.['monthsFromStartToFinish'] ?? null,
      isTaxFree: values?.['flippingCalculator']?.['isTaxFree'] ?? false,
      totalRentalIncomeFromStartToFinish:
        values?.['flippingCalculator']?.[
          'totalRentalIncomeFromStartToFinish'
        ] ?? null,
      estimatedSellingBestCasePrice:
        values?.['flippingCalculator']?.['estimatedSellingBestCasePrice'] ??
        null,
      estimatedSellingPriceWorstCase:
        values?.['flippingCalculator']?.['estimatedSellingPriceWorstCase'] ??
        null,
      primaryRoom: values?.['flippingCalculator']?.['primaryRoom'] ?? null,
      closingTax: values?.['flippingCalculator']?.['closingTax'] ?? false,
      commonExpenses:
        values?.['flippingCalculator']?.['commonExpenses'] ?? null,
      picturesOfTheProperty:
        numberOfPictures > 0 ? picturesOfTheProperty : null,
      matchInterestRate:
        values?.['flippingCalculator']?.['matchInterestRate'] ?? false,
      calculations: values?.['calculations'] ?? null
    }
    return preparedValues
  }

  /* Saving form data */
  const saveForm = async (values, callback) => {
    try {
      // Prepare data to be saved
      const data = await prepareValues(values)

      if (historyState?.parent)
        await updateParent(historyState.parent, data._id)

      // Save data
      if (initialData) {
        await updateDocument(
          COLLECTIONS.FLIPPING_CALCULATORS,
          initialData.flippingCalculator?._id,
          data
        )
        message.success(t('Flipping calculator successfully updated'))
      } else {
        const isExists = await getIsDocumentExists(
          COLLECTIONS.FLIPPING_CALCULATORS,
          flippingCalculatorId
        )
        if (isExists) {
          await updateDocument(
            COLLECTIONS.FLIPPING_CALCULATORS,
            flippingCalculatorId,
            data
          )
          message.success(t('Flipping calculator successfully updated'))
        } else {
          await createDocument(
            COLLECTIONS.FLIPPING_CALCULATORS,
            data,
            flippingCalculatorId
          )
          message.success(t('Flipping calculator successfully created'))
        }
      }
      // Final callback
      callback?.()
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      throw new Error(t('Something went wrong during data save'))
    }
  }

  /* On finish callback */
  const onFinish = async () => {
    if (loading) return // Avoid multiple calls

    try {
      setLoading(true)
      // Get form values
      const formValues = form.getFieldsValue()
      // Save data
      await saveForm(formValues)
    } catch (error) {
      message.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  /* On cancel callback */
  const onReset = () => {
    form.resetFields()
    history.goBack()
  }

  return { onFinish, onReset, loading, saveForm, prepareValues }
}

export default useActionsFlippingCalculatorAdvancedForm
