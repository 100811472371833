import {
  BILLING_PERIODS,
  BILLING_PERIODS_INTERVAL_VALUES
} from '__constants__/enums'
import { Box, Button, Divider, Text, Title } from '@qonsoll/react-design'

import { Icon } from 'components'
import PATHS from 'pages/paths'
import PlanViewCard from './PlanView.styles'
import PropTypes from 'prop-types'
import Ribbon from 'antd/lib/badge/Ribbon'
import { Tooltip } from 'antd'
import { useHistory } from 'react-router-dom'
import { useTranslations } from 'contexts/Translation'

const PlanView = (props) => {
  const {
    plan = {},
    btnText,
    onSubmit,
    withButton = true,
    isTrialUsed,
    selectedPeriod = BILLING_PERIODS_INTERVAL_VALUES[BILLING_PERIODS.MONTHLY],
    suffix = ' plan',
    optionSuffix = 'PlanOption',
    selected,
    onSelect
  } = props

  const {
    _id,
    title,
    subtitle,
    description,
    accessibleOptions,
    notAccessibleOptions,
    loading,
    prices
  } = plan

  const { t } = useTranslations()
  const history = useHistory()
  const price = prices.find(({ period }) => period === selectedPeriod)

  const onContinue = (id) =>
    history.push(
      `${PATHS.AUTHENTICATED.PlAN_CHECKOUT}?selectedPlan=${id}&priceId=${price?.priceId}&selectedPeriod=${selectedPeriod}`
    )

  const isOptions = accessibleOptions?.length || notAccessibleOptions?.length

  const planViewCard = (
    <PlanViewCard
      selected={!!(selected?.priceId === plan?.priceId)}
      onClick={onSelect}
      bodyStyle={{ height: '100%' }}
      style={{
        borderColor: 'var(--ql-color-accent1)',
        height: '100%'
      }}>
      <Box
        height="100%"
        justifyContent="space-between"
        display="flex"
        flexDirection="column">
        <Box>
          <Title level={2} textAlign="center">
            {t(title + suffix)}
          </Title>
          {subtitle ? (
            <Title level={2} mb={2} textAlign="center">
              {subtitle}
            </Title>
          ) : null}
          {!isTrialUsed && (
            <Text textAlign="center" display="block" type="secondary">
              {t('trialPeriod')}
            </Text>
          )}
          <Box display="flex" justifyContent="center"></Box>
          <Text textAlign="center" display="block">
            <Text
              color="var(--ql-color-accent1)"
              fontSize={20}
              letterSpacing="2px">
              {price?.price / 100}
              {price?.currencySymbol}
            </Text>
            {price?.period && (
              <Text type="secondary" letterSpacing="2px">
                /
              </Text>
            )}
            <Text type="secondary">{t(price?.period)}</Text>
          </Text>
          {description && <Divider my={2} />}
          <Tooltip title={t(description)}>
            <Text display="block" fontSize={14} ellipsis={isOptions}>
              {t(description)}
            </Text>
          </Tooltip>
          <Divider my={2} />
          <Box mb={3}>
            {accessibleOptions?.map(({ key, value }) => (
              <Box display="flex" key={key} mb={2} alignItems="center">
                <Icon
                  mr={2}
                  name="CheckOutlined"
                  fill="var(--ql-typography-text-color-success)"
                />
                <Text fontSize={14}>
                  {key === 'tickets' ? t(value + 'ticketsAmount') : null}{' '}
                  {t(key + optionSuffix)}
                </Text>
              </Box>
            ))}
            {notAccessibleOptions?.map(({ key, value }) => (
              <Box display="flex" key={key} mb={2} alignItems="center">
                <Icon
                  mr={2}
                  name="XCloseOutlined"
                  fill="var(--ql-typography-text-color-danger)"
                />
                <Text fontSize={14} delete>
                  {t(key + optionSuffix)}
                </Text>
              </Box>
            ))}
          </Box>
        </Box>
        {withButton && (
          <Box justifyContent="center" display="flex">
            <Button
              loading={loading}
              onClick={onSubmit ? onSubmit : () => onContinue(_id)}
              type="primary">
              {btnText || t('Continue')}
            </Button>
          </Box>
        )}
      </Box>
    </PlanViewCard>
  )

  return selectedPeriod === 1 ? (
    <Ribbon text={t('Save up to 20%')}>{planViewCard}</Ribbon>
  ) : (
    planViewCard
  )
}

PlanView.propTypes = {
  plan: PropTypes.object,
  btnText: PropTypes.string,
  onSubmit: PropTypes.func,
  withButton: PropTypes.bool,
  isTrialUsed: PropTypes.bool,
  suffix: PropTypes.string,
  optionSuffix: PropTypes.string,
  selectedPeriod: PropTypes.number,
  selected: PropTypes.bool,
  onSelect: PropTypes.func
}

export default PlanView
