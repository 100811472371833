import { Checkbox, Col, Form, Row, Typography } from 'antd'
import { StyledAddon, StyledSelect } from './LoanTermsInput.styled'

import { Box } from '@qonsoll/react-design'
import { Info } from 'components'
import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'

const LoanTermsInput = (props) => {
  const { options, group, disabled, ...rest } = props

  /* Getting translations instance */
  const { t } = useTranslations()

  return (
    <Row wrap={false}>
      <Col flex="auto">
        <StyledSelect
          disabled={disabled}
          style={{
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0
          }}
          options={options?.map((o) => ({ ...o, label: t(o.label) }))}
          {...rest}
        />
      </Col>
      <Col>
        <StyledAddon className="input-addon" disabled={disabled}>
          <Box mr={2}>
            <Form.Item
              style={{ margin: 0 }}
              name={[...group, 'noPrincipal']}
              valuePropName="checked">
              <Checkbox disabled={disabled} />
            </Form.Item>
          </Box>
          <Typography.Text disabled={disabled}>
            {t('No principal')}
          </Typography.Text>
          <Box ml={2}>
            <Info title={t('noPrincipalInfo')} />
          </Box>
        </StyledAddon>
      </Col>
    </Row>
  )
}

LoanTermsInput.propTypes = {
  options: PropTypes.array,
  group: PropTypes.array,
  disabled: PropTypes.bool
}

export default LoanTermsInput
