import { Button, Col, Row, Title } from '@qonsoll/react-design'
import { PRODUCT_TYPES, PRODUCT_TYPE_PACKAGE } from '__constants__/enums'

import { Grid } from 'antd'
import PATHS from 'pages/paths'
import { PageLayout } from 'components'
import { Spinner } from 'components'
import { UserAdvancedForm } from 'domains/User/components'
import { UserPlan } from 'domains/Plan/components'
import firebase from 'firebase/compat/app'
import { useGetUserInitialValues } from 'domains/User/hooks'
import { useHistory } from 'react-router-dom'
import { useInitializeUserAdvancedForm } from 'domains/User/components/UserAdvancedForm/hooks'
import { useTranslations } from 'contexts/Translation'
import { useUser } from 'domains/User/context'

const UserEditPage = (props) => {
  /* Getting translations instance */
  const { t } = useTranslations()
  const { user } = useUser()
  const { xs } = Grid.useBreakpoint()

  const subscriptionId =
    user?.subscriptions?.[PRODUCT_TYPES.FLIPPING] ||
    user?.subscriptions?.[PRODUCT_TYPES.RENTAL] ||
    user?.subscriptions?.[PRODUCT_TYPE_PACKAGE] ||
    user?.subscriptionId

  const history = useHistory()

  /* Fetching user initial values */
  const [initialValues, loading] = useGetUserInitialValues(user?._id)

  /* Getting form instance from hook */
  const { form } = useInitializeUserAdvancedForm(initialValues)

  const subbedPlans = user?.subscriptions
    ? Object?.entries(user?.subscriptions)?.reduce(
        (acc, [key, value]) => (value ? acc.concat(key) : acc),
        []
      )
    : []

  return (
    <PageLayout title={t('Account')}>
      <Row mx="var(--ql-grid-gutter-negative)" justifyContent="space-between">
        {loading ? (
          <Col>
            <Spinner height="70vh" text={t('User loading')} />
          </Col>
        ) : (
          <>
            <Col
              cw={subscriptionId ? [12, 12, 5] : [12, 12, 8, 7, 5]}
              mb={subscriptionId ? 4 : 0}>
              <UserAdvancedForm
                onSuccess={() =>
                  history.push(PATHS.AUTHENTICATED.RENTAL_CALCULATOR)
                }
                initialData={initialValues}
                form={form}
              />
              {xs && (
                <Button
                  danger
                  mt={2}
                  type="primary"
                  onClick={() => {
                    firebase.auth().signOut()
                    history.push('/')
                  }}>
                  {t('Logout')}
                </Button>
              )}
            </Col>
            {subscriptionId && (
              <Col cw={[12, 12, 5]}>
                <Title mb={3} level={2}>
                  {t('Current plans')}
                </Title>
                {subbedPlans?.map((productType, index) => (
                  <UserPlan
                    key={`${productType}-${index}`}
                    productType={productType}
                  />
                ))}
              </Col>
            )}
          </>
        )}
      </Row>
    </PageLayout>
  )
}

export default UserEditPage
