import { useEffect } from 'react'
import { Form } from 'antd'

const useInitializeUserAdvancedForm = (initialData) => {
  /* Getting form instance */
  const [form] = Form.useForm()

  /* Setting initial data */
  useEffect(() => {
    if (initialData) {
      /* Getting initial values */
      const { user } = initialData

      /* Formatting date fields */
      const formValues = {
        user: {
          avatarUrl: user?.['avatarUrl'] ?? null,
          firstName: user?.['firstName'] ?? null,
          lastName: user?.['lastName'] ?? null,
          email: user?.['email'] ?? null,
          phone: user?.['phone'] ?? null
        }
      }

      /* Setting initial values to form instance */
      form.setFieldsValue(formValues)
    }
  }, [initialData, form])

  return { form }
}

export default useInitializeUserAdvancedForm
