import { getDatabase, onValue, ref } from 'firebase/database'
import { useEffect, useState } from 'react'

import { PRODUCT_TYPES, PRODUCT_TYPE_PACKAGE } from '__constants__/enums'
import { useUser } from 'domains/User/context'

const useGetUserTickets = (productType) => {
  const { user } = useUser()
  const [tickets, setTickets] = useState(0)
  const [isUnlimited, setIsUnlimited] = useState(false)

  useEffect(() => {
    if (
      user?.isUnlimitedUser ||
      (user?.subscriptions?.[PRODUCT_TYPE_PACKAGE] &&
        (user?.isProAccess || user?.isFlippingProAccess))
    ) {
      setIsUnlimited(true)
    }
  }, [user])

  useEffect(() => {
    const fieldName =
      PRODUCT_TYPES.RENTAL === productType
        ? 'subscription'
        : `${productType?.toLowerCase()}Subscription`
    const subscribe = async () => {
      const db = getDatabase()
      const dbRef = user ? ref(db, 'tickets/' + user?._id) : null
      onValue(dbRef, (snapshot) => {
        const data = snapshot.val()

        const extra = data?.extra || 0
        const subscription = data ? data[fieldName] : 0

        if (subscription?.toUpperCase?.() === 'UNLIMITED') {
          setIsUnlimited(true)
        } else
          data && setTickets((Number(extra) || 0) + (Number(subscription) || 0))
      })
      return dbRef
    }
    const unsubscribe = user ? subscribe() : null
    return () => unsubscribe?.off?.()
  }, [user, productType])

  return { tickets, isUnlimited }
}

export default useGetUserTickets
