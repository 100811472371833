import {
  LOAN_TERMS,
  LOAN_TERMS_YEARS,
  OWNERSHIPS,
  PROPERTY_TYPES,
  RENTAL_UNITS
} from '__constants__/enums'
import { useEffect, useState } from 'react'

import { Form } from 'antd'
import { INTEREST_RATE_PERCENTS_RENTAL } from '__constants__/calculations/interestRatePercents'

const useInitializeRentalCalculatorAdvancedForm = (props) => {
  const { initialData, isProAccess } = props
  const [loading, setLoading] = useState(!!initialData)

  /* Getting form instance */
  const [form] = Form.useForm()

  /* Setting initial data */
  useEffect(() => {
    if (initialData) {
      setLoading(true)
      /* Getting initial values */
      const { rentalCalculator } = initialData

      /* Formatting date fields */
      const formValues = {
        rentalCalculator: {
          projectName: rentalCalculator?.['projectName'] ?? null,
          zillowLink: rentalCalculator?.['zillowLink'] ?? null,
          ownership: rentalCalculator?.['ownership'] ?? null,
          propertyType: rentalCalculator?.['propertyType'] ?? null,
          askingPrice: rentalCalculator?.['askingPrice'] ?? null,
          rentalIncome: rentalCalculator?.['rentalIncome'] ?? null,
          interestRate: rentalCalculator?.['interestRate'] ?? null,
          downPayment: rentalCalculator?.['downPayment'] ?? null,
          closingCost: rentalCalculator?.['closingCost'] ?? null,
          upfrontRenovation: rentalCalculator?.['upfrontRenovation'] ?? null,
          financeRenovation: rentalCalculator?.['financeRenovation'] ?? null,
          loanTerms: rentalCalculator?.['loanTerms'] ?? null,
          maintenance: rentalCalculator?.['maintenance'] ?? null,
          propertyTax: rentalCalculator?.['propertyTax'] ?? null,
          insurance: rentalCalculator?.['insurance'] ?? null,
          sewer: rentalCalculator?.['sewer'] ?? null,
          management: rentalCalculator?.['management'] ?? null,
          vacancy: rentalCalculator?.['vacancy'] ?? null,
          communication: rentalCalculator?.['communication'] ?? null,
          other: rentalCalculator?.['other'] ?? null,
          rentGrowthRate: rentalCalculator?.['rentGrowthRate'] ?? null,
          propertyValueGrowthRate:
            rentalCalculator?.['propertyValueGrowthRate'] ?? null,
          numberOfBedrooms: rentalCalculator?.['numberOfBedrooms'] ?? null,
          primaryRoom: rentalCalculator?.['primaryRoom'] ?? null,
          rentalUnit: rentalCalculator?.['rentalUnit'] ?? null,
          picturesOfTheProperty:
            rentalCalculator?.['picturesOfTheProperty'] ?? null,
          notes: rentalCalculator?.['notes'] ?? null,
          closingTax: rentalCalculator?.['closingTax'] ?? null,
          isNetProfitPropertyGrowth:
            rentalCalculator?.['isNetProfitPropertyGrowth'] ?? null,
          fixedExpenses: rentalCalculator?.['fixedExpenses'] ?? null,
          commonExpenses: rentalCalculator?.['commonExpenses'] ?? null,
          electricity: rentalCalculator?.['electricity'] ?? null,
          noPrincipal: rentalCalculator?.['noPrincipal'] ?? null,
          newPropertyValue: rentalCalculator?.['newPropertyValue'] ?? null,
          refinance: rentalCalculator?.['refinance'] ?? null
        },
        calculations: rentalCalculator?.['calculations'] ?? null
      }

      /* Setting initial values to form instance */
      form.setFieldsValue(formValues)
      setLoading(false)
    } else {
      const defaultLoanTerms = LOAN_TERMS.TWENTY_FIFE_YEARS
      const years = LOAN_TERMS_YEARS[defaultLoanTerms]

      const formValues = {
        rentalCalculator: {
          ownership: OWNERSHIPS.PERSONAL,
          rentalUnit: RENTAL_UNITS.OWN_PROPERTY,
          downPayment: { percent: 15, amount: null },
          loanTerms: defaultLoanTerms,
          interestRate: INTEREST_RATE_PERCENTS_RENTAL[OWNERSHIPS.PERSONAL],
          propertyType: PROPERTY_TYPES.FREEHOLD,
          rentGrowthRate: 2.5,
          propertyValueGrowthRate: 3,
          closingTax: true,
          noPrincipal: false
        },
        calculations: {
          years: years,
          netProfits: Array(years).fill(0),
          mortgages: Array(years).fill(0),
          propertyValues: Array(years).fill(0),
          equities: Array(years).fill(0)
        }
      }
      form.setFieldsValue(formValues)
    }
  }, [initialData, form, isProAccess])

  return { form, loading }
}

export default useInitializeRentalCalculatorAdvancedForm
