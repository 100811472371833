import { Addon } from './components'
import { Input } from 'antd'
import PropTypes from 'prop-types'
import { PRODUCT_TYPES } from '__constants__/enums'
import { calculateExpenses } from 'domains/RentalCalculator/components/RentalCalculatorAdvancedForm/helpers'
import { calculateSummary } from 'domains/FlippingCalculator/components/FlippingCalculatorAdvancedForm/helpers'
import { memo } from 'react'
import { useGetUserTickets } from 'domains/User/hooks'
import { useScrapDataByUrl } from 'domains/RentalCalculator/hooks'

const UrlInput = (props) => {
  const {
    value,
    onChange,
    form,
    disabled,
    onOwnershipChange,
    calculatorType = 'rentalCalculator',
    ...rest
  } = props

  // TODO improve it if new calculator types are added
  const productType =
    'rentalCalculator' === calculatorType
      ? PRODUCT_TYPES.RENTAL
      : PRODUCT_TYPES.FLIPPING

  const [scrapDataByUrl, scrapLoading] = useScrapDataByUrl(calculatorType)

  const { tickets, isUnlimited } = useGetUserTickets(productType)
  const isTicketsLeft = isUnlimited || tickets > 0

  const extractUrl = (text) => {
    const regex = /(https?:\/\/[^\s]+)/
    return text?.match(regex)?.[0] || null
  }

  const handleChange = async (e) => {
    const value = e.target.value
    const url = extractUrl(value)

    onChange?.(e.target.value)

    if (url) {
      const data = await scrapDataByUrl(url)

      // Error handling
      if (!data) return

      const formFields = form.getFieldsValue()?.[calculatorType]
      const percent = formFields?.downPayment?.percent
      const askingPrice = data?.askingPrice
      const amount = Number(((percent / 100) * askingPrice)?.toFixed(0))

      if (data.ownership) onOwnershipChange?.(data.ownership)

      const formData = {
        ...formFields,
        ...data,
        askingPrice,
        downPayment: { amount, percent }
      }

      const calculations =
        calculatorType === 'rentalCalculator'
          ? calculateExpenses(formData, !disabled)
          : calculateSummary(formData, !disabled)
      const dataToSave = { [calculatorType]: formData, calculations }
      await form.setFieldsValue(dataToSave)
      form.submit()
    }
  }

  return (
    <Input
      value={value}
      onChange={handleChange}
      addonAfter={
        ((!isUnlimited && !disabled) || scrapLoading) && (
          <Addon
            isTicketsLeft={isTicketsLeft}
            loading={scrapLoading}
            tickets={tickets}
          />
        )
      }
      disabled={disabled || !isTicketsLeft || scrapLoading}
      {...rest}
    />
  )
}

UrlInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  form: PropTypes.object,
  disabled: PropTypes.bool,
  onOwnershipChange: PropTypes.func,
  calculatorType: PropTypes.string
}

export default memo(UrlInput)
