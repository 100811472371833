const PATHS = {
  CONFIG: {
    DEFAULT: '/auth',
    AFTER_LOGIN: '/rental-calculator',
    AFTER_FLIPPING: '/flipping-calculator',
    AFTER_LOGOUT: '/rental-calculator',
    AFTER_SIGNUP: '/rental-calculator',
    AFTER_PAYMENT: '/rental-calculator'
  },
  UNAUTHENTICATED: {
    LOGIN: '/auth',
    LOGIN_WITH_EMAIL: '/auth/login-with-email',
    SIGNUP: '/auth/signup',
    SIGNUP_WITH_EMAIL: '/auth/sign-up-with-email',
    FORGOT_PASSWORD: '/auth/forgot-password',
    CONFIRM_EMAIL: '/auth/confirm-email'
  },
  SERVICE: {
    ACCESS_DENIED: '/service/access-denied',
    GDPR: '/service/gdpr',
    NOT_FOUND: '/service/404',
    TERMS_AND_CONDITIONS: '/service/terms-and-conditions'
  },
  AUTHENTICATED: {
    DASHBOARD: '/dashboard',
    USER_EDIT: '/account',
    TRANSLATIONS: '/translations',
    SETTINGS: '/settings',
    STATISTICS: '/statistics',
    RENTAL_CALCULATOR: '/rental-calculator',
    FLIPPING_CALCULATOR: '/flipping-calculator',
    FLIPPING_CALCULATOR_EDIT: '/projects/flipping-calculators/:projectId/edit',
    RENTAL_CALCULATOR_EDIT: '/projects/:projectId/edit',
    HELPS_ALL: '/help',
    PLAN_SELECTION: '/plan-selection',
    PRODUCT_SELECTION: '/product-selection',
    PlAN_CHECKOUT: '/plan-checkout',
    PRODUCT_CHECKOUT: '/product-checkout',
    PROJECTS_ALL: '/projects'
  }
}

export default PATHS
