import {
  LOAN_TERMS,
  LOAN_TERMS_YEARS,
  OWNERSHIPS,
  PROPERTY_TYPES,
  RENTAL_UNITS
} from '__constants__/enums'
import { useEffect, useState } from 'react'

import { Form } from 'antd'
import INTEREST_RATE_PERCENTS_FLIPPING from '__constants__/calculations/interestRatePercents'

const useInitializeFlippingCalculatorAdvancedForm = (props) => {
  const { initialData, isProAccess } = props
  const [loading, setLoading] = useState(!!initialData)

  /* Getting form instance */
  const [form] = Form.useForm()

  /* Setting initial data */
  useEffect(() => {
    if (initialData) {
      setLoading(true)
      /* Getting initial values */
      const { flippingCalculator } = initialData

      /* Formatting date fields */
      const formValues = {
        flippingCalculator: {
          projectName: flippingCalculator?.['projectName'] ?? null,
          zillowLink: flippingCalculator?.['zillowLink'] ?? null,
          ownership: flippingCalculator?.['ownership'] ?? null,
          propertyType: flippingCalculator?.['propertyType'] ?? null,
          askingPrice: flippingCalculator?.['askingPrice'] ?? null,
          downPayment: flippingCalculator?.['downPayment'] ?? null,
          interestRate: flippingCalculator?.['interestRate'] ?? null,
          closingCost: flippingCalculator?.['closingCost'] ?? null,
          upfrontRenovation: flippingCalculator?.['upfrontRenovation'] ?? null,
          financeRenovation: flippingCalculator?.['financeRenovation'] ?? null,
          interestRateRenovationCost:
            flippingCalculator?.['interestRateRenovationCost'] ?? null,
          fixedExpenses: flippingCalculator?.['fixedExpenses'] ?? null,
          matchInterestRate: flippingCalculator?.['matchInterestRate'] ?? false,
          electricity: flippingCalculator?.['electricity'] ?? null,
          insurance: flippingCalculator?.['insurance'] ?? null,
          sellingFees: flippingCalculator?.['sellingFees'] ?? null,
          monthsFromStartToFinish:
            flippingCalculator?.['monthsFromStartToFinish'] ?? null,
          isTaxFree: flippingCalculator?.['isTaxFree'] ?? false,
          totalRentalIncomeFromStartToFinish:
            flippingCalculator?.['totalRentalIncomeFromStartToFinish'] ?? null,
          estimatedSellingBestCasePrice:
            flippingCalculator?.['estimatedSellingBestCasePrice'] ?? null,
          estimatedSellingPriceWorstCase:
            flippingCalculator?.['estimatedSellingPriceWorstCase'] ?? null,
          picturesOfTheProperty:
            flippingCalculator?.['picturesOfTheProperty'] ?? null,
          closingTax: flippingCalculator?.['closingTax'] ?? null,
          loanTerms: flippingCalculator?.['loanTerms'] ?? null,
          primaryRoom: flippingCalculator?.['primaryRoom'] ?? null,
          commonExpenses: flippingCalculator?.['commonExpenses'] ?? null
        },
        calculations: flippingCalculator?.['calculations'] ?? null
      }

      /* Setting initial values to form instance */
      form.setFieldsValue(formValues)
      setLoading(false)
    } else {
      const loanTerms = LOAN_TERMS.TWENTY_FIFE_YEARS
      const years = LOAN_TERMS_YEARS[loanTerms]

      const formValues = {
        flippingCalculator: {
          ownership: OWNERSHIPS.PERSONAL,
          rentalUnit: RENTAL_UNITS.OWN_PROPERTY,
          downPayment: { percent: 15, amount: null },
          interestRate: INTEREST_RATE_PERCENTS_FLIPPING[OWNERSHIPS.PERSONAL],
          loanTerms,
          propertyType: PROPERTY_TYPES.FREEHOLD,
          interestRateRenovationCost:
            INTEREST_RATE_PERCENTS_FLIPPING[OWNERSHIPS.PERSONAL],
          closingTax: true,
          isTaxFree: false,
          matchInterestRate: true
        },
        calculations: {
          years: years,
          netProfits: Array(years).fill(0),
          mortgages: Array(years).fill(0),
          propertyValues: Array(years).fill(0),
          equities: Array(years).fill(0)
        }
      }
      form.setFieldsValue(formValues)
    }
  }, [initialData, form, isProAccess])

  return { form, loading }
}

export default useInitializeFlippingCalculatorAdvancedForm
