import { useMemo } from 'react'

const useUserName = (user) => {
  const { firstName, lastName, email, avatarUrl } = user || {}

  const isDisplayNameExists = useMemo(
    () => firstName || lastName,
    [firstName, lastName]
  )
  const displayName = useMemo(
    () => (firstName || 'N/A') + ' ' + (lastName || ''),
    [firstName, lastName]
  )
  const avatarText = useMemo(
    () => (firstName && lastName ? firstName[0] + lastName[0] : null),
    [firstName, lastName]
  )

  return useMemo(
    () => ({ displayName, avatarText, email, avatarUrl, isDisplayNameExists }),
    [displayName, avatarText, email, avatarUrl, isDisplayNameExists]
  )
}

export default useUserName
