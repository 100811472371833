import { Box } from '@qonsoll/react-design'
import PropTypes from 'prop-types'
import SummaryItem from '../../../SummaryItem'
import { useMemo } from 'react'
import { useTranslations } from 'contexts/Translation'

const YearlyNetProfitList = ({ options, isLocked, measurementSymbol }) => {
  const { t } = useTranslations()

  const listOptions = useMemo(() => {
    const _options = isLocked ? options.slice(0, 2) : options
    return _options.map(({ label, value, locked }) => ({
      label: `${label}. ${t('Year')}`,
      measurementSymbol,
      locked,
      value
    }))
  }, [options, t, isLocked, measurementSymbol])

  return (
    <Box>
      {listOptions?.map((options) => (
        <SummaryItem key={options.label} {...options} />
      ))}
    </Box>
  )
}

YearlyNetProfitList.propTypes = {
  options: PropTypes.array,
  isLocked: PropTypes.bool,
  measurementSymbol: PropTypes.string
}

export default YearlyNetProfitList
