import { Button, Col, Row } from '@qonsoll/react-design'
import {
  CalculatorPopover,
  RentalCalculatorAdvancedForm
} from 'domains/RentalCalculator/components'
import {
  useActionsRentalCalculatorAdvancedForm,
  useInitializeRentalCalculatorAdvancedForm
} from 'domains/RentalCalculator/components/RentalCalculatorAdvancedForm/hooks'
import { useCallback, useMemo } from 'react'

import { COLLECTIONS } from '__constants__'
import { Form } from 'antd'
import { LoanApplicationButton } from 'domains/Loan/components'
import { PRODUCT_TYPES } from '__constants__/enums'
import { PageLayout } from 'components'
import { getId } from 'services/api/firebase'
import { usePrint } from 'hooks'
import { useSendLoanApplication } from 'domains/Loan/hooks'
import { useTranslations } from 'contexts/Translation'
import { useUser } from 'domains/User/context'

const RentalCalculatorPage = () => {
  const { isProAccess, isNewCustomer } = useUser()
  const { t } = useTranslations()

  /* Getting form instance from hook */
  const { form } = useInitializeRentalCalculatorAdvancedForm({ isProAccess })
  const rentalCalculatorId = useMemo(
    () => getId(COLLECTIONS.RENTAL_CALCULATORS),
    []
  )
  const [handleSendLoanApplication] = useSendLoanApplication()
  const projectName = Form.useWatch(['rentalCalculator', 'projectName'], form)
  const finnLink = Form.useWatch(['rentalCalculator', 'zillowLink'], form)

  const onPDFCreatedCallback = useCallback(
    async (additionalArgs) =>
      await handleSendLoanApplication({
        projectName,
        finnLink,
        ...additionalArgs
      }),
    [projectName, finnLink, handleSendLoanApplication]
  )

  const { print, isPrintMode, PrintWrapper, download, isLoanSendMode } =
    usePrint(onPDFCreatedCallback)

  /* Getting loading state and actions from hook */
  const { onFinish } = useActionsRentalCalculatorAdvancedForm({
    rentalCalculatorId: rentalCalculatorId,
    form
  })

  return (
    <PageLayout
      title={t('Rental calculator')}
      isBlur={isNewCustomer}
      popover={
        <CalculatorPopover
          type={PRODUCT_TYPES.RENTAL}
          isNewCustomer={isNewCustomer}
        />
      }
      subTitle={t(
        'Follow the instructions bellow to get results in shortest possible time'
      )}
      actions={
        <Row mx="var(--ql-grid-gutter-negative)">
          <Col mb={[2, 0]}>
            <LoanApplicationButton
              projectName={projectName}
              form={form}
              isLoanSendMode={isLoanSendMode}
              download={download}
            />
          </Col>
          <Col mb={[2, 0]}>
            <Button
              block
              disabled={!isProAccess}
              onClick={print}
              loading={isPrintMode}>
              {t('Export to PDF')}
            </Button>
          </Col>
          <Col>
            <Button
              block
              type="primary"
              disabled={!isProAccess}
              onClick={() => form.submit()}>
              {t('Save project')}
            </Button>
          </Col>
        </Row>
      }>
      <RentalCalculatorAdvancedForm
        isAccessGranted={isProAccess}
        onFinish={onFinish}
        form={form}
      />
      <PrintWrapper>
        <RentalCalculatorAdvancedForm
          withId
          isAccessGranted={isProAccess}
          onFinish={onFinish}
          form={form}
        />
      </PrintWrapper>
    </PageLayout>
  )
}

export default RentalCalculatorPage
