import OWNERSHIPS from './ownerships'

const PROPERTY_TYPES = {
  // Personal
  FREEHOLD: 'FREEHOLD',
  SHARE: 'SHARE',
  // Company
  BUY_PROPERTY: 'BUY_PROPERTY',
  BUY_COMPANY: 'BUY_COMPANY'
}

const PROPERTY_TYPES_LABELS = {
  // Personal
  [PROPERTY_TYPES.FREEHOLD]: 'Freehold',
  [PROPERTY_TYPES.SHARE]: 'Share',
  // Company
  [PROPERTY_TYPES.BUY_PROPERTY]: 'Buy property',
  [PROPERTY_TYPES.BUY_COMPANY]: 'Buy company'
}

const prepareOptions = (options) => {
  return options.map((key) => ({
    label: PROPERTY_TYPES_LABELS[key],
    value: key
  }))
}

const PROPERTY_TYPES_OPTIONS = {
  [OWNERSHIPS.PERSONAL]: prepareOptions([
    PROPERTY_TYPES.FREEHOLD,
    PROPERTY_TYPES.SHARE
  ]),
  [OWNERSHIPS.COMPANY]: prepareOptions([
    PROPERTY_TYPES.BUY_PROPERTY,
    PROPERTY_TYPES.BUY_COMPANY
  ])
}

// /* (Only if the property is “selveier” - FREEHOLD) */
// /* When buying the property directly as a company, there will be a 2,5% closing fee */
const PROPERTY_TYPES_WITH_FEE = [
  PROPERTY_TYPES.FREEHOLD,
  PROPERTY_TYPES.BUY_PROPERTY
]

const COMPANY_PROPERTY_TYPES = [
  PROPERTY_TYPES.BUY_PROPERTY,
  PROPERTY_TYPES.BUY_COMPANY
]
const PERSONAL_PROPERTY_TYPES = [PROPERTY_TYPES.FREEHOLD, PROPERTY_TYPES.SHARE]

export {
  PROPERTY_TYPES_OPTIONS,
  PROPERTY_TYPES,
  PROPERTY_TYPES_WITH_FEE,
  COMPANY_PROPERTY_TYPES,
  PERSONAL_PROPERTY_TYPES
}
