import { Box, Col, Divider, Row, Text, Title } from '@qonsoll/react-design'

import { Icon } from 'components'
import PropTypes from 'prop-types'
import { useState } from 'react'

const HelpSimpleView = (props) => {
  const { question, answer, isLast } = props

  const [isCollapsed, setIsCollapsed] = useState(false)

  const toggle = () => setIsCollapsed((prev) => !prev)

  return (
    <Row noGutters>
      <Col cw={[12, 12, 9, 7]}>
        <Box display="flex" alignItems="center" mb={3}>
          <Icon
            name={isCollapsed ? 'MinusOutlined' : 'PlusOutlined'}
            fill="var(--ql-color-accent1)"
            onClick={toggle}
            cursor="pointer"
            size={20}
            mr={3}
          />
          <Title level={4} cursor="pointer" onClick={toggle}>
            {question}
          </Title>
        </Box>
        {isCollapsed && (
          <Box pl={36} mb={3}>
            <Text>{answer}</Text>
          </Box>
        )}
      </Col>
      {!isLast && (
        <Col cw={[12, 12, 12]} mb={3}>
          <Divider />
        </Col>
      )}
    </Row>
  )
}

HelpSimpleView.propTypes = {
  question: PropTypes.string,
  answer: PropTypes.string,
  isLast: PropTypes.bool
}

export default HelpSimpleView
