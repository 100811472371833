import { COLLECTIONS } from '__constants__'
import { useCollection } from 'services/api/firebase'
import { useMemo } from 'react'
import { useUser } from 'domains/User/context'

/**
 * It returns an array of values that are used to render the rentalCalculators
 * @param props - An object that contains the query parameters.
 * @returns An array of values.
 */
const useGetFlippingCalculators = (props) => {
  const { user } = useUser()

  /* Query for getting collection reference */
  const query = useMemo(
    () => ({
      ref: COLLECTIONS.FLIPPING_CALCULATORS,
      where: [['customerId', '==', user?._id || null]],
      ...props
    }),
    [props, user]
  )

  /* Getting collection data */
  const [value, loading, error, next, loadingMore, loadMoreAvailable] =
    useCollection(query)

  return [value, loading, error, next, loadingMore, loadMoreAvailable]
}

export default useGetFlippingCalculators
