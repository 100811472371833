import { Aside, Box } from '@qonsoll/react-design'

import styled from 'styled-components'

export const StyledAside = styled(Aside)`
  background-color: var(--aside-bg);
  transition: 0.5s;
  height: 100vh;
  z-index: 3;
`

export const LogoContainer = styled(Box)`
  display: flex;
  cursor: pointer;
  overflow: hidden;
  height: 92px;
`
