import { Box, Text } from '@qonsoll/react-design'

import { Icon } from 'components'
import PropTypes from 'prop-types'

const Slidebar = ({ text, onLeft, onRight, isPrintMode }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      padding="8px"
      bg={
        isPrintMode
          ? 'var(--ql-color-neutral1-t-lighten6)'
          : 'var(--ql-color-neutral1-t-lighten7)'
      }
      borderRadius="var(--input-border-radius-base)">
      <Box
        width="24px"
        height="24px"
        display="flex"
        alignItems="center"
        justifyContent="center">
        <Icon onClick={onLeft} cursor="pointer" name="ArrowLeftOutlined" />
      </Box>
      <Text width="96px" textAlign="center">
        {text}
      </Text>
      <Box
        width="24px"
        height="24px"
        display="flex"
        alignItems="center"
        justifyContent="center">
        <Icon onClick={onRight} cursor="pointer" name="ArrowRightOutlined" />
      </Box>
    </Box>
  )
}

Slidebar.propTypes = {
  text: PropTypes.string,
  onLeft: PropTypes.func,
  onRight: PropTypes.func,
  isPrintMode: PropTypes.bool
}

export default Slidebar
