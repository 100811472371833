// import { useEffect, useState } from 'react'

// import styled from 'styled-components'
// import { Slider as AntdSlider } from 'antd'
import PropTypes from 'prop-types'
// const StyledSlider = styled(AntdSlider)`
//   .ant-slider-track {
//     top: 2px;
//     height: 8px;
//     border-bottom-left-radius: 4px;
//     border-top-left-radius: 4px;
//     overflow: hidden;
//     background-color: var(--ql-color-accent1);
//   }
//   .ant-slider-step {
//     height: 8px;
//     top: 2px;
//   }
//   .ant-slider-rail {
//     top: 2px;
//     height: 8px;
//     border-bottom-right-radius: 4px;
//     border-top-right-radius: 4px;

//     overflow: hidden;
//     background-color: var(--ql-color-neutral1-t-lighten6);
//   }
//   .ant-slider-handle {
//     transform: translate(-10%) !important;
//   }
// `

const Slider = (props) => {
  const { value = 0, onChange, step = 1, ...rest } = props

  // const [sliderValue, setSliderValue] = useState(value || 0)
  // useEffect(() => setSliderValue(value), [value])

  return (
    <>
      {/* <StyledSlider
        value={value}
        onChange={onChange}
        style={{ marginInline: 0, marginBlock: '4px' }}
        onAfterChange={(value) => onChange(value)}
        tooltipVisible={false}
        step={step}
        {...rest}
      /> */}
      <input
        type="range"
        value={value || 0}
        onChange={(e) => onChange(e.target.value)}
        step={step}
        {...rest}
      />
    </>
  )
}

Slider.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  step: PropTypes.number
}

export default Slider
