import { Image } from 'antd'
import PropTypes from 'prop-types'
import longLogo from 'assets/logo/logo-full.svg'
import shortLogo from 'assets/logo/logo-symbol.svg'

const Logo = ({ collapse }) => {
  return (
    <Image
      src={collapse ? shortLogo : longLogo}
      alt="Qonsoll"
      height="40px"
      preview={false}
    />
  )
}

Logo.propTypes = {
  collapse: PropTypes.bool
}

export default Logo
