import {
  SelectableItem,
  SelectableWrapper
} from './DiagramStateSwitcher.styled'

import { Icon } from 'components'
import PropTypes from 'prop-types'

const DiagramStateSwitcher = ({ isDiagram, setIsDiagram, isPrintMode }) => {
  return (
    <SelectableWrapper
      display="flex"
      padding="4px"
      overflow="hidden"
      isPrintMode={isPrintMode}>
      <SelectableItem
        isPrintMode={isPrintMode}
        onClick={() => setIsDiagram(true)}
        selected={isDiagram}
        mr={1}>
        <Icon
          name="BarChart10Outlined"
          fill={
            isDiagram
              ? 'var(--ql-color-accent1)'
              : 'var(--ql-typography-text-color-primary)'
          }
          size={22}
        />
      </SelectableItem>
      <SelectableItem
        isPrintMode={isPrintMode}
        onClick={() => setIsDiagram(false)}
        selected={!isDiagram}>
        <Icon
          name="ListOutlined"
          fill={
            !isDiagram
              ? 'var(--ql-color-accent1)'
              : 'var(--ql-typography-text-color-primary)'
          }
          size={22}
        />
      </SelectableItem>
    </SelectableWrapper>
  )
}

DiagramStateSwitcher.propTypes = {
  isDiagram: PropTypes.bool.isRequired,
  setIsDiagram: PropTypes.func.isRequired,
  isPrintMode: PropTypes.bool
}

export default DiagramStateSwitcher
