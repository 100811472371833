const CUSTOMER_STATUSES = {
  ACTIVE: 'ACTIVE',
  DELETED: 'DELETED',
  FAILED: 'FAILED'
}

const CUSTOMER_STATUSES_OPTIONS = Object.keys(CUSTOMER_STATUSES).map((key) => ({
  label: CUSTOMER_STATUSES[key],
  value: key
}))

export default CUSTOMER_STATUSES
export { CUSTOMER_STATUSES_OPTIONS, CUSTOMER_STATUSES }
