import { Upload } from 'antd'
import styled from 'styled-components'

const styles = {
  image: {
    width: '100%',
    maxHeight: '400px',
    objectFit: 'cover',
    cursor: 'pointer'
  }
}

export const StyledDragger = styled(Upload.Dragger)`
  width: 100%;
  border: 2px dashed #0b13282e;
  background: ${({ disabled }) =>
    disabled ? 'var(--input-disabled-bg) !important' : 'transparent'};
`

export default styles
