import { Col, Row } from '@qonsoll/react-design'
import { PageLayout, Spinner } from 'components'
import { useFilter, useListView } from 'hooks'

import { HelpList } from 'domains/Help/components'
import PropTypes from 'prop-types'
import { useGetHelps } from 'domains/Help/hooks'
import { useMemo } from 'react'
import { useTranslations } from 'contexts/Translation'

const HelpsAllPage = (props) => {
  const { isListWithCreate } = props

  /* Getting translations instance */
  const { t } = useTranslations()
  /* State for controlling list view with localStorage remembering */
  const [listView] = useListView('help')
  /* Getting filter params */
  const filterParams = useFilter('help')
  /* Destructuring filter params */
  const { filterData } = filterParams

  /* Building filter query */
  const ref = useMemo(() => filterData, [filterData])
  /* Fetching helps */
  const [helps, loading] = useGetHelps(ref)
  return (
    <PageLayout title={t('Help')}>
      <Row mx="var(--ql-grid-gutter-negative)">
        {loading ? (
          <Col>
            <Spinner height="70vh" text={t('Help loading')} />
          </Col>
        ) : (
          <Col cw={[12, 12, 12, 12]}>
            <HelpList
              listView={listView}
              isListWithCreate={isListWithCreate}
              helps={helps}
            />
          </Col>
        )}
      </Row>
    </PageLayout>
  )
}

HelpsAllPage.propTypes = {
  isListWithCreate: PropTypes.bool,
  withHeader: PropTypes.bool
}

export default HelpsAllPage
