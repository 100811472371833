import { AccountMenu, LoginButton, Logo, MainMenu } from '../'
import { LogoContainer, StyledAside } from './LayoutAside.styled'

import { Box } from '@qonsoll/react-design'
import { GetPROAccess } from 'domains/Plan/components'
import { LanguageSelect } from 'modules/admin-module/domains/Translation/components'
import PATHS from 'pages/paths'
import PropTypes from 'prop-types'
import { memo } from 'react'
import { useHistory } from 'react-router-dom'
import { useUser } from 'domains/User/context'

const LayoutAside = ({ collapse, ...rest }) => {
  const history = useHistory()
  const { loading, isAuthenticated, isProAccess, isFlippingProAccess } =
    useUser()

  const goHome = () => history.push(PATHS.AUTHENTICATED.RENTAL_CALCULATOR)

  return (
    <StyledAside {...rest}>
      <LogoContainer
        justifyContent={collapse ? 'center' : null}
        onClick={goHome}>
        <Logo collapse={collapse} />
      </LogoContainer>
      <Box mb="auto" overflowY="auto" mx={-24}>
        <MainMenu collapse={collapse} />
      </Box>
      {!collapse && !isProAccess && !isFlippingProAccess && (
        <Box mb={4}>
          <GetPROAccess />
        </Box>
      )}
      <Box mb={2}>
        <LanguageSelect collapse={collapse} />
      </Box>
      {!loading && isAuthenticated ? (
        <Box ml={collapse ? 1 : 0}>
          <AccountMenu collapse={collapse} />
        </Box>
      ) : (
        <LoginButton collapse={collapse} />
      )}
    </StyledAside>
  )
}

LayoutAside.propTypes = {
  collapse: PropTypes.bool
}

export default memo(LayoutAside)
