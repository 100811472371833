function pmt(
  rate_per_period,
  number_of_payments,
  present_value,
  future_value = 0,
  type = 0
) {
  if (rate_per_period !== 0.0) {
    // Interest rate exists
    var q = Math.pow(1 + rate_per_period, number_of_payments)
    return (
      -(rate_per_period * (future_value + q * present_value)) /
      ((-1 + q) * (1 + rate_per_period * type))
    )
  } else if (number_of_payments !== 0.0) {
    // No interest rate, but number of payments exists
    return -(future_value + present_value) / number_of_payments
  }

  return 0
}

const calculateMortgage = (values) => {
  const askingPrice = Number(values?.askingPrice) || 0
  const propertyValue = askingPrice || 0
  const downPayment = Number(values?.downPayment?.amount) || 0
  const financeRenovation = Number(values?.financeRenovation?.amount) || 0
  const debt = askingPrice - downPayment + financeRenovation
  const loanToValueRatio = (debt / propertyValue) * 100
  const interestRate = Number(values?.interestRate) || 0
  const years = 1
  const noPrincipal = values?.noPrincipal
  const mainMortgageDebt = askingPrice - downPayment
  const financeRenovationDebt = financeRenovation
  const mainMortgageInterestRate = Number(values?.interestRate) || 0
  const financedRenovationCostRate =
    Number(values?.interestRateRenovationCost) || 0

  const period = years * 12 // Static value
  const monthlyRate = Number(interestRate / 100 / 12) // Static value
  const pmtValue = pmt(monthlyRate, period, debt)
  const monthlyPayment = noPrincipal
    ? debt * monthlyRate
    : Number.isFinite(pmtValue)
    ? -pmtValue
    : 0 // Static value
  const debtToValueRatio = (debt / propertyValue) * 100
  const monthlyInterest = noPrincipal ? monthlyPayment : debt * monthlyRate
  const monthlyInstallment = monthlyPayment - monthlyInterest

  return {
    debt,
    propertyValue,
    loanToValueRatio,
    downPayment,
    interestRate,
    years,
    monthlyPayment,
    interest: monthlyInterest,
    installment: monthlyInstallment,
    monthlyRate,
    debtToValueRatio,
    mainMortgageDebt,
    financeRenovationDebt,
    mainMortgageInterestRate,
    financedRenovationCostRate
  }
}

export default calculateMortgage
