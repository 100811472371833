function pmt(
  rate_per_period,
  number_of_payments,
  present_value,
  future_value = 0,
  type = 0
) {
  if (rate_per_period !== 0.0) {
    // Interest rate exists
    var q = Math.pow(1 + rate_per_period, number_of_payments)
    return (
      -(rate_per_period * (future_value + q * present_value)) /
      ((-1 + q) * (1 + rate_per_period * type))
    )
  } else if (number_of_payments !== 0.0) {
    // No interest rate, but number of payments exists
    return -(future_value + present_value) / number_of_payments
  }

  return 0
}

const calculateMortgageBase = (values) => {
  const { years } = values

  const askingPrice = Number(values?.askingPrice) || 0
  const downPayment = Number(values?.downPayment?.amount) || 0
  const interestRate = Number(values?.interestRate) || 0
  const noPrincipal = values?.noPrincipal
  const propertyValue = Number(values?.newPropertyValue) || askingPrice || 0
  const refinance = Number(values?.refinance) || 0
  const financeRenovation = Number(values?.financeRenovation?.amount) || 0
  const refinanceMax = propertyValue - askingPrice + downPayment
  const initialDebt = askingPrice - downPayment + refinance + financeRenovation

  const period = years * 12 // Static value
  const monthlyRate = Number(interestRate / 100 / 12) // Static value
  const monthlyPayment = noPrincipal
    ? initialDebt * monthlyRate
    : -pmt(monthlyRate, period, initialDebt) // Static value
  const debtToValueRatio = (initialDebt / propertyValue) * 100

  return {
    propertyValue: propertyValue,
    downPayment: downPayment,
    debt: initialDebt,
    interestRate: interestRate,
    years,
    monthlyPayment: monthlyPayment,
    monthlyRate,
    debtToValueRatio,
    refinanceMax
    // newPropertyValue
  }
}

export default calculateMortgageBase
