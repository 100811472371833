import { Button, Text } from '@qonsoll/react-design'

import { Icon } from '@qonsoll/icons'
import PATHS from 'pages/paths'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { useTranslations } from 'contexts/Translation'

const LoginButton = ({ collapse }) => {
  const history = useHistory()
  const { t } = useTranslations()

  return (
    <Button
      onClick={() => history.push(PATHS.UNAUTHENTICATED.LOGIN)}
      icon={
        <Icon fill="var(--ql-color-accent1)" name="LogIn1Outlined" size={16} />
      }
      type="text">
      {collapse ? null : (
        <Text color="var(--ql-color-accent1)" ml={2}>
          {t('Login')}
        </Text>
      )}
    </Button>
  )
}

LoginButton.propTypes = {
  collapse: PropTypes.bool
}

export default LoginButton
