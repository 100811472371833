import { Col, Divider, Row } from '@qonsoll/react-design'
import {
  RentalCalculatorSimpleForm,
  RentalCalculatorSummary
} from 'domains/RentalCalculator/components'

import { Form } from 'antd'
import { Logo } from 'components/BoilerplateLayout/components'
import PropTypes from 'prop-types'
import { memo } from 'react'

const CombinedForm = (props) => {
  const { form, isAccessGranted, initialData, isPrintMode } = props

  return (
    <Row noGutters>
      {isPrintMode && (
        <Col cw={12} mb={32} mt={-32}>
          <Logo />
        </Col>
      )}
      <Col cw={isPrintMode ? 5 : [12, 12, 12, 5]}>
        <RentalCalculatorSimpleForm
          isPrintMode={isPrintMode}
          isAccessRestricted={!isAccessGranted}
          initialOwnership={initialData?.rentalCalculator?.ownership}
          group={['rentalCalculator']}
          form={form}
        />
      </Col>
      <Col
        display={isPrintMode ? 'flex' : ['none', 'none', 'none', 'flex']}
        cw={isPrintMode ? 2 : [0, 0, 0, 2]}
        h="center"
        height="inherit">
        <Divider type="vertical" style={{ height: '100%' }} />
      </Col>
      <Col cw={isPrintMode ? 5 : [12, 12, 12, 5]}>
        <Form.Item name={['calculations']}>
          <RentalCalculatorSummary
            isAccessGranted={isAccessGranted}
            group={['calculations']}
            isPrintMode={isPrintMode}
            form={form}
          />
        </Form.Item>
      </Col>
    </Row>
  )
}

CombinedForm.propTypes = {
  form: PropTypes.object,
  isAccessGranted: PropTypes.bool,
  initialData: PropTypes.object,
  isPrintMode: PropTypes.bool
}

export default memo(CombinedForm)
