const BILLING_PERIODS = {
  MONTHLY: 'MONTHLY',
  ANNUALLY: 'ANNUALLY'
}

const BILLING_PERIODS_VALUES = {
  MONTHLY: 'Monthly',
  ANNUALLY: 'Annually'
}

const BILLING_PERIODS_INTERVAL_VALUES = {
  MONTHLY: 'month',
  ANNUALLY: 'year'
}

export {
  BILLING_PERIODS,
  BILLING_PERIODS_VALUES,
  BILLING_PERIODS_INTERVAL_VALUES
}
