import { COLLECTIONS } from '__constants__'
import { ROLES } from '__constants__/enums'
import firebase from 'firebase/compat/app'

const { USERS } = COLLECTIONS

/**
 * Save the user's data to the database.
 * @returns A promise that resolves to the user object.
 */
const saveUserToDB = ({
  id,
  email,
  avatarUrl = null,
  agreement = null,
  gdpr = null,
  additionalData = {},
  onError
}) => {
  const { firstName, lastName, displayName } = additionalData

  // Extract the first name and last name from the display name if they are not provided.
  if ((!firstName || !lastName) && displayName) {
    const [firstName = '', lastName = ''] = displayName.split(' ')
    additionalData.firstName = firstName
    additionalData.lastName = lastName
  }

  const firestore = firebase.firestore()
  return firestore
    .collection(USERS)
    .doc(id)
    .set({
      _createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      _updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      _isUpdated: false,
      _updatedBy: id,
      _id: id,
      email,
      emailVerified: false,
      ...additionalData,
      agreement,
      gdpr,
      avatarUrl,
      role: ROLES.CUSTOMER
    })
    .catch((err) => {
      onError && onError(err)
    })
}

export default saveUserToDB
