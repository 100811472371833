import OWNERSHIPS from './ownerships'

const RENTAL_UNITS = {
  OWN_PROPERTY: 'OWN_PROPERTY',
  PART_OF_MY_HOME: 'PART_OF_MY_HOME',
  RESIDENTIAL: 'RESIDENTIAL',
  COMMERCIAL: 'COMMERCIAL'
}

const RENTAL_UNITS_LABELS = {
  OWN_PROPERTY: 'Own property',
  PART_OF_MY_HOME: 'Part of my home',
  RESIDENTIAL: 'Residential',
  COMMERCIAL: 'Commercial'
}

const prepareOptions = (options) => {
  return options.map((key) => ({
    label: RENTAL_UNITS_LABELS[key],
    value: key
  }))
}

const BASE_TAX = 22

/* 
  If the property will be owned by a company we can add the tax decrease.
  If its a residential property the tax wright off will be 4% of the value spread out over 25 years.
  If its a commercial property the tax wright off will be 2% over 50 years
*/
const RENTAL_UNITS_BASED_TAX = {
  [RENTAL_UNITS.OWN_PROPERTY]: BASE_TAX,
  [RENTAL_UNITS.PART_OF_MY_HOME]: 0,
  [RENTAL_UNITS.RESIDENTIAL]: BASE_TAX,
  [RENTAL_UNITS.COMMERCIAL]: BASE_TAX - 2
}

const RENTAL_UNITS_OPTIONS = {
  [OWNERSHIPS.PERSONAL]: prepareOptions([
    RENTAL_UNITS.OWN_PROPERTY,
    RENTAL_UNITS.PART_OF_MY_HOME
  ]),
  [OWNERSHIPS.COMPANY]: prepareOptions([
    RENTAL_UNITS.RESIDENTIAL,
    RENTAL_UNITS.COMMERCIAL
  ])
}

export default RENTAL_UNITS
export {
  RENTAL_UNITS_OPTIONS,
  RENTAL_UNITS,
  RENTAL_UNITS_LABELS,
  RENTAL_UNITS_BASED_TAX
}
