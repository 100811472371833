import { cloneElement, useRef, useState } from 'react'

import { Box } from '@qonsoll/react-design'
import PropTypes from 'prop-types'
import { useCreatePDFFileFromPage } from 'hooks'
import { useReactToPrint } from 'react-to-print'

const usePrint = (onPDFCreatedCallback, onPDFCreatedCallbackParams) => {
  const [isPrintMode, setIsPrintMode] = useState(false)

  const pdfRef = useRef(null)
  const { download, isLoanSendMode } = useCreatePDFFileFromPage(
    pdfRef,
    onPDFCreatedCallback,
    onPDFCreatedCallbackParams
  )

  const handlePrint = useReactToPrint({
    content: () => pdfRef.current,
    bodyClass: 'root',
    copyStyles: true
  })

  const print = async () => {
    setIsPrintMode(true)
    setTimeout(() => {
      handlePrint()
      setIsPrintMode(false)
    }, 500)
  }

  const PrintWrapper = ({ children }) => {
    if ((!isPrintMode && !isLoanSendMode) || !children) return null

    return (
      <Box position="absolute" width="1080px" zIndex={-100} ref={pdfRef} p={4}>
        {cloneElement(children, { isPrintMode: true })}
      </Box>
    )
  }

  PrintWrapper.propTypes = {
    children: PropTypes.node
  }

  return { print, isPrintMode, isLoanSendMode, download, PrintWrapper }
}

export default usePrint
