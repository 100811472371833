// Recursion
const calculateLoan = (values) => {
  let {
    debt = 0,
    monthlyPayment = 0,
    monthlyRate = 0,
    month = 0,
    annualInterest = 0,
    noPrincipal = false,
    downPayment = 0,
    interestRate = 0
  } = values

  /* beginning balance * rate */
  // const monthlyInterest = noPrincipal ? monthlyPayment : debt * monthlyRate
  const monthlyInterest = monthlyPayment
  /* payment - interest */
  const monthlyInstallment = monthlyPayment - monthlyInterest
  if (month === 0) {
    return {
      debt: debt,
      monthlyPayment,
      monthlyRate,
      monthlyInterest,
      monthlyInstallment,
      annualInterest,
      downPayment,
      interestRate
    }
  }

  /* beginning balance - installment */
  debt -= monthlyInstallment

  if (month < 12) {
    // Annual interest
    annualInterest += monthlyInterest
  }

  return calculateLoan({
    debt,
    monthlyPayment,
    monthlyRate,
    month: month - 1,
    annualInterest,
    noPrincipal,
    downPayment,
    interestRate
  })
}

export default calculateLoan
