import { Box, Button, Img, Text, Title } from '@qonsoll/react-design'

import PropTypes from 'prop-types'
import logo from 'assets/logo/background-logo.svg'
import { useTranslations } from 'contexts/Translation'
import { useUser } from 'domains/User/context'

const GetPROAccess = () => {
  const { t } = useTranslations()
  const { unlockProAccess, isAuthenticated } = useUser()

  return (
    <Box
      p={24}
      overflow="hidden"
      position="relative"
      borderRadius="var(--card-radius)"
      backgroundColor="var(--ql-color-neutral1)">
      <Box position="absolute" top="-40px" right="-24px">
        <Img src={logo} alt="logo" fill="white" />
      </Box>
      <Title color="var(--ql-color-white)" mb={2} level={3}>
        {t('Get PRO')}
      </Title>
      <Box mb={3}>
        <Text color="var(--ql-color-white)">
          {t('Unlock important metrics and new functions')}.
        </Text>
      </Box>
      <Button block type="primary" onClick={unlockProAccess}>
        {isAuthenticated ? t('Get PRO') : t('Sign up')}
      </Button>
    </Box>
  )
}

GetPROAccess.propTypes = {
  collapse: PropTypes.bool
}

export default GetPROAccess
