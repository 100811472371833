import { Form } from 'antd'
import InputPrice from '../InputPrice'
import PropTypes from 'prop-types'

const NewPropertyValueInput = (props) => {
  const { form, group, onChange, ...rest } = props

  const askingPrice = Form.useWatch([...group, 'askingPrice'], form)
  const handleChange = (value) => {
    form.setFieldsValue({ rentalCalculator: { refinance: 0 } })
    onChange(value)
  }

  return <InputPrice onChange={handleChange} min={askingPrice} {...rest} />
}

NewPropertyValueInput.propTypes = {
  group: PropTypes.array,
  form: PropTypes.object,
  onChange: PropTypes.func
}

export default NewPropertyValueInput
