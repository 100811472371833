import { Select, Text } from '@qonsoll/react-design'
import { SelectableItem, SelectableWrapper } from './EnumSelect.styled'

import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'

/**
 * It renders a selectable row if there are less than 4 options, otherwise it renders a select
 * @param value - The current value of the field
 * @param onChange - function that will be called when the value changes
 * @param options - An array of objects with the following shape:
 * @param placeholder - The placeholder text to show when no value is selected
 * @returns A function that returns a component
 */
const EnumSelect = (props) => {
  const {
    value,
    onChange,
    options,
    placeholder,
    disabled,
    onChangeAfter,
    isPrintMode,
    ...rest
  } = props

  /* Getting translations instance */
  const { t } = useTranslations()

  const handleChange = async (value) => {
    onChangeAfter && (await onChangeAfter(value))
    onChange?.(value)
  }

  if (options?.length < 4) {
    return (
      <SelectableWrapper isPrintMode={isPrintMode} disabled={disabled}>
        {options?.map(({ label, value }, index) => (
          <SelectableItem
            isPrintMode={isPrintMode}
            disabled={disabled}
            key={label}
            h="center"
            onClick={disabled ? null : () => handleChange?.(value)}
            selected={value === props.value}
            mr={index === options.length - 1 ? 0 : [0, 2]}
            isLast={index === options.length - 1}>
            <Text style={{ color: 'inherit' }}>{t(label)}</Text>
          </SelectableItem>
        ))}
      </SelectableWrapper>
    )
  }

  return (
    <Select
      disabled={disabled}
      value={value}
      onChange={handleChange}
      placeholder={placeholder}
      options={options?.map((o) => ({ ...o, label: t(o.label) }))}
      {...rest}
    />
  )
}

EnumSelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  onChangeAfter: PropTypes.func,
  isPrintMode: PropTypes.bool
}

export default EnumSelect
