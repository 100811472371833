import { APPLICATION_URL } from '__constants__'
import PATHS from 'pages/paths'
import { fetchApi } from 'services/api/rest'
import { notification } from 'antd'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'
import { useStripe } from '@stripe/react-stripe-js'
import { useTranslations } from 'contexts/Translation'
import { useUser } from 'domains/User/context'

const useBuyProduct = (plan) => {
  const [isLoading, setIsLoading] = useState(false)
  const { user } = useUser()
  const { t } = useTranslations()
  const history = useHistory()
  const stripe = useStripe()

  const buyProduct = async (event) => {
    try {
      setIsLoading(true)

      // create a payment method
      const res = await fetchApi({
        method: 'POST',
        path: 'subscriptions/invoices/' + user?._id,
        body: {
          priceId: plan.priceId
        }
      })

      const isCorrect = res?.data?.invoice?.id
      if (isCorrect) {
        if (res?.data?.invoice?.status !== 'succeeded') {
          // eslint-disable-next-line no-console
          console.log('Confirmation required')

          await stripe.confirmCardPayment(res?.data?.invoice?.clientSecret, {
            payment_method: user?.paymentMethodId,
            return_url: APPLICATION_URL + PATHS.CONFIG.AFTER_PAYMENT
          })
        }

        notification.success({
          message: t('Success'),
          description: t('Product has been successfully bought')
        })
        history.push(PATHS.CONFIG.AFTER_PAYMENT)
      } else throw new Error(res)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
      notification.error({
        message: t('Error'),
        description: t('Something went wrong, please try again later')
      })
    } finally {
      setIsLoading(false)
    }
  }

  return [buyProduct, isLoading]
}

// PAYMENT INTENTS Buy Product function
// const useBuyProduct = (plan) => {
//   const [isLoading, setIsLoading] = useState(false)
//   const { user } = useUser()
//   const { t } = useTranslations()
//   const history = useHistory()
//   const stripe = useStripe()

//   const buyProduct = async (event) => {
//     try {
//       setIsLoading(true)

//       // create a payment method
//       const res = await fetchApi({
//         method: 'POST',
//         path: 'subscriptions/paymentintents/' + user?._id,
//         body: {
//           amount: plan.price,
//           currency: plan.currency,
//           priceId: plan.priceId,
//           metadata: plan.metadata
//         }
//       })

//       const isCorrect = res?.data?.paymentIntent?.id

//       if (isCorrect) {
//         if (res?.data?.paymentIntent?.status !== 'succeeded') {
//           // eslint-disable-next-line no-console
//           console.log('Confirmation required')

//           await stripe.confirmCardPayment(
//             res?.data?.paymentIntent?.clientSecret,
//             {
//               payment_method: user?.paymentMethodId,
//               return_url: APPLICATION_URL + PATHS.CONFIG.AFTER_PAYMENT
//             }
//           )
//         }

//         notification.success({
//           message: t('Success'),
//           description: t('Product has been successfully bought')
//         })
//         history.push(PATHS.CONFIG.AFTER_PAYMENT)
//       } else throw new Error(res)
//     } catch (error) {
//       // eslint-disable-next-line no-console
//       console.log(error)
//       notification.error({
//         message: t('Error'),
//         description: t('Something went wrong, please try again later')
//       })
//     } finally {
//       setIsLoading(false)
//     }
//   }

//   return [buyProduct, isLoading]
// }

export default useBuyProduct
