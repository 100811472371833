import { model, attr } from 'services/model'
import * as yup from 'yup'

const validationSchema = yup.object().shape({
  question: yup
    .string()
    .typeError('Field question has wrong type')
    .default(null)
    .nullable(),
  answer: yup
    .string()
    .typeError('Field answer has wrong type')
    .default(null)
    .nullable()
})

const Help = model(
  'help',
  {
    question: attr('string'),
    answer: attr('string')
  },
  validationSchema
)

export default Help
