import { RENTAL_UNITS_BASED_TAX } from '__constants__/enums'

const calculateTax = (
  value,
  rentalUnit,
  yearlyProfitBeforeTax,
  upfrontRenovation
) => {
  const taxPercent = RENTAL_UNITS_BASED_TAX[rentalUnit]
  const upfrontRenovationValue = Number(upfrontRenovation)
  const tax = upfrontRenovationValue
    ? upfrontRenovationValue < yearlyProfitBeforeTax
      ? value * (taxPercent / 100)
      : 0
    : value * (taxPercent / 100)
  const taxChecked = tax < 0 ? 0 : tax
  const netValue = value - taxChecked

  return {
    tax: taxChecked,
    netValue: netValue
  }
}

export default calculateTax
