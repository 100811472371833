const calculateExpenses = (values) => {
  const askingPrice = Number(values?.askingPrice) || 0
  const downPayment = Number(values?.downPayment?.amount) || 0
  const interestRate = Number(values?.interestRate) || 0
  const monthsFromStartToFinish = Number(values?.monthsFromStartToFinish) || 0
  const electricity = Number(values?.electricity) || 0
  const insurance = Number(values?.insurance) || 0
  const financeRenovation = Number(values?.financeRenovation?.amount) || 0
  const interestRateRenovationCost =
    Number(values?.interestRateRenovationCost) || 0
  const closingCost = Number(values?.closingCost) || 0
  const commonExpenses = Number(values?.commonExpenses) || 0
  const interestMainMortgage = monthsFromStartToFinish
    ? (((askingPrice - downPayment) * interestRate) / 100 / 12) *
      monthsFromStartToFinish
    : 0
  const interestFinancedRenovationCost =
    ((financeRenovation * interestRateRenovationCost) / 100 / 12) *
    monthsFromStartToFinish

  const totalInterest = monthsFromStartToFinish
    ? (((askingPrice - downPayment) * interestRate +
        financeRenovation * interestRateRenovationCost) /
        100 /
        12) *
      monthsFromStartToFinish
    : 0

  const totalCommonCost = electricity + insurance
  const municipalTaxes = monthsFromStartToFinish
    ? ((Number(values?.fixedExpenses) || 0) / 12) * monthsFromStartToFinish
    : 0
  const upfrontRenovation = Number(values?.upfrontRenovation) || 0
  const sellingCosts = Number(values?.sellingFees) || 0
  const rentalIncome = Number(values?.totalRentalIncomeFromStartToFinish) || 0
  const operatingExpenses = monthsFromStartToFinish
    ? ((Number(values?.fixedExpenses) || 0) / 12 +
        commonExpenses +
        totalCommonCost) *
      monthsFromStartToFinish
    : 0

  const totalExpenses =
    Number(totalInterest) +
    Number(operatingExpenses) +
    Number(upfrontRenovation) +
    Number(sellingCosts) -
    Number(rentalIncome) +
    Number(closingCost)

  return {
    totalInterest,
    totalCommonCost,
    municipalTaxes,
    upfrontRenovation,
    sellingCosts,
    rentalIncome,
    totalExpenses,
    closingTaxValue: closingCost,
    operatingExpenses,
    interestMainMortgage,
    interestFinancedRenovationCost
  }
}

export default calculateExpenses
