import { Button, Text, Title } from '@qonsoll/react-design'
import { Modal, Space, notification } from 'antd'

import PropTypes from 'prop-types'
import { fetchApi } from 'services/api/rest'
import { useState } from 'react'
import { useTranslations } from 'contexts/Translation'

const CancelSubscription = ({ isCompletely, subscriptionId }) => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const { t } = useTranslations()

  const hideModal = () => setOpen(false)
  const showModal = () => setOpen(true)

  const unsubscribe = async () => {
    try {
      setLoading(true)
      const res = await fetchApi({
        method: 'DELETE',
        path:
          'subscriptions/' +
          subscriptionId +
          (isCompletely ? '?immediately=true' : '')
      })
      const isSuccess = res?.data?.subscription?.id
      if (isSuccess) {
        notification.success({
          message: t('Success'),
          description: t('You have successfully unsubscribed')
        })
      } else throw new Error(res)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
      notification.error({
        message: t('Error'),
        description: t('Something went wrong, please try again later'),
        duration: 120
      })
    } finally {
      setLoading(false)
      setOpen(false)
    }
  }

  if (!subscriptionId) return null

  return (
    <>
      <Button type="primary" onClick={showModal} danger block>
        {isCompletely ? t('Unsubscribe completely') : t('Cancel subscription')}
      </Button>
      <Modal
        title={t('Confirm unsubscribe')}
        visible={open}
        onOk={unsubscribe}
        onCancel={hideModal}
        okText={t('Unsubscribe')}
        okButtonProps={{ type: 'primary', danger: true, loading: loading }}
        cancelText={t('Cancel')}>
        <Space direction="vertical">
          <Title level={3} mb={3}>
            {t('Are you sure you want to unsubscribe?')}
          </Title>
          <Text>
            {t(
              'If you are sure you want to proceed, click the Unsubscribe button below'
            )}
            .
          </Text>
        </Space>
      </Modal>
    </>
  )
}

CancelSubscription.propTypes = {
  isCompletely: PropTypes.bool,
  subscriptionId: PropTypes.string
}

export default CancelSubscription
