import * as yup from 'yup'

import { attr, model } from 'services/model'

const validationSchema = yup.object().shape({
  projectName: yup
    .string()
    .typeError('Field projectName has wrong type')
    .default(null)
    .nullable(),
  zillowLink: yup
    .string()
    .typeError('Field zillowLink has wrong type')
    .default(null)
    .nullable(),
  ownership: yup
    .string()
    .typeError('Field ownership has wrong type')
    .default(null)
    .nullable(),
  askingPrice: yup
    .string()
    .typeError('Field askingPrice has wrong type')
    .default(null)
    .nullable(),
  rentalIncome: yup
    .string()
    .typeError('Field rentalIncome has wrong type')
    .default(null)
    .nullable(),
  interestRate: yup
    .string()
    .typeError('Field interestRate has wrong type')
    .default(null)
    .nullable(),
  downPayment: yup
    .object()
    .typeError('Field downPayment has wrong type')
    .default(null)
    .nullable(),
  closingCost: yup
    .string()
    .typeError('Field closingCost has wrong type')
    .default(null)
    .nullable(),
  upfrontRenovation: yup
    .string()
    .typeError('Field upfrontRenovation has wrong type')
    .default(null)
    .nullable(),
  loanTerms: yup
    .string()
    .typeError('Field loanTerms has wrong type')
    .default(null)
    .nullable(),
  maintenance: yup
    .object()
    .typeError('Field maintenance has wrong type')
    .default(null)
    .nullable(),
  propertyTax: yup
    .string()
    .typeError('Field propertyTax has wrong type')
    .default(null)
    .nullable(),
  insurance: yup
    .string()
    .typeError('Field insurance has wrong type')
    .default(null)
    .nullable(),
  sewer: yup
    .string()
    .typeError('Field sewer has wrong type')
    .default(null)
    .nullable(),
  management: yup
    .object()
    .typeError('Field management has wrong type')
    .default(null)
    .nullable(),
  vacancy: yup
    .string()
    .typeError('Field vacancy has wrong type')
    .default(null)
    .nullable(),
  communication: yup
    .string()
    .typeError('Field communication has wrong type')
    .default(null)
    .nullable(),
  other: yup
    .string()
    .typeError('Field other has wrong type')
    .default(null)
    .nullable(),
  rentGrowthRate: yup
    .string()
    .typeError('Field rentGrowthRate has wrong type')
    .default(null)
    .nullable(),
  propertyValueGrowthRate: yup
    .string()
    .typeError('Field propertyValueGrowthRate has wrong type')
    .default(null)
    .nullable(),
  numberOfBedrooms: yup
    .string()
    .typeError('Field numberOfBedrooms has wrong type')
    .default(null)
    .nullable(),
  primaryRoom: yup
    .string()
    .typeError('Field primaryRoom has wrong type')
    .default(null)
    .nullable(),
  rentalUnit: yup
    .string()
    .typeError('Field rentalUnit has wrong type')
    .default(null)
    .nullable(),
  picturesOfTheProperty: yup
    .array()
    .typeError('Field picturesOfTheProperty has wrong type')
    .default(null)
    .nullable(),
  notes: yup
    .string()
    .typeError('Field notes has wrong type')
    .default(null)
    .nullable()
})

const RentalCalculator = model(
  'rentalCalculator',
  {
    projectName: attr('string'),
    zillowLink: attr('string'),
    ownership: attr('string'),
    askingPrice: attr('string'),
    rentalIncome: attr('string'),
    interestRate: attr('string'),
    downPayment: attr('string'),
    closingCost: attr('string'),
    upfrontRenovation: attr('string'),
    loanTerms: attr('string'),
    maintenance: attr('string'),
    propertyTax: attr('string'),
    insurance: attr('string'),
    sewer: attr('string'),
    management: attr('string'),
    vacancy: attr('string'),
    communication: attr('string'),
    other: attr('string'),
    rentGrowthRate: attr('string'),
    propertyValueGrowthRate: attr('string'),
    numberOfBedrooms: attr('string'),
    primaryRoom: attr('string'),
    rentalUnit: attr('string'),
    picturesOfTheProperty: attr('string'),
    notes: attr('string')
  },
  validationSchema
)

export default RentalCalculator
