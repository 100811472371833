import { useCallback, useEffect } from 'react'

import { useState } from 'react'

function useParentSize(ref) {
  // Initialize state with undefined width/height
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined
  })
  const getDimensions = useCallback(() => {
    // Set window width/height to state
    setWindowSize({
      width: ref?.current?.parentElement?.offsetWidth,
      height: ref?.current?.parentElement?.offsetHeight
    })

    // Add event listener
    window.addEventListener('resize', () =>
      setWindowSize({
        width: ref?.current?.parentElement?.offsetWidth,
        height: ref?.current?.parentElement?.offsetHeight
      })
    )
  }, [ref])

  useEffect(() => getDimensions(), [getDimensions])

  return windowSize
}
export default useParentSize
