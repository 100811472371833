import { Button } from '@qonsoll/react-design'
import PropTypes from 'prop-types'
import { fetchApi } from 'services/api/rest'
import { notification } from 'antd'
import { useState } from 'react'
import { useTranslations } from 'contexts/Translation'

const RenewSubscription = (props) => {
  const { subscriptionId } = props

  const { t } = useTranslations()

  const [loading, setLoading] = useState(false)

  const renewSubscription = async () => {
    try {
      setLoading(true)
      const res = await fetchApi({
        method: 'POST',
        path: 'subscriptions/' + subscriptionId + '/reactivate'
      })
      const isSuccess = res?.data?.subscription?.id
      if (isSuccess) {
        notification.success({
          message: t('Success'),
          description: t('You have successfully renewed your subscription')
        })
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Button onClick={renewSubscription} block loading={loading}>
      {t('Renew subscription')}
    </Button>
  )
}

RenewSubscription.propTypes = {
  subscriptionId: PropTypes.string
}

export default RenewSubscription
