import calculateTax from '../calculate/calculateTax'

const calculateYearlyProfit = (values) => {
  const {
    propertyValueIncrease = 0,
    isNetProfitPropertyGrowth,
    rentalUnit,
    monthlyIncome,
    monthlyExpenses,
    interest,
    upfrontRenovation,
    currentIndex,
    netProfit
  } = values

  const yearlyProfit = monthlyIncome * 12 - monthlyExpenses * 12 - interest
  const increase = isNetProfitPropertyGrowth ? propertyValueIncrease : 0

  /* Yearly net profits: Rental income - expenses - interest expenses - tax */
  const yearlyProfitBeforeTax = yearlyProfit + increase

  const yearlyNetProfit = calculateTax(
    yearlyProfitBeforeTax,
    rentalUnit,
    currentIndex === 0 ? yearlyProfitBeforeTax : netProfit,
    upfrontRenovation
  ).netValue
  const yearlyNetProfitWithoutIncrease = calculateTax(
    yearlyProfit,
    rentalUnit,
    currentIndex === 0 ? yearlyProfit : netProfit,
    upfrontRenovation
  ).netValue

  return {
    yearlyProfitBeforeTax: yearlyProfitBeforeTax,
    yearlyNetProfit: yearlyNetProfit,
    yearlyNetProfitWithoutIncrease
  }
}

export default calculateYearlyProfit
