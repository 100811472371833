import { Icon } from 'components'
import PropTypes from 'prop-types'
import { Tooltip } from 'antd'
import { memo } from 'react'

const Info = (props) => {
  const {
    title,
    fill = 'var(--ql-color-accent1)',
    infoName = 'InfoCircleOutlined',
    ...rest
  } = props

  return (
    <Tooltip placement="topRight" title={title} {...rest}>
      <Icon name={infoName} fill={fill} />
    </Tooltip>
  )
}

Info.propTypes = {
  title: PropTypes.string,
  fill: PropTypes.string,
  infoName: PropTypes.string
}

export default memo(Info)
