import { Box, Input, PhoneInput } from '@qonsoll/react-design'
import { Form, Grid } from 'antd'

import { AvatarUploader } from 'components'
import { MAX_LENGTH_FIELD } from '__constants__'
import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'

const UserSimpleForm = (props) => {
  const { group } = props

  const { xs } = Grid.useBreakpoint()

  /* Getting translations instance */
  const { t } = useTranslations()

  return (
    <>
      <Box display="flex" justifyContent={xs ? 'center' : null}>
        <Form.Item
          name={[...group, 'avatarUrl']}
          rules={[
            {
              required: false,
              message: t('Please enter your user avatar url!')
            }
          ]}>
          <AvatarUploader size={156} />
        </Form.Item>
      </Box>
      <Form.Item
        label={t('First name')}
        name={[...group, 'firstName']}
        rules={[
          {
            required: true,
            message: t('Please enter your user first name!')
          },
          { max: MAX_LENGTH_FIELD, message: t('user first name is too long!') }
        ]}>
        <Input placeholder={t('Please enter your user first name')} />
      </Form.Item>
      <Form.Item
        label={t('Last name')}
        name={[...group, 'lastName']}
        rules={[
          {
            required: true,
            message: t('Please enter your user last name!')
          },
          { max: MAX_LENGTH_FIELD, message: t('user last name is too long!') }
        ]}>
        <Input placeholder={t('Please enter your user last name')} />
      </Form.Item>
      <Form.Item
        label={t('Email')}
        name={[...group, 'email']}
        rules={[
          {
            required: true,
            message: t('Please enter your user email!')
          },
          { max: MAX_LENGTH_FIELD, message: t('user email is too long!') }
        ]}>
        <Input placeholder={t('Please enter your user email')} />
      </Form.Item>
      <Form.Item
        label={t('Phone')}
        name={[...group, 'phone']}
        rules={[
          {
            required: false,
            message: t('Please enter your user phone!')
          }
        ]}>
        <PhoneInput
          country="no"
          specialLabel=""
          placeholder={t('Please enter your user phone')}
        />
      </Form.Item>
    </>
  )
}

UserSimpleForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  showTitle: PropTypes.bool,
  form: PropTypes.any,
  group: PropTypes.array
}

export default UserSimpleForm
