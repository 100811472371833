import { useEffect, useState } from 'react'

import { COLLECTIONS } from '__constants__'
import firebase from 'firebase/compat/app'
import { onAuthStateChanged } from 'firebase/auth'
import { useHandleError } from 'hooks'

const useInitializeUser = () => {
  /* If the user is logged in, fetch the user's data from Firestore. */
  const [state, setState] = useState({
    user: null,
    isAuthenticated: false,
    loading: true,
    error: null
  })

  /* The above code is a function that takes in a callback function as an argument.
  The callback function is then called with the error as an argument. */
  const handleError = useHandleError()

  useEffect(() => {
    let unsubscribeUserListener = null
    const unsubscribeAuthListener = onAuthStateChanged(
      firebase.auth(),
      (currentAuth) => {
        if (!currentAuth) {
          unsubscribeUserListener?.()
          return setState({
            user: null,
            isAuthenticated: false,
            loading: false,
            error: null
          })
        }
        unsubscribeUserListener = firebase
          .firestore()
          .collection(COLLECTIONS.USERS)
          .doc(currentAuth.uid)
          .onSnapshot({
            next: (snapshot) => {
              if (!snapshot?.exists) {
                return handleError({ message: 'User does not exist' })
              }
              const data = snapshot.data()
              setState({
                user: data,
                isAuthenticated: true,
                loading: false,
                error: null
              })
            },
            error: (error) =>
              setState((prevState) => ({
                ...prevState,
                error
              }))
          })
      },
      (error) =>
        setState((prevState) => ({
          ...prevState,
          error
        }))
    )
    return () => {
      unsubscribeAuthListener?.()
      unsubscribeUserListener?.()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return [state.user, state.isAuthenticated, state.loading, state.error]
}

export default useInitializeUser
