import { Box, Button, Divider, Text } from '@qonsoll/react-design'

import CUSTOMER_STATUSES from '__constants__/enums/customerStatuses'
import { CancelSubscription } from 'domains/Plan/components/UserPlan/components'
import { CheckoutForm } from 'modules/stripe-module/components'
import { Link } from 'react-router-dom'
import { PlanSimpleView } from 'domains/Plan/components'
import PropTypes from 'prop-types'
import { Spin } from 'antd'
import { useBuyPlan } from 'domains/Plan/hooks'
import { useMemo } from 'react'
import { usePlan } from 'domains/Plan/context'
import { useTranslations } from 'contexts/Translation'
import { useUser } from 'domains/User/context'

const PlanCheckoutForm = (props) => {
  const {
    plan,
    subscriptionId,
    invoiceUrl,
    isSubscriptionIncomplete,
    onChangePlan,
    period
  } = props

  const { isSubscribeProcessing } = usePlan()
  const [buySubscription, buyLoading] = useBuyPlan(plan)
  const { user, isTrialUsed } = useUser()
  const { t } = useTranslations()

  const isPaymentMethodActive = useMemo(
    () =>
      !!user.customerId &&
      user.customerStatus === CUSTOMER_STATUSES.ACTIVE &&
      !!user.paymentMethodId,
    [user.customerId, user.customerStatus, user.paymentMethodId]
  )

  if (isSubscribeProcessing)
    return (
      <>
        <Text display="flex" justifyContent="center" alignItems="center">
          <Box mr={2}>
            <Spin />
          </Box>
          {t('Payment confirmation')}...
        </Text>
      </>
    )

  if (!buyLoading && subscriptionId)
    return (
      <Box>
        <Box style={{ display: 'flex', width: '100%', gap: '8px' }}>
          {isSubscriptionIncomplete && (
            <Button block type="primary">
              <Link
                block
                to={{ pathname: invoiceUrl }}
                target="_blank"
                type="primary">
                {t('Complete payment')}
              </Link>
            </Button>
          )}
          <CancelSubscription subscriptionId={subscriptionId} isCompletely />
        </Box>
      </Box>
    )

  return (
    <Box width="inherit">
      <PlanSimpleView
        plan={plan}
        isTrialUsed={isTrialUsed}
        onChange={onChangePlan}
        selectedPeriod={period}
      />
      <Divider my={4} />
      <CheckoutForm
        loading={buyLoading}
        submitText={isTrialUsed ? t('Complete purchase') : t('Start trial')}
        onSubmit={buySubscription}
        isPaymentMethodActive={isPaymentMethodActive}
        paymentMethodId={user.paymentMethodId}
      />
    </Box>
  )
}

PlanCheckoutForm.propTypes = {
  plan: PropTypes.object,
  subscriptionId: PropTypes.string,
  invoiceUrl: PropTypes.string,
  isSubscriptionIncomplete: PropTypes.bool,
  isTrialUsed: PropTypes.bool,
  onChangePlan: PropTypes.func,
  period: PropTypes.string
}

export default PlanCheckoutForm
