import { Box, Divider, Ribbon, Text, Title } from '@qonsoll/react-design'
import { Card, Tooltip } from 'antd'

import { Icon } from 'components'
import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'

const SubscriptionView = (props) => {
  const {
    plan = {},
    subscriptionStatus,
    ticketsLeft,
    isUnlimitedTickets,
    isSubscriptionCancelled,
    subscriptionEndDate
  } = props

  const {
    title,
    price,
    currencySymbol,
    period,
    subtitle,
    description,
    accessibleOptions,
    notAccessibleOptions
  } = plan || {}

  const { t } = useTranslations()

  return (
    <Ribbon
      text={
        isSubscriptionCancelled
          ? 'End: ' + subscriptionEndDate
          : subscriptionStatus
      }
      display={subscriptionStatus ? 'flex' : 'none'}>
      <Card
        selected
        bodyStyle={{ height: '100%' }}
        style={{
          borderColor: 'var(--ql-color-accent1)',
          height: '100%'
        }}>
        <Box
          height="100%"
          justifyContent="space-between"
          display="flex"
          flexDirection="column">
          <Box>
            <Title level={2} textAlign="center">
              {t((title || '') + ' plan')}
            </Title>
            <Title level={2} mb={2} textAlign="center">
              {subtitle}
            </Title>
            <Text textAlign="center" display="block">
              <Text
                color="var(--ql-color-accent1)"
                fontSize={20}
                letterSpacing="2px">
                {price / 100 || null}
                {currencySymbol}
              </Text>
              {period && (
                <Text type="secondary" letterSpacing="2px">
                  /
                </Text>
              )}
              <Text type="secondary">{t(period)}</Text>
            </Text>
            {description && <Divider my={2} />}
            <Tooltip title={t(description)}>
              <Text display="block" fontSize={14}>
                {t(description)}
              </Text>
            </Tooltip>
            <Divider my={2} />
            <Box mb={3}>
              {accessibleOptions
                ?.filter(({ key }) => !['type', 'order'].includes(key))
                ?.map(({ key, value }) => (
                  <Box display="flex" key={key} mb={2} alignItems="center">
                    <Icon
                      mr={2}
                      name="CheckOutlined"
                      fill="var(--ql-typography-text-color-success)"
                    />
                    <Text fontSize={14}>
                      {key === 'tickets' ? t(value + 'ticketsAmount') : null}{' '}
                      {t(key + 'PlanOption')}
                    </Text>
                  </Box>
                ))}
              {notAccessibleOptions?.map(({ key, value }) => (
                <Box display="flex" key={key} mb={2} alignItems="center">
                  <Icon
                    mr={2}
                    name="XCloseOutlined"
                    fill="var(--ql-typography-text-color-danger)"
                  />
                  <Text fontSize={14} delete>
                    {t(key + 'PlanOption')}
                  </Text>
                </Box>
              ))}
            </Box>
            {!isUnlimitedTickets && !isUnlimitedTickets && (
              <>
                <Divider my={2} />
                <Text fontSize={14}>
                  {t('Tickets left')}: {ticketsLeft}
                </Text>
              </>
            )}
          </Box>
        </Box>
      </Card>
    </Ribbon>
  )
}

SubscriptionView.propTypes = {
  plan: PropTypes.object,
  subscriptionStatus: PropTypes.string,
  ticketsLeft: PropTypes.any,
  isUnlimitedTickets: PropTypes.bool,
  isOptions: PropTypes.bool,
  isSubscriptionCancelled: PropTypes.bool,
  subscriptionEndDate: PropTypes.string
}

export default SubscriptionView
