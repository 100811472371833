import { Box, Col, Row, Text, Title } from '@qonsoll/react-design'
import { useHistory, useLocation } from 'react-router-dom'

import { ProductCheckoutForm } from 'domains/Product/components'
import { useGetProduct } from 'domains/Product/hooks'
import { useMemo } from 'react'
import { useTranslations } from 'contexts/Translation'

const ProductCheckout = () => {
  const { t } = useTranslations()
  const location = useLocation()
  const history = useHistory()

  const { productId, priceId } = useMemo(() => {
    const query = new URLSearchParams(location.search)
    return {
      productId: query.get('selectedProduct'),
      priceId: query.get('priceId')
    }
  }, [location])

  const [currentPlan, loading] = useGetProduct(productId, priceId)
  const onChangeProduct = () => history.goBack()

  return (
    <Row h="center" v="center" height="100%">
      <Col cw={[12, 12, 12, 12, 8, 6]}>
        <Box mb={40}>
          <Title textAlign="center" mb={3}>
            {t('Add more tickets')}
          </Title>
          <Text display="block" textAlign="center">
            {t('You will not be charged util you click a purchase')}
          </Text>
        </Box>
        {currentPlan && (
          <ProductCheckoutForm
            onChangeProduct={onChangeProduct}
            plan={currentPlan}
            loading={loading}
          />
        )}
        {loading && (
          <Box>
            <Text display="block" textAlign="center">
              {t('Loading')}...
            </Text>
          </Box>
        )}
      </Col>
    </Row>
  )
}

export default ProductCheckout
