import {
  BILLING_PERIODS,
  BILLING_PERIODS_INTERVAL_VALUES,
  BILLING_PERIODS_VALUES,
  PRODUCT_TYPES,
  PRODUCT_TYPE_PACKAGE
} from '__constants__/enums'
import { Box, Col, Row, Spin, Text, Title } from '@qonsoll/react-design'
import { Grid, Radio, Tooltip } from 'antd'
import { PlanSimpleView, PlanView } from 'domains/Plan/components'
import { useMemo, useState } from 'react'

import PropTypes from 'prop-types'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { capitalize } from 'lodash'
import { useGetPlans } from 'domains/Plan/hooks'
import { useLocation } from 'react-router-dom'
import { useTranslations } from 'contexts/Translation'
import { useUser } from 'domains/User/context'

const PlanSelectPage = (props) => {
  const {
    cw = [12, 12, 6, 4],
    planType,
    wrapperCw = [12, 12, 12, 12, 10, 8],
    mb = 4
  } = props
  const location = useLocation()
  const [selectedPeriod, setSelectedPeriod] = useState(
    BILLING_PERIODS_INTERVAL_VALUES[BILLING_PERIODS.MONTHLY]
  )
  const [selectedPlanType, setSelectedPlanType] = useState(
    planType || PRODUCT_TYPES.RENTAL
  )
  const { subscriptionType } = useMemo(() => {
    const query = new URLSearchParams(location.search)
    return { subscriptionType: query.get('type') }
  }, [location])

  const { t } = useTranslations()
  const { isTrialUsed } = useUser()
  const [plans, loading] = useGetPlans()
  const filteredPlans = useMemo(
    () => plans.filter((plan) => selectedPlanType === plan?.type),
    [selectedPlanType, plans]
  )
  const showPlanSelecting = useMemo(
    () =>
      subscriptionType === planType ||
      subscriptionType === PRODUCT_TYPE_PACKAGE,
    [planType, subscriptionType]
  )

  const { xs, md } = Grid.useBreakpoint()
  return (
    <>
      {showPlanSelecting ? (
        <Row h="center" v="center" height="100%">
          <Col cw={wrapperCw}>
            <Box mb={10}>
              <Text display="block" textAlign="center" strong={true}>
                {t('Please wait!')}
              </Text>
            </Box>
            <Box mb={10}>
              <Text
                display="block"
                textAlign="center"
                variant="body1"
                type="secondary">
                {t('You have bought a plan to access the calculator')}
              </Text>
            </Box>
          </Col>
        </Row>
      ) : (
        <Row
          mx="var(--ql-grid-gutter-negative)"
          h="center"
          v="center"
          height="100%">
          <Col cw={wrapperCw}>
            <Box mb={40}>
              <Title textAlign="center" mb={4}>
                {t('Select plan')}
              </Title>
              {!planType ? (
                <Box
                  mb={3}
                  display="flex"
                  whiteSpace="nowrap"
                  overflowX="auto"
                  justifyContent="center">
                  <Radio.Group
                    size="middle"
                    buttonStyle={xs || !md ? 'solid' : 'outline'}
                    onChange={(val) => setSelectedPlanType(val?.target?.value)}
                    value={selectedPlanType}>
                    {Object.entries({
                      ...PRODUCT_TYPES,
                      [PRODUCT_TYPE_PACKAGE.toUpperCase()]: PRODUCT_TYPE_PACKAGE
                    })?.map(([key, type]) => (
                      <Radio.Button
                        style={{ padding: '4px 8px' }}
                        key={key}
                        value={type}>
                        {t(capitalize(type))}
                        {type === PRODUCT_TYPE_PACKAGE ? (
                          <Tooltip title={t('You will save up to 16%')}>
                            <QuestionCircleOutlined
                              style={{ marginLeft: '2px' }}
                            />
                          </Tooltip>
                        ) : null}
                      </Radio.Button>
                    ))}
                  </Radio.Group>
                </Box>
              ) : null}
              <Text display="block" textAlign="center">
                {t('Choose the payment plan thats right for you')}
              </Text>
              {!loading ? (
                <Box mt={2} display="flex" justifyContent="center">
                  <Radio.Group
                    size="middle"
                    buttonStyle={xs || !md ? 'solid' : 'outline'}
                    onChange={(val) => setSelectedPeriod(val?.target?.value)}
                    value={selectedPeriod}>
                    {Object.entries(BILLING_PERIODS_VALUES)?.map(
                      ([key, period]) => (
                        <Radio.Button
                          style={{ padding: '4px 8px' }}
                          key={key}
                          value={BILLING_PERIODS_INTERVAL_VALUES[key]}>
                          {t(period)}
                        </Radio.Button>
                      )
                    )}
                  </Radio.Group>
                </Box>
              ) : null}
            </Box>
            {loading && (
              <Box justifyContent="center" display="flex">
                <Spin />
                <Text ml={2}>{t('Loading')}...</Text>
              </Box>
            )}
            <Row mx="var(--ql-grid-gutter-negative)" h="center">
              {filteredPlans?.map((plan) => (
                <Col key={plan._id} mb={mb} cw={cw} height="inherit">
                  {xs || !md ? (
                    <PlanSimpleView
                      plan={plan}
                      selectedPeriod={selectedPeriod}
                      isTrialUsed={isTrialUsed}
                    />
                  ) : (
                    <PlanView
                      selectedPeriod={selectedPeriod}
                      isTrialUsed={isTrialUsed}
                      plan={plan}
                    />
                  )}
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      )}
    </>
  )
}

PlanSelectPage.propTypes = {
  cw: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  wrapperCw: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  mb: PropTypes.any,
  planType: PropTypes.string
}

export default PlanSelectPage
