import getDocument from './getDocument'

/**
 * Get a document from a collection by id, and return true if it exists, false otherwise.
 * @param collection - The name of the collection you want to check.
 * @param _id - The id of the document you want to get.
 * @returns A boolean value
 */
const getIsDocumentExists = async (collection, _id) => {
  const document = await getDocument(collection, _id)
  return !!document?._id
}

export default getIsDocumentExists
