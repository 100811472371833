const USERS = 'users'
const LOGS = 'logs'
const RENTAL_CALCULATORS = 'rentalCalculators'
const HELPS = 'helps'
const SUBSCRIPTIONS = 'subscriptions'
const FLIPPING_CALCULATORS = 'flippingCalculators'

export const COLLECTIONS = {
  USERS,
  LOGS,
  RENTAL_CALCULATORS,
  HELPS,
  SUBSCRIPTIONS,
  FLIPPING_CALCULATORS
}
export default COLLECTIONS
