import { useEffect, useState } from 'react'

import { fetchApi } from 'services/api/rest'
import { transformProduct } from 'domains/Product/helpers'

/**
 * It returns an array of values that are used to render the plans
 * @param props - An object that contains the query parameters.
 * @returns An array of values.
 */
const useGetProducts = (props) => {
  const [products, setPlans] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const retrieveProducts = async () => {
      try {
        const res = await fetchApi({
          method: 'GET',
          path: 'subscriptions/products'
        })
        const products = res?.data?.products

        const fetchedProducts = products
          ?.filter((i) => !Object.keys(i.recurring).length)
          ?.map((product) => transformProduct(product))

        setPlans(fetchedProducts)
      } catch (err) {
        setError(err)
      } finally {
        setLoading(false)
      }
    }

    retrieveProducts()
  }, [])

  return [products, loading, error]
}

export default useGetProducts
