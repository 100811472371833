import { useHistory, useLocation } from 'react-router-dom'

import { ArrowLeftOutlined } from '@ant-design/icons'
import { Button } from '@qonsoll/react-design'
import PATHS from 'pages/paths'
import firebase from 'firebase/compat/app'
import { useBackButtonVisibility } from './hooks'

const Back = () => {
  const history = useHistory()
  const isButtonVisible = useBackButtonVisibility()
  const location = useLocation()

  const goBack = () => {
    if (location.pathname === PATHS.UNAUTHENTICATED.CONFIRM_EMAIL) {
      firebase.auth().signOut()
      history.push(PATHS.AUTHENTICATED.RENTAL_CALCULATOR)
    } else history.goBack()
  }

  return (
    <>
      {isButtonVisible ? (
        <Button
          type="text"
          shape="circle"
          icon={<ArrowLeftOutlined />}
          onClick={goBack}
        />
      ) : null}
    </>
  )
}

export default Back
