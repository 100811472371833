import { Checkbox } from 'antd'
import PropTypes from 'prop-types'

const ClosingCostTaxCheckbox = (props) => {
  const { group, form, checked, onChange, isAccessRestricted } = props

  const onCheckboxChange = (event) => {
    const prevClosingCost = form.getFieldValue([group, 'closingCost']) || 0
    const askingPrice = form.getFieldValue([group, 'askingPrice']) || 0
    const closingCostChecked = event?.target?.checked ?? false

    const askingPriceTax = Number(askingPrice) * 0.025
    // If checkbox is checked, we need to add asking price tax to closing cost
    // If checkbox is unchecked, we need to remove asking price tax from closing cost
    const newValue = closingCostChecked
      ? Number(prevClosingCost) + askingPriceTax
      : Number(prevClosingCost) - askingPriceTax
    const newValueNormalized = Number(newValue)?.toFixed(0)

    form.setFieldsValue({ [group]: { closingCost: newValueNormalized } })
    onChange(event?.target?.checked)
  }

  return (
    <Checkbox
      checked={checked}
      onChange={onCheckboxChange}
      disabled={isAccessRestricted}
    />
  )
}

ClosingCostTaxCheckbox.propTypes = {
  group: PropTypes.array,
  form: PropTypes.object,
  isAccessRestricted: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func
}

export default ClosingCostTaxCheckbox
