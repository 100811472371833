import { Checkbox, Form, Space } from 'antd'

import { Info } from 'components'
import { InputPrice } from 'domains/RentalCalculator/components/RentalCalculatorSimpleForm/components'
import PropTypes from 'prop-types'
import { Text } from '@qonsoll/react-design'
import { useTranslations } from 'contexts/Translation'

const ClosingCostTaxCheckbox = (props) => {
  const { group, form, checked, onChange, isAccessRestricted } = props
  const onCheckboxChange = (event) => {
    const prevClosingCost = form.getFieldValue([group, 'closingCost'])
    const askingPrice = form.getFieldValue([group, 'askingPrice'])
    form.setFieldsValue({
      [group]: {
        closingCost: event?.target?.checked
          ? Number(prevClosingCost) + Number(askingPrice) * 0.025
          : prevClosingCost - askingPrice * 0.025
      }
    })
    onChange(event?.target?.checked)
  }

  return (
    <Checkbox
      checked={checked}
      onChange={onCheckboxChange}
      disabled={isAccessRestricted}
    />
  )
}

ClosingCostTaxCheckbox.propTypes = {
  group: PropTypes.array,
  form: PropTypes.object,
  isAccessRestricted: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func
}

const ClosingCostInput = (props) => {
  const { group, form, value, onChange, isAccessRestricted, xs, isPrintMode } =
    props

  const { t } = useTranslations()

  return (
    <InputPrice
      value={value}
      onChange={onChange}
      addonAfter={
        <Space>
          <Form.Item
            style={{ margin: 0 }}
            name={[...group, 'closingTax']}
            valuePropName="checked">
            <ClosingCostTaxCheckbox
              group={group}
              form={form}
              isAccessRestricted={isAccessRestricted}
            />
          </Form.Item>
          <Text disabled={isAccessRestricted} whiteSpace="nowrap">
            2.5% {xs || isPrintMode ? null : t('tax')}
          </Text>
          <Info title={t('Property closing fee')} />
        </Space>
      }
      disabled={isAccessRestricted}
      placeholder={t('Placeholder')}
    />
  )
}

ClosingCostInput.propTypes = {
  group: PropTypes.array,
  form: PropTypes.object,
  isAccessRestricted: PropTypes.bool,
  xs: PropTypes.bool,
  isPrintMode: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func
}

export default ClosingCostInput
