import { CardDropdown, SelectableCard } from 'components'

import { FlippingCalculatorView } from './components'
import PropTypes from 'prop-types'
import { useFlippingCalculatorBaseActions } from 'domains/FlippingCalculator/hooks'
import { useState } from 'react'

const FlippingCalculatorSimpleView = (props) => {
  const { flippingCalculator, index, isSelected } = props

  const [isHovered, setIsHovered] = useState(false)

  /* Getting actions from useSimpleFormActions hook */
  const actions = useFlippingCalculatorBaseActions({
    flippingCalculatorId: flippingCalculator?._id
  })
  const { onEdit, onDelete } = actions

  return (
    <CardDropdown
      document={flippingCalculator}
      index={index}
      data-testid="flippingCalculatorListItem">
      <SelectableCard
        isSelected={isSelected}
        hoverable
        onClick={onEdit}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}>
        <FlippingCalculatorView
          flippingCalculator={flippingCalculator}
          isHovered={isHovered}
          onDelete={onDelete}
          onEdit={onEdit}
        />
      </SelectableCard>
    </CardDropdown>
  )
}

FlippingCalculatorSimpleView.propTypes = {
  flippingCalculator: PropTypes.object,
  onSelect: PropTypes.func,
  index: PropTypes.number,
  isSelected: PropTypes.bool,
  actions: PropTypes.object
}

export default FlippingCalculatorSimpleView
