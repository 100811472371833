import {
  AskingPriceInput,
  FormItemInfo,
  InputAmountWithPercent,
  InputPercent,
  InputPrice,
  LoanTermsInput,
  NewPropertyValueInput,
  RefinanceInput,
  RoomInput,
  UrlInput
} from './components'
import { EnumSelect, ImageUploader } from 'components'
import { Form, Grid, Input } from 'antd'
import {
  LOAN_TERMS_OPTIONS,
  OWNERSHIPS,
  OWNERSHIPS_OPTIONS,
  PROPERTY_TYPES_OPTIONS,
  PROPERTY_TYPES_WITH_FEE,
  RENTAL_UNITS_OPTIONS
} from '__constants__/enums'

import { INTEREST_RATE_PERCENTS_RENTAL } from '__constants__/calculations/interestRatePercents'
import PropTypes from 'prop-types'
import { TextArea } from '@qonsoll/react-design'
import { handleAfterChangeOwnership } from 'domains/RentalCalculator/components/RentalCalculatorSimpleForm/helpers'
import { useState } from 'react'
import { useTranslations } from 'contexts/Translation'

const RentalCalculatorSimpleForm = (props) => {
  const {
    onSubmit,
    group,
    isAccessRestricted,
    form,
    initialOwnership,
    isPrintMode
  } = props

  const [ownership, setOwnership] = useState(
    initialOwnership || OWNERSHIPS.PERSONAL
  )

  /* Getting translations instance */
  const { t } = useTranslations()
  const { xs } = Grid.useBreakpoint()

  const handleAfterChangePropertyType = (propertyType) => {
    const closingTaxChecked = form?.getFieldValue([...group, 'closingTax'])
    const prevClosingCost = form?.getFieldValue([...group, 'closingCost']) || 0
    const prevAskingPrice = form.getFieldValue([group, 'askingPrice']) || 0

    const isClosingTaxChecked = PROPERTY_TYPES_WITH_FEE.includes(propertyType)

    // If closing tax is not changed, we don't need to change it
    if (closingTaxChecked === isClosingTaxChecked) return

    // If closing tax is changed, we need to calculate new closing cost
    const newClosingCost = isClosingTaxChecked
      ? Number(prevClosingCost) + Number(prevAskingPrice) * 0.025
      : Number(prevClosingCost) - Number(prevAskingPrice) * 0.025

    const newValueNormalized = Number(newClosingCost)?.toFixed(0)

    form.setFieldsValue({
      [group]: {
        closingTax: isClosingTaxChecked,
        closingCost: newValueNormalized
      }
    })
  }

  const handleRenovationCostChange = (value) => {
    const upfrontRenovationCost = Number(value)
    const financeRenovation = form.getFieldValue([group, 'financeRenovation'])
    if (financeRenovation?.percent !== 0)
      form.setFieldsValue({
        [group]: {
          financeRenovation: {
            amount: Number(
              Number(
                (upfrontRenovationCost * Number(financeRenovation?.percent)) /
                  100
              ).toFixed(2)
            ),
            percent: financeRenovation?.percent
          }
        }
      })
  }

  return (
    <>
      <Form.Item
        label={t('Project name')}
        name={[...group, 'projectName']}
        rules={[
          {
            required: true,
            message: t('Please enter project name!')
          }
        ]}>
        <Input
          disabled={isAccessRestricted}
          placeholder={t('Forrest cottage, Hotel apartments')}
          onPressEnter={onSubmit}
        />
      </Form.Item>
      <Form.Item
        label={
          <FormItemInfo
            title={t('Zillow URL')}
            info={t('zillowUrlFieldInfo')}
          />
        }
        name={[...group, 'zillowLink']}
        rules={[
          {
            required: false,
            message: t('Please enter zillow url!')
          }
        ]}>
        <UrlInput
          calculatorType="rentalCalculator"
          disabled={isAccessRestricted}
          onOwnershipChange={setOwnership}
          placeholder="https://"
          form={form}
        />
      </Form.Item>
      <Form.Item
        label={
          <FormItemInfo title={t('Ownership')} info={t('ownershipFieldInfo')} />
        }
        name={[...group, 'ownership']}>
        <EnumSelect
          isPrintMode={isPrintMode}
          onChangeAfter={(ownershipValue) =>
            handleAfterChangeOwnership({
              ownership: ownershipValue,
              setOwnership,
              interestRateConfig: INTEREST_RATE_PERCENTS_RENTAL,
              form,
              group,
              handleAfterChangePropertyType
            })
          }
          disabled={isAccessRestricted}
          options={OWNERSHIPS_OPTIONS}
        />
      </Form.Item>
      <Form.Item
        name={[...group, 'propertyType']}
        label={
          <FormItemInfo
            title={t('Property type')}
            disabled={isAccessRestricted}
            info={
              ownership === OWNERSHIPS.COMPANY
                ? t('companyPropertyTypeFieldInfo')
                : t('propertyTypeFieldInfo')
            }
          />
        }>
        <EnumSelect
          isPrintMode={isPrintMode}
          disabled={isAccessRestricted}
          onChangeAfter={handleAfterChangePropertyType}
          options={PROPERTY_TYPES_OPTIONS[ownership]}
        />
      </Form.Item>
      <Form.Item
        label={
          <FormItemInfo
            title={t('Unit type')}
            info={
              ownership === OWNERSHIPS.COMPANY
                ? t('companyUnitTypeFieldInfo')
                : t('unitTypeFieldInfo')
            }
          />
        }
        name={[...group, 'rentalUnit']}>
        <EnumSelect
          isPrintMode={isPrintMode}
          disabled={isAccessRestricted}
          options={RENTAL_UNITS_OPTIONS[ownership]}
        />
      </Form.Item>
      <Form.Item
        label={
          <FormItemInfo
            title={t('Asking price')}
            info={t('askingPriceFieldInfo')}
          />
        }
        name={[...group, 'askingPrice']}
        rules={[
          {
            required: true,
            message: t('Please enter asking price!')
          }
        ]}>
        <AskingPriceInput
          form={form}
          group={group}
          isPrintMode={isPrintMode}
          disabled={isAccessRestricted}
          placeholder={t('Placeholder')}
        />
      </Form.Item>
      <Form.Item
        label={
          <FormItemInfo
            title={t('Rental income')}
            info={t('rentalIncomeFieldInfo')}
          />
        }
        name={[...group, 'rentalIncome']}>
        <InputPrice
          disabled={isAccessRestricted}
          placeholder={t('Placeholder')}
        />
      </Form.Item>
      <Form.Item
        label={
          <FormItemInfo
            title={t('Interest rate')}
            info={t('interestRateFieldInfo')}
          />
        }
        name={[...group, 'interestRate']}>
        <InputPercent
          max={30}
          step={0.05}
          form={form}
          disabled={isAccessRestricted}
          placeholder={t('Placeholder')}
        />
      </Form.Item>
      <Form.Item
        label={
          <FormItemInfo
            title={t('Down payment')}
            info={t('downPaymentFieldInfo')}
          />
        }
        name={[...group, 'downPayment']}>
        <InputAmountWithPercent
          disabled={isAccessRestricted}
          placeholder={t('Placeholder')}
          targetField="askingPrice"
          group={group}
          form={form}
          max={100}
        />
      </Form.Item>
      <Form.Item
        label={
          <FormItemInfo
            title={t('Upfront renovation')}
            info={t('upfrontRenovationFieldInfo')}
          />
        }
        name={[...group, 'upfrontRenovation']}>
        <InputPrice
          onBeforeChange={handleRenovationCostChange}
          disabled={isAccessRestricted}
          placeholder={t('Placeholder')}
        />
      </Form.Item>
      <Form.Item
        label={
          <FormItemInfo
            title={t('Finance the renovation')}
            info={t('financeTheRenovationFieldInfo')}
          />
        }
        name={[...group, 'financeRenovation']}>
        <InputAmountWithPercent
          disabled={isAccessRestricted}
          placeholder={t('Placeholder')}
          targetField="upfrontRenovation"
          group={group}
          form={form}
          max={100}
        />
      </Form.Item>
      <Form.Item
        label={
          <FormItemInfo
            title={t('New property value')}
            info={t('newPropertyValueFieldInfo')}
          />
        }
        name={[...group, 'newPropertyValue']}>
        <NewPropertyValueInput
          disabled={isAccessRestricted}
          placeholder={t('Placeholder')}
          group={group}
          form={form}
        />
      </Form.Item>
      <Form.Item
        name={[...group, 'refinance']}
        label={
          <FormItemInfo title={t('Refinance')} info={t('refinanceFieldInfo')} />
        }>
        <RefinanceInput
          disabled={isAccessRestricted}
          placeholder={t('Placeholder')}
          group={group}
        />
      </Form.Item>
      <Form.Item
        label={
          <FormItemInfo
            title={t('Closing cost')}
            info={t('closingCostFieldInfo')}
          />
        }
        name={[...group, 'closingCost']}>
        <InputPrice
          disabled={isAccessRestricted}
          placeholder={t('Placeholder')}
        />
      </Form.Item>
      <Form.Item
        label={
          <FormItemInfo
            title={t('Loan terms')}
            info={t('loanTermsFieldInfo')}
          />
        }
        name={[...group, 'loanTerms']}>
        <LoanTermsInput
          placeholder={t('Placeholder')}
          disabled={isAccessRestricted}
          options={LOAN_TERMS_OPTIONS}
          group={group}
        />
      </Form.Item>
      <Form.Item
        label={
          <FormItemInfo
            title={`${t('Fixed expenses')} (${t('year')})`}
            info={t('fixedExpensesFieldInfo')}
          />
        }
        name={[...group, 'fixedExpenses']}>
        <InputPrice
          disabled={isAccessRestricted}
          placeholder={t('Placeholder')}
        />
      </Form.Item>
      <Form.Item
        label={
          <FormItemInfo
            title={`${t('Common expenses')} (${t('month')})`}
            info={t('commonExpensesFieldInfo')}
          />
        }
        name={[...group, 'commonExpenses']}>
        <InputPrice
          disabled={isAccessRestricted}
          placeholder={t('Placeholder')}
        />
      </Form.Item>
      <Form.Item
        label={
          <FormItemInfo
            title={`${t('Property tax')} (${t('year')})`}
            info={t('propertyTaxFieldInfo')}
          />
        }
        name={[...group, 'propertyTax']}>
        <InputPrice
          disabled={isAccessRestricted}
          placeholder={t('Placeholder')}
        />
      </Form.Item>
      <Form.Item
        label={
          <FormItemInfo
            title={`${t('Sewer')} (${t('month')})`}
            info={t('sewerFieldInfo')}
          />
        }
        name={[...group, 'sewer']}>
        <InputPrice
          disabled={isAccessRestricted}
          placeholder={t('Placeholder')}
        />
      </Form.Item>
      <Form.Item
        label={
          <FormItemInfo
            title={`${t('Insurance')} (${t('month')})`}
            info={t('insuranceFieldInfo')}
          />
        }
        name={[...group, 'insurance']}>
        <InputPrice
          disabled={isAccessRestricted}
          placeholder={t('Placeholder')}
        />
      </Form.Item>
      <Form.Item
        label={
          <FormItemInfo
            title={`${t('WIFI')}/${t('cable')} (${t('month')})`}
            info={t('communicationFieldInfo')}
          />
        }
        name={[...group, 'communication']}>
        <InputPrice
          disabled={isAccessRestricted}
          placeholder={t('Placeholder')}
        />
      </Form.Item>
      <Form.Item
        label={
          <FormItemInfo
            title={`${t('Electricity')} (${t('month')})`}
            info={t('electricityFieldInfo')}
          />
        }
        name={[...group, 'electricity']}>
        <InputPrice
          disabled={isAccessRestricted}
          placeholder={t('Placeholder')}
        />
      </Form.Item>
      <Form.Item
        label={
          <FormItemInfo
            title={`${t('Other')} (${t('month')})`}
            info={t('otherFieldInfo')}
          />
        }
        name={[...group, 'other']}>
        <InputPrice
          disabled={isAccessRestricted}
          placeholder={t('Placeholder')}
        />
      </Form.Item>
      <Form.Item
        label={
          <FormItemInfo
            title={t('Maintenance')}
            info={t('maintenanceFieldInfo')}
          />
        }
        name={[...group, 'maintenance']}>
        <InputAmountWithPercent
          disabled={isAccessRestricted}
          placeholder={t('Placeholder')}
          targetField="rentalIncome"
          group={group}
          form={form}
        />
      </Form.Item>
      <Form.Item
        label={
          <FormItemInfo
            title={t('Management')}
            info={t('managementFieldInfo')}
          />
        }
        name={[...group, 'management']}>
        <InputAmountWithPercent
          disabled={isAccessRestricted}
          placeholder={t('Placeholder')}
          targetField="rentalIncome"
          group={group}
          form={form}
        />
      </Form.Item>
      <Form.Item
        label={
          <FormItemInfo title={t('Vacancy')} info={t('vacancyFieldInfo')} />
        }
        name={[...group, 'vacancy']}>
        <InputPercent
          disabled={isAccessRestricted}
          placeholder={t('Placeholder')}
        />
      </Form.Item>
      <Form.Item
        label={
          <FormItemInfo
            title={t('Rent and expenses growth rate')}
            info={t('rentExpensesGrowthRateFieldInfo')}
          />
        }
        name={[...group, 'rentGrowthRate']}>
        <InputPercent
          disabled={isAccessRestricted}
          placeholder={t('Placeholder')}
          step={0.5}
        />
      </Form.Item>
      <Form.Item
        label={
          <FormItemInfo
            title={t('Property value growth rate')}
            info={t('propertyValueGrowthRateFieldInfo')}
          />
        }
        name={[...group, 'propertyValueGrowthRate']}>
        <InputPercent
          disabled={isAccessRestricted}
          placeholder={t('Placeholder')}
        />
      </Form.Item>
      <Form.Item
        label={t('Number of bedrooms')}
        name={[...group, 'numberOfBedrooms']}
        rules={[
          {
            required: false,
            message: t('Please enter number of bedrooms!')
          }
        ]}>
        <Input
          min={0}
          type="number"
          disabled={isAccessRestricted}
          placeholder={t('Placeholder')}
        />
      </Form.Item>
      <Form.Item
        label={t('Primary room')}
        name={[...group, 'primaryRoom']}
        rules={[
          {
            required: false,
            message: t('Please enter primary room!')
          }
        ]}>
        <RoomInput
          disabled={isAccessRestricted}
          placeholder={t('Placeholder')}
        />
      </Form.Item>
      <Form.Item
        label={t('Property photos')}
        style={{ flexFlow: xs ? null : 'column' }}
        name={[...group, 'picturesOfTheProperty']}>
        <ImageUploader
          disabled={isAccessRestricted}
          placeholder={t('Please enter picture of the property')}
        />
      </Form.Item>
      <Form.Item
        onKeyPress={(e) => e.key === 'Enter' && e.stopPropagation()}
        label={t('Notes')}
        name={[...group, 'notes']}
        rules={[
          {
            required: false,
            message: t('Please enter notes!')
          }
        ]}>
        <TextArea
          rows={4}
          disabled={isAccessRestricted}
          placeholder={t('Enter notes here') + '...'}
        />
      </Form.Item>
    </>
  )
}

RentalCalculatorSimpleForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  showTitle: PropTypes.bool,
  form: PropTypes.any,
  group: PropTypes.array,
  isAccessRestricted: PropTypes.bool,
  initialOwnership: PropTypes.string,
  isPrintMode: PropTypes.bool,
  formValues: PropTypes.object
}

export default RentalCalculatorSimpleForm
