import { Box, Title } from '@qonsoll/react-design'

import PropTypes from 'prop-types'
import { SummaryItem } from 'domains/RentalCalculator/components/RentalCalculatorSummary/components'
import { useCurrency } from 'contexts/Currency'
import { useMemo } from 'react'
import { useTranslations } from 'contexts/Translation'

const FlippingCalculatorResultsMortgage = (props) => {
  const {
    measurementSymbol,
    value = {},
    // propertyValues = [],
    mortgages = [],
    isLocked
  } = props

  const { t } = useTranslations()

  const { percentSymbol } = useCurrency()

  const selected = useMemo(() => {
    const mortgage = mortgages[0] || {}
    // const propertyValue = propertyValues[yearNumber]
    const {
      debt,
      monthlyInterest
      //  monthlyInstallment
    } = mortgage
    // const loanToValueRatio = (debt / propertyValue) * 100

    return {
      ...value,
      debt,
      interest: monthlyInterest,
      // installment: monthlyInstallment,
      ...mortgage
      // loanToValueRatio,
      // propertyValue
    }
  }, [
    mortgages,
    value
    // , propertyValues
  ])

  return (
    <>
      <Box mb={4} display="flex" alignItems="center" justifyContent="center">
        <Title level={3}>{t('Mortgage')}</Title>
      </Box>
      {/* <SummaryItem
        measurementSymbol={measurementSymbol}
        value={selected?.propertyValue}
        label={t('Property value')}
        info={t('propertyValueInfo')}
        locked={isLocked}
      />
      <SummaryItem
        measurementSymbol={percentSymbol}
        value={selected.loanToValueRatio}
        label={t('Loan to value ratio')}
        info={t('loanToValueRatioInfo')}
        locked={isLocked}
      /> */}
      <SummaryItem
        measurementSymbol={measurementSymbol}
        value={selected.downPayment}
        label={t('Down payment')}
        info={t('downPaymentInfo')}
        locked={isLocked}
      />
      <SummaryItem
        measurementSymbol={measurementSymbol}
        value={selected.debt}
        label={t('Total debt')}
        info={t('debtInfo')}
        locked={isLocked}
      />
      <Box
        mb={3}
        mt={5}
        display="flex"
        alignItems="center"
        justifyContent="center">
        <Title level={4}>{t('Main mortgage')}</Title>
      </Box>
      <SummaryItem
        measurementSymbol={measurementSymbol}
        value={selected.mainMortgageDebt}
        label={t('Debt')}
        info={t('debtInfo')}
        locked={isLocked}
      />
      <SummaryItem
        roundTo={3}
        measurementSymbol={percentSymbol}
        value={selected.mainMortgageInterestRate}
        label={t('Interest rate')}
        info={t('interestRateInfo')}
        locked={isLocked}
      />
      <SummaryItem
        roundTo={2}
        measurementSymbol={measurementSymbol}
        value={selected.interestMainMortgage}
        label={t('Interest from start to finish')}
        info={t('TotalInterestFromStartToFinishMainMortgage')}
        locked={isLocked}
      />
      <Box
        mb={3}
        mt={5}
        display="flex"
        alignItems="center"
        justifyContent="center">
        <Title level={4}>{t('Financed renovation cost')}</Title>
      </Box>
      <SummaryItem
        measurementSymbol={measurementSymbol}
        value={selected.financeRenovationDebt}
        label={t('Debt')}
        info={t('debtInfo')}
        locked={isLocked}
      />
      <SummaryItem
        roundTo={2}
        measurementSymbol={percentSymbol}
        value={selected.financedRenovationCostRate}
        label={t('Interest rate')}
        info={t('interestRateInfo')}
        locked={isLocked}
      />
      <SummaryItem
        roundTo={2}
        measurementSymbol={measurementSymbol}
        value={selected.interestFinancedRenovationCost}
        label={t('Interest from start to finish')}
        info={t('TotalInterestFromStartToFinishFinancedRenovationCost')}
        locked={isLocked}
      />
      <Box mt={4}>
        <SummaryItem
          roundTo={2}
          measurementSymbol={measurementSymbol}
          value={selected.totalInterestFromStartToFinish}
          strong
          label={t('Total interest from start to finish')}
          info={t('TotalInterestFromStartToFinishFinancedRenovationCost')}
          locked={isLocked}
        />
      </Box>
      {/* <SummaryItem
        measurementSymbol={measurementSymbol}
        value={selected.monthlyPayment}
        label={t('Monthly payment')}
        info={t('monthlyPaymentInfo')}
        locked={isLocked}
      />
      <SummaryItem
        measurementSymbol={measurementSymbol}
        value={selected.interest}
        label={t('Interest')}
        info={t('interestInfo')}
        locked={isLocked}
      /> */}
      {/* <SummaryItem
        measurementSymbol={measurementSymbol}
        value={selected.installment}
        label={t('Installment')}
        info={t('installmentInfo')}
        locked={isLocked}
      /> */}
    </>
  )
}

FlippingCalculatorResultsMortgage.propTypes = {
  value: PropTypes.object,
  measurementSymbol: PropTypes.string,
  isLocked: PropTypes.bool,
  years: PropTypes.number,
  isPrintMode: PropTypes.bool,
  propertyValues: PropTypes.array,
  mortgages: PropTypes.array
}

export default FlippingCalculatorResultsMortgage
