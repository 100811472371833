import { Form, Grid } from 'antd'
import { memo, useCallback } from 'react'

import { CombinedForm } from './components'
import { KEYS } from '__constants__'
import PropTypes from 'prop-types'
import { calculateExpenses } from './helpers'

const RentalCalculatorAdvancedForm = (props) => {
  const { form, isAccessGranted, initialData, isPrintMode, onFinish, withId } =
    props

  const { xs } = Grid.useBreakpoint()

  /* Handling enter key press */
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      form.submit()
    }
  }

  const handleChange = useCallback(
    (e) => {
      const formFields = form.getFieldsValue()?.rentalCalculator
      const calculations = calculateExpenses(formFields, isAccessGranted)
      form.setFieldsValue({ calculations })
    },
    [isAccessGranted, form]
  )

  return (
    <Form
      id={withId ? 'divToSaveAsPDF' : null}
      onValuesChange={handleChange}
      onKeyPress={handleKeyPress}
      onFinish={onFinish}
      layout={xs ? 'horizontal' : 'vertical'}
      autoComplete="off"
      form={form}>
      <CombinedForm
        form={form}
        isAccessGranted={isAccessGranted}
        initialData={initialData}
        isPrintMode={isPrintMode}
      />
    </Form>
  )
}

RentalCalculatorAdvancedForm.propTypes = {
  initialData: PropTypes.object,
  group: PropTypes.array,
  showTitle: PropTypes.bool,
  isAccessGranted: PropTypes.bool,
  form: PropTypes.object,
  _id: PropTypes.string,
  isPrintMode: PropTypes.bool,
  onFinish: PropTypes.func,
  withId: PropTypes.bool
}

export default memo(RentalCalculatorAdvancedForm)
