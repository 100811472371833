import { Col, Row } from '@qonsoll/react-design'
import { Input, Tooltip, notification } from 'antd'

import { Icon } from '@qonsoll/icons'
import { LANGUAGES } from '../../__constants__'
import PropTypes from 'prop-types'
import firebase from 'firebase/compat/app'
import { useState } from 'react'
import { useTranslations } from 'contexts/Translation'

const LocalizationItem = (props) => {
  // Props destructuring
  const {
    data = {},
    appName,
    placeholder = '',
    withTooltips = false,
    triggerTranslationsRefetch
  } = props

  // Hooks
  const { t, saveTranslationForLanguage } = useTranslations()

  // States
  const [labels, setLabels] = useState(
    Object.fromEntries(LANGUAGES.map((l) => [l.shortCode, data[l.shortCode]]))
  )

  // Functions
  const save = (shortCode, value) => {
    /**
     * It shows a success notification, clears the inputs if the creation is enabled, and triggers the
     * translations data source update
     */
    const handleSuccessOperation = () => {
      // showing success in-app notification
      notification.success({
        message: t('Success'),
        description: `"${value}" ${t('label was saved to the')} ${appName}`
      })

      // triggering of translations data source update
      triggerTranslationsRefetch?.()
    }
    /**
     * It takes an error object as an argument, logs the error to the console, and shows an error
     * notification to the user
     * @param error - The error object returned by the API
     */
    const handleError = (error) => {
      // showing error in-app notification
      notification.error({
        message: t('Error'),
        description: `${t('error')}`
      })
    }

    const updateTranslation = ({ textLabel, shortCode, refEnding }) => {
      /* Creating a reference to the database. */
      const ref = `translations/${appName}/${shortCode}/${refEnding}`
      return firebase.database().ref(ref).set(textLabel)
    }
    const update = saveTranslationForLanguage || updateTranslation

    // update operation
    update({
      textLabel: value,
      refEnding: data.original,
      shortCode: shortCode
    })
      .then(handleSuccessOperation)
      .catch(handleError)
  }

  // Template
  return (
    <Row mb={2} noOuterGutters>
      {LANGUAGES.map((lang) => (
        <Col key={lang.shortCode}>
          <Input
            value={labels?.[lang.shortCode]}
            placeholder={placeholder}
            onChange={(e) =>
              setLabels((prev) => ({
                ...prev,
                [lang.shortCode]: e.target.value
              }))
            }
            onPressEnter={() => save(lang.shortCode, labels?.[lang.shortCode])}
            suffix={
              withTooltips ? (
                <Tooltip
                  title={t(
                    'You can add new translation by filling this input and pressing "Enter"'
                  )}>
                  <Icon
                    name="AlertCircleFilled"
                    cursor="help"
                    fill="var(--ql-color-accent1)"
                  />
                </Tooltip>
              ) : null
            }
          />
        </Col>
      ))}
      {/* <Col>
        <Input
          value={enLabel}
          placeholder={enPlaceholder}
          onChange={(e) => setEnLabel(e.target.value)}
          onPressEnter={() => save('en', enLabel)}
          suffix={
            withTooltips ? (
              <Tooltip
                title={t(
                  'You can add new translation by filling this input and pressing "Enter"'
                )}>
                <Icon
                  name="AlertCircleFilled"
                  cursor="help"
                  fill="var(--ql-color-accent1)"
                />
              </Tooltip>
            ) : null
          }
        />
      </Col>
      <Col>
        <Input
          value={noLabel}
          placeholder={noPlaceholder}
          onChange={(e) => setNoLabel(e.target.value)}
          onPressEnter={() => save('no', noLabel)}
          suffix={
            withTooltips ? (
              <Tooltip
                title={t(
                  'You can add new translation by filling this input and pressing "Enter"'
                )}>
                <Icon
                  name="AlertCircleFilled"
                  cursor="help"
                  fill="var(--ql-color-accent1)"
                />
              </Tooltip>
            ) : null
          }
        />
      </Col> */}
    </Row>
  )
}

LocalizationItem.propTypes = {
  data: PropTypes.object,
  appName: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  withTooltips: PropTypes.bool,
  triggerTranslationsRefetch: PropTypes.func
}

export default LocalizationItem
