import { Box } from '@qonsoll/react-design'
import styled from 'styled-components'

export const SelectableItem = styled(Box)`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0px 16px 0px 16px;
  height: 40px;
  border-radius: var(--input-border-radius-base);
  background: transparent;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  ${(props) =>
    props.disabled
      ? `color: var(--input-disabled-color); background-color: var(--input-disabled-bg);`
      : ''}
  ${(props) =>
    props.selected &&
    `
      background: #fff !important;
      ${!props.isPrintMode && 'box-shadow: 0px 4px 16px 0px #0B13282E;'}
    `}
`
export const SelectableWrapper = styled(Box)`
  padding: 4px;
  border-radius: var(--input-border-radius-base);
  background-color: ${(props) =>
    props.isPrintMode
      ? 'var(--ql-color-neutral1-t-lighten6)'
      : 'var(--ql-color-neutral1-t-lighten7)'};
  display: flex;
`
