import { Col, Row, Text } from '@qonsoll/react-design'

import { HelpSimpleView } from 'domains/Help/components'
import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'

const HelpList = (props) => {
  const { helps, emptyProps } = props

  /* Getting translations instance */
  const { t } = useTranslations()

  /* Checking if list is empty */
  const isEmpty = !helps?.length

  return (
    <Row mx="var(--ql-grid-gutter-negative)">
      {!isEmpty && (
        <Col cw={12}>
          {helps?.map((help, index) => (
            <HelpSimpleView
              isLast={index === helps.length - 1}
              key={help?._id}
              {...help}
            />
          ))}
        </Col>
      )}
      {isEmpty && (
        <Col cw={12}>
          <Text variant="overline" type="secondary">
            {emptyProps?.message || t('No helps have been created yet')}
          </Text>
        </Col>
      )}
    </Row>
  )
}

HelpList.propTypes = {
  helps: PropTypes.array,
  isListWithCreate: PropTypes.bool,
  listView: PropTypes.bool,
  emptyProps: PropTypes.object,
  actions: PropTypes.any,
  cw: PropTypes.any
}

export default HelpList
