import { Box } from '@qonsoll/react-design'
import { LanguageSelect } from 'modules/admin-module/domains/Translation/components'
import Logo from '../Logo'
import MobileMenu from '../MobileMenu'
import PATHS from 'pages/paths'
import PropTypes from 'prop-types'
import { StyledContainer } from '../../BoilerplateLayout.styled'
import { useHistory } from 'react-router-dom'

const MobileLayout = (props) => {
  const { children, ...rest } = props

  const history = useHistory()

  const goHome = () => history.push(PATHS.AUTHENTICATED.RENTAL_CALCULATOR)

  const h = '56px'

  return (
    <Box
      height="100% !important"
      width="100% !important"
      overflow="hidden !important"
      position="static !important"
      {...rest}>
      <Box
        bg="#fff"
        position="absolute"
        width="inherit"
        height={h}
        top={0}
        zIndex={1}
        display="flex"
        borderBottom="1px solid var(--ql-color-dark-t-lighten5)"
        alignItems="center">
        <Box
          px="var(--ql-content-px-xxs)"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="inherit">
          <Box onClick={goHome} display="flex" maxWidth="100px">
            <Logo />
          </Box>
          <Box minWidth={48}>
            <LanguageSelect collapse isMobile />
          </Box>
        </Box>
      </Box>
      <StyledContainer
        isMobile
        position="absolute"
        width="inherit"
        height="unset !important"
        bottom={h}
        top={h}>
        {children}
      </StyledContainer>
      <Box
        position="absolute"
        width="inherit"
        height={h}
        bottom={1}
        zIndex={2000}
        bg="#fff"
        borderTop="1px solid var(--ql-color-dark-t-lighten5)">
        <MobileMenu height={h} />
      </Box>
    </Box>
  )
}

MobileLayout.propTypes = {
  children: PropTypes.node
}

export default MobileLayout
