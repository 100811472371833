import { Input, Typography } from 'antd'

import { INPUT_NUMBER_REGEX } from '__constants__/regExps'
import PropTypes from 'prop-types'
import { memo } from 'react'

const RoomInput = (props) => {
  const { value, onChange, disabled, ...rest } = props

  const handleChange = (e) => {
    const value = e.target.value?.replace(INPUT_NUMBER_REGEX, '')
    if (value < 0) return onChange(0)
    onChange(value)
  }

  const addon = (
    <Typography.Text disabled={disabled}>
      m<sup>2</sup>
    </Typography.Text>
  )

  return (
    <Input
      min={0}
      value={value}
      addonAfter={addon}
      onChange={handleChange}
      disabled={disabled}
      {...rest}
    />
  )
}

RoomInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool
}

export default memo(RoomInput)
