import { Box, Text } from '@qonsoll/react-design'
import { memo, useMemo } from 'react'

import InputNumber from '../InputNumber'
import PropTypes from 'prop-types'
import { StyledSuffix } from './InputPrice.styled'
import { getTextWidth } from 'get-text-width'
import { useCurrency } from 'contexts/Currency'

const InputPrice = (props) => {
  const {
    inputPriceRef,
    value,
    onChange,
    onBeforeChange,
    disabled,
    additionalSuffix = false,
    additionalSuffixValue,
    mainSuffixSymbol,
    id,
    ...rest
  } = props

  const suffixSymbol = useMemo(
    () =>
      `kr ${
        additionalSuffixValue
          ? Number(value / additionalSuffixValue).toFixed(1)
          : value
      },-/m2`,
    [additionalSuffixValue, value]
  )

  const onInputValueChange = (value) => {
    onBeforeChange?.(value)
    onChange?.(value)
  }

  return (
    <Box position="relative" overflow="hidden">
      <InputNumber
        ref={inputPriceRef}
        id={id}
        value={value}
        disabled={disabled}
        onChange={onInputValueChange}
        {...rest}
      />
      <SuffixMemoized
        value={value}
        disabled={disabled}
        suffixSymbol={mainSuffixSymbol}
      />
      {additionalSuffix ? (
        <SuffixMemoized
          suffixSymbol={suffixSymbol}
          value={value}
          disabled={disabled}
          right="25px"
        />
      ) : null}
    </Box>
  )
}

export const SuffixMemoized = memo(function Suffix(props) {
  const { disabled, value, suffixSymbol, right } = props
  const { symbol } = useCurrency()

  const textWidth = useMemo(() => {
    return Math.ceil(getTextWidth(value)) * 1.95 + 24
  }, [value])

  return (
    (value || value === 0) && (
      <StyledSuffix
        disabled={disabled}
        style={right ? { right } : { left: textWidth }}>
        <Text
          color={
            disabled
              ? 'var(--input-disabled-color)'
              : 'var(--ql-typography-text-color-secondary)'
          }>
          {suffixSymbol || symbol}
        </Text>
      </StyledSuffix>
    )
  )
})

SuffixMemoized.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  suffixSymbol: PropTypes.string,
  disabled: PropTypes.bool,
  right: PropTypes.string
}

InputPrice.propTypes = {
  inputPriceRef: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  additionalSuffix: PropTypes.bool,
  additionalSuffixValue: PropTypes.number,
  mainSuffixSymbol: PropTypes.string,
  onBeforeChange: PropTypes.func
}

export default memo(InputPrice)
