import {
  FlippingCalculator,
  FlippingCalculatorEdit
} from './FlippingCalculator'
import { PlanCheckout, PlanSelect } from './Plan'
import { ProductCheckout, ProductSelect } from './Product'
import { RentalCalculator, RentalCalculatorEdit } from './RentalCalculator'

import { HelpsAll } from './Help'
import PATHS from '../paths'
import { ProjectsAll } from './Project'
import { UserEdit } from './User'

const {
  HELPS_ALL,
  PROJECTS_ALL,
  RENTAL_CALCULATOR,
  FLIPPING_CALCULATOR,
  FLIPPING_CALCULATOR_EDIT,
  RENTAL_CALCULATOR_EDIT,
  USER_EDIT,
  PlAN_CHECKOUT,
  PRODUCT_CHECKOUT
} = PATHS.AUTHENTICATED

const MAIN_ROUTES = [
  {
    key: 'USER_EDIT',
    path: USER_EDIT,
    component: UserEdit,
    exact: true
  },
  {
    key: 'RENTAL_CALCULATOR',
    path: RENTAL_CALCULATOR,
    component: RentalCalculator,
    exact: true
  },
  {
    key: 'FLIPPING_CALCULATOR',
    path: FLIPPING_CALCULATOR,
    component: FlippingCalculator,
    exact: true
  },
  {
    key: 'FLIPPING_CALCULATOR_EDIT',
    path: FLIPPING_CALCULATOR_EDIT,
    component: FlippingCalculatorEdit,
    exact: true
  },
  {
    key: 'RENTAL_CALCULATOR_EDIT',
    path: RENTAL_CALCULATOR_EDIT,
    component: RentalCalculatorEdit,
    exact: true
  },
  {
    key: 'PROJECTS_ALL',
    path: PROJECTS_ALL,
    component: ProjectsAll,
    exact: true
  },
  {
    key: 'HELPS_ALL',
    path: HELPS_ALL,
    component: HelpsAll,
    exact: true
  },
  {
    key: 'PLAN_SELECTION',
    path: PATHS.AUTHENTICATED.PLAN_SELECTION,
    component: PlanSelect,
    exact: true
  },
  {
    key: 'PlAN_CHECKOUT',
    path: PlAN_CHECKOUT,
    component: PlanCheckout
  },
  {
    key: 'PRODUCT_SELECTION',
    path: PATHS.AUTHENTICATED.PRODUCT_SELECTION,
    component: ProductSelect,
    exact: true
  },
  {
    key: 'PRODUCT_CHECKOUT',
    path: PRODUCT_CHECKOUT,
    component: ProductCheckout
  }
]

const SETTINGS_ROUTES = []

const STATISTICS_ROUTES = []

export { MAIN_ROUTES, SETTINGS_ROUTES, STATISTICS_ROUTES }
export default MAIN_ROUTES
