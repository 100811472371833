import { CurrencyProvider } from 'contexts/Currency'
import { GDPRPopup } from './domains/App/components'
import Navigator from './pages/Navigator'
import { PlanProvider } from 'domains/Plan/context'
import { StripeProvider } from 'modules/stripe-module/contexts/Stripe'
import { ThemeProvider } from 'styled-components'
import { TranslationsProvider } from 'contexts/Translation'
import breakpoints from 'styles/app/breakpoints'

/**
 * It returns a React component that renders a TranslationsProvider and a Navigator.
 * @returns The <TranslationsProvider> component.
 */
function App() {
  return (
    <ThemeProvider theme={breakpoints}>
      <TranslationsProvider>
        <StripeProvider>
          <PlanProvider>
            <CurrencyProvider>
              <Navigator />
              <GDPRPopup />
            </CurrencyProvider>
          </PlanProvider>
        </StripeProvider>
      </TranslationsProvider>
    </ThemeProvider>
  )
}

export default App
