import { PRODUCT_TYPES, PRODUCT_TYPE_PACKAGE } from '__constants__/enums'

import PlanContext from './PlanContext'
import PropTypes from 'prop-types'
import useListenSubscriptionWithAdditionalData from './useListenSubscriptionWithAdditionalData'
import { useUser } from 'domains/User/context'

const PlanProvider = ({ children }) => {
  const { user, isTrialUsed: isRentalTrialUsed } = useUser()

  const rentalSubscriptionData = useListenSubscriptionWithAdditionalData(
    user?.subscriptions?.[PRODUCT_TYPES.RENTAL],
    isRentalTrialUsed
  )

  const flippingSubscriptionData = useListenSubscriptionWithAdditionalData(
    user?.subscriptions?.[PRODUCT_TYPES.FLIPPING],
    isRentalTrialUsed
  )

  const packageSubscriptionData = useListenSubscriptionWithAdditionalData(
    user?.subscriptions?.[PRODUCT_TYPE_PACKAGE],
    isRentalTrialUsed
  )

  return (
    <PlanContext.Provider
      value={{
        [PRODUCT_TYPES.RENTAL]: rentalSubscriptionData,
        [PRODUCT_TYPES.FLIPPING]: flippingSubscriptionData,
        [PRODUCT_TYPE_PACKAGE]: packageSubscriptionData,
        isTrialUsed: isRentalTrialUsed
      }}>
      {children}
    </PlanContext.Provider>
  )
}

PlanProvider.propTypes = { children: PropTypes.node }

export default PlanProvider
