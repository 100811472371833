import { Grid } from 'antd'
import { Icon } from '@qonsoll/icons'
import PATHS from 'pages/paths'
import { useHistory } from 'react-router-dom'
import { useMemo } from 'react'
import { useTranslations } from 'contexts/Translation'
import { useUser } from 'domains/User/context'

const useMenuItems = () => {
  const { t } = useTranslations()
  const history = useHistory()
  const { isAuthenticated } = useUser()
  const { xs } = Grid.useBreakpoint()

  const menuItems = useMemo(
    () => [
      {
        key: PATHS.AUTHENTICATED.RENTAL_CALCULATOR,
        icon: <Icon name="CalculatorOutlined" />,
        text: t('Rental Calculator'),
        mobileText: t('Calculator'),
        onClick: () => history.push(PATHS.AUTHENTICATED.RENTAL_CALCULATOR),
        order: 0
      },
      {
        key: PATHS.AUTHENTICATED.FLIPPING_CALCULATOR,
        icon: <Icon name="CalculatorOutlined" />,
        text: t('Flipping Calculator'),
        mobileText: t('Flipping'),
        onClick: () => history.push(PATHS.AUTHENTICATED.FLIPPING_CALCULATOR),
        order: 1
      },
      {
        key: PATHS.AUTHENTICATED.PROJECTS_ALL,
        icon: <Icon name="Building6Outlined" />,
        text: t('My Projects'),
        mobileText: t('Projects'),
        onClick: () => history.push(PATHS.AUTHENTICATED.PROJECTS_ALL),
        order: 2
      },
      {
        key: PATHS.AUTHENTICATED.USER_EDIT,
        icon: <Icon name="User1Outlined" />,
        text: t('My Account'),
        mobileText: t('Account'),
        onClick: () => history.push(PATHS.AUTHENTICATED.USER_EDIT),
        hidden: !isAuthenticated,
        order: xs ? 4 : 3
      },
      {
        key: '/login',
        icon: <Icon name="LogIn1Outlined" />,
        text: t('Login'),
        mobileText: t('Login'),
        onClick: () => history.push(PATHS.UNAUTHENTICATED.LOGIN),
        hidden: isAuthenticated,
        order: 5
      },
      {
        key: PATHS.AUTHENTICATED.HELPS_ALL,
        icon: <Icon name="LifeBuoy2Outlined" />,
        text: t('Help'),
        mobileText: t('Help'),
        onClick: () => history.push(PATHS.AUTHENTICATED.HELPS_ALL),
        order: xs ? 3 : 4
      }
    ],
    [t, history, isAuthenticated, xs]
  )

  const availableMenuItems = useMemo(
    () =>
      menuItems
        .filter((item) => !item.hidden)
        ?.sort((a, b) => a.order - b.order),
    [menuItems]
  )

  return availableMenuItems
}

export default useMenuItems
