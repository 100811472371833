const calculateOperatingExpenses = (values, isProAccess) => {
  const insurance = Number(values?.insurance) || 0
  const sewer = Number(values?.sewer) || 0
  const communication = Number(values?.communication) || 0
  const other = Number(values?.other) || 0
  const fixedExpenses = Number(values?.fixedExpenses) || 0
  const maintenance = Number(values?.maintenance?.amount) || 0
  const management = Number(values?.management?.amount) || 0
  const commonExpenses = Number(values?.commonExpenses) || 0
  const electricity = Number(values?.electricity) || 0
  const propertyTax = Number(values?.propertyTax) || 0
  const totalExpenses = Number(values?.totalExpenses) || 0

  /* Maintenance, Insurance, Sewer, Management, WIFI/cable, Other, FixedExpenses, Electricity */
  const monthlyExpenses =
    maintenance +
    insurance +
    sewer +
    management +
    communication +
    other +
    commonExpenses +
    electricity +
    fixedExpenses / 12 +
    propertyTax / 12 +
    totalExpenses

  return monthlyExpenses || 0
}

export default calculateOperatingExpenses
