import { Select } from 'antd'
import styled from 'styled-components'

export const StyledAddon = styled.div`
  z-index: -1;
  display: flex;
  align-items: center;
  height: 48px;
  font-variant: var(--ql-typography-font-variant-default);
  font-feature-settings: var(--ql-typography-font-feature-settings-default);
  width: 100%;
  min-width: 0;
  padding: 0 11px;
  color: ${({ disabled }) =>
    disabled
      ? 'var(--ql-state-disabled-color) !important'
      : 'var(--input-color)'};
  font-size: var(--ql-typography-font-size-md);
  line-height: var(--ql-typography-line-height-md);
  background-color: ${({ disabled }) =>
    disabled ? 'var(--input-disabled-bg)' : 'var(--input-bg)'};
  border: var(--ql-border-width-default) var(--ql-border-style-default)
    var(--input-border-color);
  border-radius: var(--input-border-radius-base);
  transition: all var(--ql-animation-duration-slow);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`

export const StyledSelect = styled(Select)`
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: var(--input-hover-border-color);
  }
  .ant-select-selector {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    height: 48px !important;
  }
`
