import { OWNERSHIPS } from '__constants__/enums'

export const INTEREST_RATE_PERCENTS_FLIPPING = {
  [OWNERSHIPS.PERSONAL]: 5.5,
  [OWNERSHIPS.COMPANY]: 7
}

export const INTEREST_RATE_PERCENTS_RENTAL = {
  [OWNERSHIPS.PERSONAL]: 5.5,
  [OWNERSHIPS.COMPANY]: 7
}

export default INTEREST_RATE_PERCENTS_FLIPPING
