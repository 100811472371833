import { Box } from '@qonsoll/react-design'
import { Info } from 'components'
import PropTypes from 'prop-types'

const FormItemInfo = (props) => {
  const { info, title, ...rest } = props

  return (
    <>
      {title}
      {info ? (
        <Box ml={1}>
          <Info
            fill="var(--ql-typography-text-color-secondary)"
            infoName="HelpCircleOutlined"
            title={info}
            placement="top"
            {...rest}
          />
        </Box>
      ) : null}
    </>
  )
}

FormItemInfo.propTypes = {
  info: PropTypes.string,
  title: PropTypes.string
}

export default FormItemInfo
