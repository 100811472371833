import { Box, Button, Text } from '@qonsoll/react-design'

import { Info } from 'components'
import PATHS from 'pages/paths'
import PropTypes from 'prop-types'
import { Spin } from 'antd'
import { memo } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslations } from 'contexts/Translation'

const Addon = (props) => {
  const { loading, isTicketsLeft, tickets } = props
  const { t } = useTranslations()
  const history = useHistory()

  if (loading)
    return (
      <Box pt={1}>
        <Spin />
      </Box>
    )
  if (!isTicketsLeft)
    return (
      <Button
        size="small"
        type="primary"
        onClick={() => history.push(PATHS.AUTHENTICATED.PRODUCT_SELECTION)}>
        {t('Buy tickets')}
      </Button>
    )
  return (
    <Text display="flex" alignItems="center">
      <Box mr={2}>
        <Info placement="top" title={t('ticketsLeftInfo')} />
      </Box>
      {t('Left')} : {tickets}
    </Text>
  )
}

Addon.propTypes = {
  loading: PropTypes.bool,
  isTicketsLeft: PropTypes.bool,
  tickets: PropTypes.number
}

export default memo(Addon)
