import { OWNERSHIPS, PROPERTY_TYPES, RENTAL_UNITS } from '__constants__/enums'

import { DOWN_PAYMENT_PERCENTS } from '__constants__'
import { calculateDownPayment } from 'domains/RentalCalculator/components/RentalCalculatorSimpleForm/helpers'

const handleAfterChangeOwnership = async ({
  downPaymentConfig = DOWN_PAYMENT_PERCENTS,
  ownership,
  form,
  group,
  interestRateConfig,
  handleAfterChangePropertyType,
  setOwnership
}) => {
  const downPayment = form.getFieldValue([...group, 'downPayment'])
  const askingPrice = form.getFieldValue([...group, 'askingPrice']) || 0
  const { percent } = downPayment || {}
  // Updating down payment
  if (
    (percent === downPaymentConfig[OWNERSHIPS.PERSONAL] &&
      ownership === OWNERSHIPS.COMPANY) ||
    (percent === downPaymentConfig[OWNERSHIPS.COMPANY] &&
      ownership === OWNERSHIPS.PERSONAL)
  ) {
    const newPercent = downPaymentConfig[ownership]
    const newAmount = calculateDownPayment(newPercent, askingPrice)
    form.setFieldsValue({
      [group]: {
        downPayment: {
          percent: newPercent,
          amount: Number(newAmount) || null
        }
      }
    })
  }

  //Updating interest rate
  if (interestRateConfig)
    form.setFieldsValue({
      [group]: {
        interestRate: interestRateConfig[ownership],
        interestRateRenovationCost: interestRateConfig[ownership]
      }
    })

  // Updating property type
  if (ownership === OWNERSHIPS.COMPANY) {
    const newPropertyType = PROPERTY_TYPES.BUY_PROPERTY
    handleAfterChangePropertyType(newPropertyType)
    await form.setFieldsValue({ [group]: { propertyType: newPropertyType } })
  } else {
    const newPropertyType = PROPERTY_TYPES.FREEHOLD
    handleAfterChangePropertyType(newPropertyType)
    await form.setFieldsValue({ [group]: { propertyType: newPropertyType } })
  }

  // Updating rental unit
  if (ownership === OWNERSHIPS.COMPANY) {
    const newUnitType = RENTAL_UNITS.RESIDENTIAL
    await form.setFieldsValue({ [group]: { rentalUnit: newUnitType } })
  } else {
    const newUnitType = RENTAL_UNITS.OWN_PROPERTY
    await form.setFieldsValue({ [group]: { rentalUnit: newUnitType } })
  }

  setOwnership(ownership)
}

export default handleAfterChangeOwnership
