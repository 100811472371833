const calculatePropertyValueGrowth = (values) => {
  const { propertyValueGrowthRate, propertyValue, year } = values

  /* Vn = V * (1 + r / 100) */
  const increaseRate = Math.pow(1 + propertyValueGrowthRate / 100, year)
  const propertyValueIncreased = propertyValue * increaseRate
  const propertyValueIncrease = propertyValueIncreased - propertyValue

  return { propertyValueIncrease, propertyValueIncreased }
}

export default calculatePropertyValueGrowth
