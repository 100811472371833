import { Box } from '@qonsoll/react-design'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledBox = styled(Box)`
  padding-vertical: var(--ql-content-py);
  background-color: var(--ql-color-white);
  overflow-y: auto;
`

export const StyledContainer = (props) => {
  const { children, collapse, isMobile, ...rest } = props

  return (
    <StyledBox
      py="var(--ql-content-py)"
      bg="var(--ql-color-white)"
      px={[
        'var(--ql-content-px-xxs)',
        'var(--ql-content-px-xs)',
        'var(--ql-content-px-sm)',
        'var(--ql-content-px-md)',
        'var(--ql-content-px-lg)',
        'var(--ql-content-px-xl)',
        'var(--ql-content-px-xxl)',
        'var(--ql-content-px-xxxl)'
      ]}
      width={
        isMobile
          ? '100vw'
          : `calc(100vw - ${
              collapse
                ? 'var(--ql-aside-collapsed-width)'
                : 'var(--ql-aside-width)'
            })`
      }
      height="100vh"
      {...rest}>
      {children}
    </StyledBox>
  )
}

StyledContainer.propTypes = {
  collapse: PropTypes.bool,
  children: PropTypes.node,
  isMobile: PropTypes.bool
}
