import { Button, Col, Row } from '@qonsoll/react-design'
import {
  useActionsFlippingCalculatorAdvancedForm,
  useInitializeFlippingCalculatorAdvancedForm
} from 'domains/FlippingCalculator/components/FlippingCalculatorAdvancedForm/hooks'

import { COLLECTIONS } from '__constants__'
import { CalculatorPopover } from 'domains/RentalCalculator/components'
import { FlippingCalculatorAdvancedForm } from 'domains/FlippingCalculator/components'
import { PRODUCT_TYPES } from '__constants__/enums'
import { PageLayout } from 'components'
import { getId } from 'services/api/firebase'
import { useMemo } from 'react'
import { usePrint } from 'hooks'
import { useTranslations } from 'contexts/Translation'
import { useUser } from 'domains/User/context'

const RentalCalculatorPage = () => {
  const { isFlippingProAccess, isNewFlippingCustomer } = useUser()
  const { t } = useTranslations()

  /* Getting form instance from hook */
  const { form } = useInitializeFlippingCalculatorAdvancedForm({
    isProAccess: isFlippingProAccess
  })
  const flippingCalculatorId = useMemo(
    () => getId(COLLECTIONS.FLIPPING_CALCULATORS),
    []
  )

  const { print, isPrintMode, PrintWrapper } = usePrint()

  /* Getting loading state and actions from hook */
  const { onFinish } = useActionsFlippingCalculatorAdvancedForm({
    flippingCalculatorId: flippingCalculatorId,
    form
  })

  const onRedirectButtonClick = () =>
    window.open(process.env.REACT_APP_FLIPPELAN_LINK)

  return (
    <PageLayout
      title={t('Flipping calculator')}
      isBlur={isNewFlippingCustomer}
      popover={
        <CalculatorPopover
          type={PRODUCT_TYPES.FLIPPING}
          isNewCustomer={isNewFlippingCustomer}
        />
      }
      subTitle={t(
        'Follow the instructions bellow to get results in shortest possible time'
      )}
      actions={
        <Row mx="var(--ql-grid-gutter-negative)">
          <Col mb={[2, 0]}>
            <Button
              block
              type="primary"
              disabled={!isFlippingProAccess}
              onClick={onRedirectButtonClick}>
              {t('Søk om Flippelån')}
            </Button>
          </Col>
          <Col mb={[2, 0]}>
            <Button
              block
              disabled={!isFlippingProAccess}
              onClick={print}
              loading={isPrintMode}>
              {t('Export to PDF')}
            </Button>
          </Col>
          <Col>
            <Button
              block
              type="primary"
              disabled={!isFlippingProAccess}
              onClick={() => form.submit()}>
              {t('Save project')}
            </Button>
          </Col>
        </Row>
      }>
      <FlippingCalculatorAdvancedForm
        isAccessGranted={isFlippingProAccess}
        onFinish={onFinish}
        form={form}
      />
      <PrintWrapper>
        <FlippingCalculatorAdvancedForm
          withId
          isAccessGranted={isFlippingProAccess}
          onFinish={onFinish}
          form={form}
        />
      </PrintWrapper>
    </PageLayout>
  )
}

export default RentalCalculatorPage
