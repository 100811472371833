import {
  Box,
  Button,
  Card,
  Col,
  Divider,
  Row,
  Spin,
  Text,
  Title
} from '@qonsoll/react-design'

import { Icon } from 'components'
import PATHS from 'pages/paths'
import PropTypes from 'prop-types'
import { Tooltip } from 'antd'
import { useGetProducts } from 'domains/Product/hooks'
import { useHistory } from 'react-router-dom'
import { useTranslations } from 'contexts/Translation'

const ProductSelectPage = () => {
  const { t } = useTranslations()
  const [Products, loading] = useGetProducts()

  return (
    <Row
      mx="var(--ql-grid-gutter-negative)"
      h="center"
      v="center"
      height="100%">
      <Col cw={[12, 12, 12, 12, 8, 6]}>
        <Box mb={40}>
          <Title textAlign="center" mb={3}>
            {t('Select Product')}
          </Title>
          <Text display="block" textAlign="center">
            {t('Choose the payment Product thats right for you')}
          </Text>
        </Box>
        {loading && (
          <Box justifyContent="center" display="flex">
            <Spin />
            <Text ml={2}>{t('Loading')} ...</Text>
          </Box>
        )}
        <Row mx="var(--ql-grid-gutter-negative)" h="center">
          {Products?.map((Product) => (
            <ProductsList Product={Product} key={Product._id} />
          ))}
        </Row>
      </Col>
    </Row>
  )
}

const ProductsList = ({ Product }) => {
  const {
    _id,
    title,
    price,
    currencySymbol,
    period,
    subtitle,
    description,
    accessibleOptions,
    notAccessibleOptions,
    priceId
  } = Product
  const { t } = useTranslations()
  const history = useHistory()
  const onContinue = (id) =>
    history.push(
      `${PATHS.AUTHENTICATED.PRODUCT_CHECKOUT}?selectedProduct=${id}&priceId=${priceId}`
    )

  const isOptions = accessibleOptions?.length || notAccessibleOptions?.length

  return (
    <Col key={subtitle} mb={4} cw={[12, 12, 6]} height="inherit">
      <Card
        selected
        bodyStyle={{ height: '100%' }}
        style={{
          borderColor: 'var(--ql-color-accent1)',
          height: '100%'
        }}>
        <Box
          height="100%"
          justifyContent="space-between"
          display="flex"
          flexDirection="column">
          <Box>
            <Title level={2} textAlign="center">
              {t(title + ' Product')}
            </Title>
            <Title level={2} mb={2} textAlign="center">
              {subtitle}
            </Title>
            <Text textAlign="center" display="block">
              <Text
                color="var(--ql-color-accent1)"
                fontSize={20}
                letterSpacing="2px">
                {price / 100}
                {currencySymbol}
              </Text>
              {period && (
                <Text type="secondary" letterSpacing="2px">
                  /
                </Text>
              )}
              <Text type="secondary">{t(period)}</Text>
            </Text>
            {description && <Divider my={2} />}
            <Tooltip title={t(description)}>
              <Text display="block" fontSize={14} ellipsis={isOptions}>
                {t(description)}
              </Text>
            </Tooltip>
            <Divider my={2} />
            <Box mb={3}>
              {accessibleOptions?.map(({ key, value }) => (
                <Box display="flex" key={key} mb={2} alignItems="center">
                  <Icon
                    mr={2}
                    name="CheckOutlined"
                    fill="var(--ql-typography-text-color-success)"
                  />
                  <Text fontSize={14}>
                    {key === 'tickets' ? t(value + 'ticketsAmount') : null}{' '}
                    {t(key + 'ProductOption')}
                  </Text>
                </Box>
              ))}
              {notAccessibleOptions?.map(({ key, value }) => (
                <Box display="flex" key={key} mb={2} alignItems="center">
                  <Icon
                    mr={2}
                    name="XCloseOutlined"
                    fill="var(--ql-typography-text-color-danger)"
                  />
                  <Text fontSize={14} delete>
                    {t(key + 'ProductOption')}
                  </Text>
                </Box>
              ))}
            </Box>
          </Box>
          <Box justifyContent="center" display="flex">
            <Button onClick={() => onContinue(_id)} type="primary">
              {t('Continue')}
            </Button>
          </Box>
        </Box>
      </Card>
    </Col>
  )
}

ProductsList.propTypes = {
  Product: PropTypes.object
}

export default ProductSelectPage
