import { Box, Layout, LayoutSystemProvider } from '@qonsoll/react-design'
import { LayoutAside, LayoutHeader, Logo, MobileLayout } from './components'

import Back from '../Back'
import PATHS from 'pages/paths'
import PropTypes from 'prop-types'
import { StyledContainer } from './BoilerplateLayout.styled'
import { useAsideState } from './hooks'
import { useLocation } from 'react-router-dom'

const unauthenticatedPaths = Object.values(PATHS.UNAUTHENTICATED)

const BoilerplateLayout = ({ children }) => {
  const location = useLocation()
  const [collapse, isMobile] = useAsideState()

  const isOnAuthPage = unauthenticatedPaths.includes(location.pathname)
  /* The layoutConfig is memoized to only re-render when isAuthenticated changes. */
  const layoutConfig = {
    isAsideLeft: !isMobile && !isOnAuthPage,
    asideLeftCollapseVariant: 'small',
    asideLeftCollapsed: collapse,
    asideLeftOuter: false,
    asideLeftOverlay: false,
    // Workaround for the issue with the layout system (to fix aside and content and make correct overflow for content)
    contentVerticalPadding: '0',
    contentHorizontalPadding: '0'
  }

  return !isOnAuthPage ? (
    <>
      {isMobile ? (
        <MobileLayout>{children}</MobileLayout>
      ) : (
        <LayoutSystemProvider {...layoutConfig}>
          <Layout asideLeft={<LayoutAside collapse={collapse} />}>
            <StyledContainer collapse={collapse}>{children}</StyledContainer>
          </Layout>
        </LayoutSystemProvider>
      )}
    </>
  ) : (
    <>
      <LayoutSystemProvider {...layoutConfig}>
        <Layout>
          <LayoutHeader
            left={<Back />}
            center={
              <Box display="flex" justifyContent="center">
                <Logo />
              </Box>
            }
          />
          <StyledContainer
            width="100vw"
            overflow="hidden"
            height="calc(100vh - 56px)">
            {children}
          </StyledContainer>
        </Layout>
      </LayoutSystemProvider>
    </>
  )
}

BoilerplateLayout.propTypes = {
  children: PropTypes.element
}

export default BoilerplateLayout
