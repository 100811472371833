import { Box, Title } from '@qonsoll/react-design'
import { useMemo, useState } from 'react'

import PropTypes from 'prop-types'
import Slidebar from '../Slidebar'
import SummaryItem from '../SummaryItem'
import { useCurrency } from 'contexts/Currency'
import { useTranslations } from 'contexts/Translation'

const MortgageSummary = (props) => {
  const {
    measurementSymbol,
    propertyValues = [],
    mortgages = [],
    value = {},
    years,
    isLocked,
    isPrintMode
  } = props

  const { percentSymbol } = useCurrency()

  const { t } = useTranslations()

  const [selectedIndex, setSelectedIndex] = useState(0)

  const selected = useMemo(() => {
    const yearNumber = selectedIndex

    const mortgage = mortgages[yearNumber] || {}
    const { debt, monthlyInterest, monthlyInstallment } = mortgage

    const propertyValue = propertyValues[yearNumber]
    const loanToValueRatio = (debt / propertyValue) * 100

    return {
      ...value,
      debt,
      interest: monthlyInterest,
      installment: monthlyInstallment,
      loanToValueRatio,
      propertyValue
    }
  }, [value, selectedIndex, mortgages, propertyValues])

  const slidebarText = useMemo(() => {
    const label = selectedIndex + 1
    return `${label}. ${t('year')}`
  }, [selectedIndex, t])

  const handleNext = () => {
    setSelectedIndex((prev) => {
      if (prev < years - 1) return prev + 1
      return prev
    })
  }
  const handlePrev = () => {
    setSelectedIndex((prev) => {
      if (prev > 0) return prev - 1
      return prev
    })
  }

  return (
    <>
      <Box
        mb={4}
        display="flex"
        alignItems="center"
        justifyContent="space-between">
        <Title level={3}>{t('Mortgage')}</Title>
        <Slidebar
          isPrintMode={isPrintMode}
          text={slidebarText}
          onLeft={handlePrev}
          onRight={handleNext}
        />
      </Box>
      <SummaryItem
        measurementSymbol={measurementSymbol}
        value={selected.propertyValue}
        label={t('Property value')}
        info={t('propertyValueInfo')}
        locked={isLocked}
      />
      <SummaryItem
        measurementSymbol={percentSymbol}
        value={selected.loanToValueRatio}
        label={t('Loan to value ratio')}
        info={t('loanToValueRatioInfo')}
        locked={isLocked}
      />
      <SummaryItem
        measurementSymbol={measurementSymbol}
        value={selected.downPayment}
        label={t('Down payment')}
        info={t('downPaymentInfo')}
        locked={isLocked}
      />
      <SummaryItem
        measurementSymbol={measurementSymbol}
        value={selected.debt}
        label={t('Debt')}
        info={t('debtInfo')}
        locked={isLocked}
      />
      <SummaryItem
        measurementSymbol={percentSymbol}
        value={selected.interestRate}
        label={t('Interest rate')}
        roundTo={2}
        info={t('interestRateInfo')}
        locked={isLocked}
      />
      <SummaryItem
        value={selected.years}
        label={t('Years')}
        info={t('yearsInfo')}
        locked={isLocked}
      />
      <SummaryItem
        measurementSymbol={measurementSymbol}
        value={selected.monthlyPayment}
        label={t('Monthly payment')}
        info={t('monthlyPaymentInfo')}
        locked={isLocked}
      />
      <SummaryItem
        measurementSymbol={measurementSymbol}
        value={selected.interest}
        label={t('Interest')}
        info={t('interestInfo')}
        locked={isLocked}
      />
      <SummaryItem
        measurementSymbol={measurementSymbol}
        value={selected.installment}
        label={t('Installment')}
        info={t('installmentInfo')}
        locked={isLocked}
      />
    </>
  )
}

MortgageSummary.propTypes = {
  value: PropTypes.object,
  measurementSymbol: PropTypes.string,
  years: PropTypes.number,
  mortgages: PropTypes.array,
  propertyValues: PropTypes.array,
  isLocked: PropTypes.bool,
  isPrintMode: PropTypes.bool
}

export default MortgageSummary
