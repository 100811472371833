import { Col, Divider, Row } from '@qonsoll/react-design'
import {
  FlippingCalculatorResultsSection,
  FlippingCalculatorSimpleForm
} from 'domains/FlippingCalculator/components'
import { Form, Grid } from 'antd'
import { memo, useCallback } from 'react'

import { KEYS } from '__constants__'
import { Logo } from 'components/BoilerplateLayout/components'
import PropTypes from 'prop-types'
import { calculateSummary } from 'domains/FlippingCalculator/components/FlippingCalculatorAdvancedForm/helpers'

const FlippingCalculatorAdvancedForm = (props) => {
  const { form, isAccessGranted, initialData, isPrintMode, onFinish, withId } =
    props

  const { xs } = Grid.useBreakpoint()

  /* Handling enter key press */
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      form.submit()
    }
  }

  const handleChange = useCallback(
    (e) => {
      const formFields = form.getFieldsValue()?.flippingCalculator
      const calculations = calculateSummary(formFields, isAccessGranted)
      form.setFieldsValue({ calculations })
    },
    [isAccessGranted, form]
  )

  return (
    <Form
      id={withId ? 'divToSaveAsPDF' : null}
      onValuesChange={handleChange}
      onKeyPress={handleKeyPress}
      onFinish={onFinish}
      layout={xs ? 'horizontal' : 'vertical'}
      autoComplete="off"
      form={form}>
      <Row noGutters>
        {isPrintMode && (
          <Col cw={12} mb={32} mt={-32}>
            <Logo />
          </Col>
        )}
        <Col cw={isPrintMode ? 5 : [12, 12, 12, 5]}>
          <FlippingCalculatorSimpleForm
            isPrintMode={isPrintMode}
            isAccessRestricted={!isAccessGranted}
            initialOwnership={initialData?.flippingCalculator?.ownership}
            group={['flippingCalculator']}
            form={form}
          />
        </Col>
        <Col
          display={isPrintMode ? 'flex' : ['none', 'none', 'none', 'flex']}
          cw={isPrintMode ? 2 : [0, 0, 0, 2]}
          h="center"
          height="inherit">
          <Divider type="vertical" style={{ height: '100%' }} />
        </Col>
        <Col cw={isPrintMode ? 5 : [12, 12, 12, 5]}>
          <Form.Item name={['calculations']}>
            <FlippingCalculatorResultsSection
              isAccessGranted={isAccessGranted}
              isPrintMode={isPrintMode}
              form={form}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

FlippingCalculatorAdvancedForm.propTypes = {
  initialData: PropTypes.object,
  group: PropTypes.array,
  showTitle: PropTypes.bool,
  isAccessGranted: PropTypes.bool,
  form: PropTypes.object,
  _id: PropTypes.string,
  isPrintMode: PropTypes.bool,
  onFinish: PropTypes.func,
  withId: PropTypes.bool
}

export default memo(FlippingCalculatorAdvancedForm)
