import { Box, PhoneInput, Text, Title } from '@qonsoll/react-design'
import { Modal, message } from 'antd'

import { COLLECTIONS } from '__constants__'
import { updateDocument } from 'services/api/firebase'
import { useRef } from 'react'
import { useTranslations } from 'contexts/Translation'
import { useUser } from 'domains/User/context'

const useUpdateUserPhoneNumberModal = () => {
  const inputRef = useRef()
  const { t } = useTranslations()
  const { user } = useUser()

  const onOk = async () => {
    try {
      await updateDocument(COLLECTIONS.USERS, user?._id, {
        phone: inputRef?.current?.state?.formattedNumber
      })
      message.success(
        `${t('Successfully update phone number')}. ${t(
          'Now you can proceed with loan application'
        )}`
      )
    } catch (err) {
      message.error(t('Error occurred during user phone number update'))
      //eslint-disable-next-line
        console.log('Err:', err)
    }
  }

  const openModal = () =>
    Modal.info({
      closable: true,
      maskClosable: true,
      icon: null,
      title: (
        <Title level={5}>{`${t(
          'You havent saved phone number in your profile'
        )}. ${t('Please enter your phone number to proceed')}`}</Title>
      ),
      content: (
        <div>
          <Box mb={2}>
            <Text>{t('Your phone number')}</Text>
          </Box>
          <PhoneInput
            ref={inputRef}
            country="no"
            specialLabel=""
            placeholder={t('Please enter your user phone')}
          />
        </div>
      ),
      okText: t('Save'),
      onOk
    })

  return openModal
}

export default useUpdateUserPhoneNumberModal
