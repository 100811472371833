import { OWNERSHIPS } from '__constants__/enums'

export const DOWN_PAYMENT_PERCENTS = {
  [OWNERSHIPS.PERSONAL]: 15,
  [OWNERSHIPS.COMPANY]: 30
}

export const DOWN_PAYMENT_PERCENTS_FLIPPING = {
  [OWNERSHIPS.PERSONAL]: 15,
  [OWNERSHIPS.COMPANY]: 20
}

export default DOWN_PAYMENT_PERCENTS
