import { useEffect, useState } from 'react'

import { notification } from 'antd'
import { fetchApi } from 'services/api/rest'
import { useTranslations } from 'contexts/Translation'

/**
 * It returns a payment method Stripe object
 * @param paymentMethodId - A payment method ID.
 * @param isPaymentMethodActive - If payment method is declared inactive, call to backend does not perform.
 * @returns A payment method Stripe object.
 */
const useGetPaymentMethod = (paymentMethodId, isPaymentMethodActive) => {
  const [paymentMethod, setPaymentMethod] = useState({})
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const { t } = useTranslations()
  useEffect(() => {
    if (isPaymentMethodActive) {
      const retrievePaymentMethod = async () => {
        try {
          setLoading(true)
          const res = await fetchApi({
            method: 'GET',
            path: `subscriptions/paymentmethods/${paymentMethodId}`
          })
          const isSuccess = !!res?.data?.paymentMethod?.id
          if (isSuccess) {
            setPaymentMethod(res.data.paymentMethod)
          }
        } catch (err) {
          setError(err)

          notification.error({
            message: t('Error'),
            description: t(
              'Something went wrong, please enter your payment card again!'
            )
          })
        } finally {
          setLoading(false)
        }
      }

      retrievePaymentMethod()
    }
  }, [paymentMethodId, isPaymentMethodActive, t])

  return [paymentMethod, loading, error]
}

export default useGetPaymentMethod
