import { Box } from '@qonsoll/react-design'
import PageHeader from '../PageHeader'
import PropTypes from 'prop-types'

const PageLayout = (props) => {
  const { children, header, isBlur, popover } = props

  return (
    <Box position="relative">
      {popover}
      <Box filter={isBlur ? 'blur(2px)' : null}>
        {header || <PageHeader mb={4} {...props} />}
        {children}
      </Box>
    </Box>
  )
}

PageLayout.propTypes = {
  children: PropTypes.any,
  header: PropTypes.any,
  isBlur: PropTypes.bool,
  popover: PropTypes.any
}

export default PageLayout
