import CurrencyContext from './CurrencyContext'
import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'

const CurrencyProvider = (props) => {
  const { children } = props

  const { t } = useTranslations()

  const currency = {
    symbol: ` ${t('Kr')}`,
    percentSymbol: ` ${t('%')}`
  }

  return (
    <CurrencyContext.Provider value={currency}>
      {children}
    </CurrencyContext.Provider>
  )
}

CurrencyProvider.propTypes = {
  children: PropTypes.any
}

export default CurrencyProvider
