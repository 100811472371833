import { Box, Input, Text } from '@qonsoll/react-design'

import { INPUT_NUMBER_REGEX } from '__constants__/regExps'
import PropTypes from 'prop-types'
import { Slider } from 'components'
import { StyledSuffix } from './InputPercent.styled'
import { getTextWidth } from 'get-text-width'
import { memo } from 'react'
import { useCurrency } from 'contexts/Currency'

const InputPercent = (props) => {
  const {
    value,
    onChange,
    customOnChange,
    disabled,
    max = 50,
    step = 1,
    ...rest
  } = props

  const { percentSymbol } = useCurrency()

  const handleChange = (e) => {
    const value = e.target.value?.replace(INPUT_NUMBER_REGEX, '')
    if (value > max) {
      customOnChange?.(max)
      return onChange(max)
    } else if (value < 0) {
      customOnChange?.(0)
      return onChange(0)
    }
    customOnChange?.(value)
    onChange(value)
  }

  const onSliderChange = (sliderValue) => {
    customOnChange?.(sliderValue)
    onChange(sliderValue)
  }

  const textWidth = Math.ceil(getTextWidth(value) + 1) * 2 + 20

  return (
    <>
      <Box position="relative">
        <Input
          min={0}
          type="number"
          max={max}
          value={value?.toString?.()}
          disabled={disabled}
          onChange={handleChange}
          {...rest}
        />
        {(!!value || value === 0) && (
          <StyledSuffix style={{ left: textWidth }}>
            <Text
              color={
                disabled
                  ? 'var(--input-disabled-color)'
                  : 'var(--ql-typography-text-color-secondary)'
              }>
              {percentSymbol}
            </Text>
          </StyledSuffix>
        )}
      </Box>
      <Slider
        disabled={disabled}
        value={value}
        onChange={onSliderChange}
        max={max}
        step={step}
        min={0}
      />
    </>
  )
}

InputPercent.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  customOnChange: PropTypes.func,
  disabled: PropTypes.bool,
  max: PropTypes.number,
  step: PropTypes.number
}

export default memo(InputPercent)
